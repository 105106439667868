import PropTypes from 'prop-types';

export const accountType = PropTypes.shape({
  accountNumber: PropTypes.string,
  accountNickName: PropTypes.string,
  accountType: PropTypes.string,
  availableBalance: PropTypes.number,
  currentBalance: PropTypes.number,
  majorAccountCode: PropTypes.string,
  minorAccountCode: PropTypes.string,
});

export const modalType = PropTypes.shape({
  title: PropTypes.string,
  body: PropTypes.string,
  yesText: PropTypes.string,
  noText: PropTypes.string,
});
