import { colors } from "styles/config";

export const styles = {
  columnPadding: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  goalImageBig: {
    width: 266,
    height: 196,
    marginTop: 12,
  },
  goalImageSmall: {
    width: 166,
    height: 122,
    marginTop: -12,
  },
  goalTitleBig: {
    width: '100%',
    paddingTop: 110,
  },
  goalTitleSmall: {
    width: '70%',
    paddingTop: 32,
  },
  listWrapper: {
    marginTop: 16,
  },
  subheaderWrapper: {
    width: '100%',
  },
  subheaderContainer: {
    height: 205,
    display: 'flex',
    justifyContent: 'space-between',
  },
  recommendationIconContainer: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // fpa
  trackerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 0',
  },
  trackerItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 120,
  },
  currentStep: {
    position: 'relative',
    padding: 1,
    backgroundColor: colors.snow,
    border: `1px solid ${colors.harbor}`,
    borderRadius: '100%',
    marginTop: 6,
    marginBottom: 8,
  },
  step: {
    position: 'relative',
    backgroundColor: colors.snow,
    marginTop: 6,
    marginBottom: 8,
  },
  inactiveStep: {
    position: 'relative',
    marginTop: 6,
    marginBottom: 8,
  },
  trackerLine: {
    position: 'absolute',
    top: 40,
    height: 4,
    backgroundColor: colors.storm,
  },
  filledTrackerLine: {
    height: 4,
    backgroundColor: colors.harbor,
    transition: 'width 0.5s linear',
  },  
};
