export const CREDIT_BUILDER = {
  paddingTop: 0,
  recommendationBullets: [
    {
      title: 'You Have Options:',
      subtitle: 'If no credit or poor credit has made it difficult for you to achieve your goals, we have a couple options that could benefit you.',
      readMoreLink: null,
      subtitleList: [
        {
          title: 'A secured credit card'
        },
        {
          title: 'A savings-secured loan'
        }
      ]
    },
    {
      title: 'Secured Credit Card',
      subtitle: 'Consider a secured credit card. This option looks and works like a regular credit card. The difference is it requires a cash security deposit that the lender “holds” to secure the funds.',
      readMoreLink: 'https://www.becu.org/everyday-banking/credit-card/secured-credit-card',
      subtitleListHeader: null,
      subtitleList: [
        {
          title: 'Approval is often easier (although not guaranteed)'
        },
        {
          title: 'Can help you establish or reestablish good credit history'
        },
        {
          title: 'May improve your credit score.'
        }
      ]
    },
    {
      title: 'Savings-Secured Loan',
      subtitle: 'Consider a savings-secured loan. This option allows you to borrow against your savings account to secure a loan.',
      readMoreLink: 'https://www.becu.org/loans-and-mortgages/secured-personal',
      subtitleList: [
        {
          title: 'Intended to help people rebuild or establish credit'
        },
        {
          title: 'No credit report is pulled'
        },
        {
          title: 'Loans will be reported to the credit bureaus, which establishes credit history'
        },
        {
          title: 'No loan or application fees'
        },
        {
          title: 'Low interest rates'
        },
        {
          title: 'Minimum loan amount is $250'
        }
      ]
    },    
  ]  
};


export const EDUCATION = {
  header: 'Check out this collection of BECU content focused on improving your credit standing.',
  paddingTop: 70,
  recommendationContent: [
    {
      title: 'Free Webinars',
      subtitle: 'BECU offers a wide range of free webinars for our members including how to read your credit report and build credit.',
      readMoreLink: 'https://www.becu.org/seminars'
    },
    {
      title: 'What is a FICO® Score?',
      subtitle: 'A FICO® Score is a three-digit number lenders use to evaluate your creditworthiness. Let’s look at how to improve your score.',
      readMoreLink: 'https://www.becu.org/articles/what-is-a-fico-score'
    },
    {
      title: 'Building Credit',
      subtitle: 'Good credit pays off every day. Let’s look at the fundamentals of building credit.',
      readMoreLink: 'https://www.becu.org/articles/building-credit'
    },
    {
      title: 'Why Did My Credit Score Drop?',
      subtitle: 'If your credit score dropped overnight, don’t panic. Let’s look at possible reasons why your credit score fell and what score-boosting steps to take next.',
      readMoreLink: 'https://www.becu.org/blog/why-did-my-credit-score-drop'
    },
    {
      title: 'Things To Know About Credit Scores',
      subtitle: '(Originally published April 13, 2021) One bit of good news from an otherwise tough couple of years is that credit scores have continued to rise. Let’s look at what this means for your financial health and how you can get in on the trend.',
      readMoreLink: 'https://www.becu.org/blog/raise-credit-scores-with-healthy-finance-habits'
    }
  ],
};

export const BEST_PRACTICES = {
  paddingTop: 70,
  recommendationList: [
    {
      title: 'Pay Your Bills on Time',
      subtitle: 'Payment history makes up 35% of your credit score. Even a single missed payment can cause a significant drop in your credit score.'
    },
    {
      title: 'Keep Balances Low',
      subtitle: 'The amount of available credit you’re using across all your credit accounts makes up 30% of your credit score. A good rule of thumb is to keep your credit utilization under 30%.'
    },
    {
      title: 'Beware of Offers in the Mail',
      subtitle: 'Don’t give in to the credit card offers you receive in the mail. To stop receiving these	solicitations, visit optoutprescreen.com or call 888-5OPTOUT (888-567-8688).'
    },
    {
      title: 'Don’t Apply for Too Many Loans or Credit Cards at Once',
      subtitle: 'Applying for credit places a “hard inquiry” on your credit report for 24 months and can lower 	your credit score. Rate shopping, the process of gathering quotes and comparing offers from multiple lenders can help you reduce unnecessary “hard inquiry” hits on your credit report.\nRate shopping before applying for an auto loan, student loan, or mortgage can reduce the number of inquiries on your credit report if you do it within a short window. When the lender is using the FICO® credit scoring model, it will count all inquiries made within 45 days as one inquiry if used for one specific type of loan. This is why it’s important to complete rate shopping and get your loan within 45 days.'
    },
    {
      title: 'Keep Old Credit Cards Open',
      subtitle: 'Credit scores benefit from a long credit history and a high total credit limit. Closing cards with long credit history could negatively impact your credit score.'
    },
    {
      title: 'Maintain a Good Credit Mix',
      subtitle: 'Responsibly add to your credit mix. Lenders look for a combination of revolving accounts and installment loans. This shows your ability to handle different types of credit.'
    },
    {
      title: 'Use Money Management Tools to Prevent Overspending',
      subtitle: 'Take good care of your personal finances. Track your spending and create a spending plan.'
    }
  ],
  relatedContentSeparator: true,
  recommendationContent: [
    {
      title: 'Building Credit',
      subtitle: 'Good credit pays off every day. Let’s look at the fundamentals of building credit.',
      readMoreLink: 'https://www.becu.org/articles/building-credit'
    },
    {
      title: 'Things To Know About Credit Scores',
      subtitle: '(Originally published April 13, 2021) One bit of good news from an otherwise tough couple of years is that credit scores have continued to rise. Let’s explain what this means to your financial health and how you can get in on the trend.',
      readMoreLink: 'https://www.becu.org/blog/raise-credit-scores-with-healthy-finance-habits'
    },
    {
      title: 'Money Manager: Finances Made Easy',
      subtitle: 'When you’re juggling checking and savings accounts, loan payments, retirement savings and more, it can be easy to lose sight of your overall financial health. That’s why BECU offers members access to Money Manager, an easy-to-use budgeting tool that lets you view your entire financial portfolio in one place.',
      readMoreLink: 'https://www.becu.org/everyday-banking/bank-anywhere/money-manager'
    }
  ],
};

export const EMPLOYER_SPONSORED_RETIREMENT_PLAN = {
  paddingTop: 90,
  headerContent: [
    {
      title: 'Don’t Miss Out on Free Money!',
      subtitle: ['Your employer may offer an employer sponsored retirement plan. Examples include a 401(k), 403(b), or a 457(b) plan.', 'Many employers will also offer a match program with their retirement plan. If you haven’t started contributing, consider starting now. If you are contributing already, consider increasing the amount until you are taking full advantage.', 'Talk to your Human Resources team to learn more about what types of benefit plans your company offers.'],
    }
  ],
  recommendationBulletsTitle: 'Types of Retirement Plans',
  recommendationBullets: [
    {
      title: '401(k)',
      subtitle: 'An employer-sponsored retirement savings program that gives employees a choice of investment options.',
      readMoreLink: null,
      subtitleList: null
    },
    {
      title: '403(b)',
      subtitle: 'A retirement savings program provided by certain employers such as public education, certain non-profits, and churches or church-related organizations.',
      readMoreLink: null,
      subtitleList: null
    },
    {
      title: '457(b)',
      subtitle: 'A retirement savings program provided by certain employers such as state and local government agencies and certain non-profit organizations.',
      readMoreLink: null,
      subtitleList: null
    }   
  ],
  tip: {
    title: 'Tip: Merit Increase, Pay Raise or Bonus',
    content: 'When you receive a merit increase, pay raise, or bonus, consider putting a portion towards your retirement plan.',
  },
};
