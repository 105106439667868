import React from 'react';
import Flexbox from 'flexbox-react';

import { colors } from 'styles/config';
import { styles } from './styles';

export const ProfileAnswers = (item, index, lastItem, lineBreak) => (
  <Flexbox key={index} justifyContent='flex-start' alignItems='center' style={{...styles.answerWrapper, borderBottom: lastItem ? 'none' : `1px solid ${colors.storm}`}}>
    <img src={require(`../../../assets/images/icons/${item.financialTopic.topic.split(' ').join('_')}.svg`)} style={styles.icon} aria-hidden='true' />
    <div>
      <p style={styles.topic}>{item.financialTopic.topic}</p>
      {lineBreak === true ? (
        <>
          <p style={styles.answer}>{item.financialTopic.topicIntroduction}</p>
          <p style={styles.answer}>{item.financialAnswers[0].answer}</p>
        </>
      ) : (
        <p style={styles.answer}>{item.financialTopic.topicIntroduction} {item.financialAnswers[0].answer.toLowerCase()}</p>
      )}
    </div>
  </Flexbox>
);