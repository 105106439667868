import {
    CREATE_FINANCIAL_GOAL_SUCCESS,
    CREATE_FINANCIAL_TOPIC_SUCCESS,
    DELETE_FINANCIAL_GOAL_SUCCESS,
    DELETE_FINANCIAL_TOPIC_SUCCESS,
    GET_FINANCIAL_GOAL_SUCCESS,
    GET_FINANCIAL_RECOMMENDATIONS_SUCCESS,
    GET_FINANCIAL_TOPIC_SUCCESS,
    GET_CTA_GOALS_SUCCESS,
    EDIT_GOAL_SUCCESS,
    UPDATE_TUTORIAL,
    UPDATE_COMPLETION_SUCCESS,
    UPDATE_FAVORITES_SUCCESS,
    GET_COMPLETED_FAVORITE_RECOMMENDATIONS_SUCCESS
  } from '../config/actionTypes';
  
  const INITIAL_STATE = {
    financialGoal: null,
    financialTopic: null,
    financialGoalAdded: false,
    financialTopicAdded: false,
    financialGoalDeleted: false,
    financialTopicDelete: null,
    recommendations: [],    
    recommendationsSuccess: false,
    ctaGoals: [],
    ctaGoalsSuccess: false,
    editingGoal: null,
    tutorial: null,
    completedFavoriteRecommendations: {
      completedRecommendations: [],
      favoriteRecommendations: []
    } ,
    recommendationCompletionSuccess: { success: false, isFavorite: false },
    recommendationFavoritesSuccess: { success: false, isFavorite: false },
  };

  const updateArray = (array, item, shouldAdd) => {
    const index = array.findIndex((i) => i.recommendationsId === item.recommendationsId && i.goalId === item.goalId);

    if (shouldAdd) {
      if (index === -1) {
        array.push(item);
      } else {
        array[index] = item;
      }
    } else {
      if (index !== -1) {
        array.splice(index, 1);
      }
    }
  };

  export const financialHealth = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_FINANCIAL_GOAL_SUCCESS:
        return {
          ...state,
          financialGoal: action.payload,
        };
      case GET_FINANCIAL_TOPIC_SUCCESS:
        return {
          ...state,
          financialTopic: action.payload,
        };
        case CREATE_FINANCIAL_GOAL_SUCCESS:
        return {
          ...state,
          financialGoalAdded: action.payload,
        };
        case CREATE_FINANCIAL_TOPIC_SUCCESS:
        return {
          ...state,
          financialTopicAdded: action.payload,
        };
        case DELETE_FINANCIAL_GOAL_SUCCESS:
          return {
            ...state,
            financialGoalDeleted: action.payload,
          };
          case DELETE_FINANCIAL_TOPIC_SUCCESS:
          return {
            ...state,
            financialTopicDelete: {
              deletedId: action.payload.financialProfileAnswerId,
              financialTopic: action.payload.financialTopic,
            },
          };
        case GET_FINANCIAL_RECOMMENDATIONS_SUCCESS: {
          if (action.payload.type === 'reset') {
            return {
              ...state,
              recommendationsSuccess: false,
            };
          }

          const recs = [...action.payload.recommendations];

          recs.forEach((rec) => {
            if (rec.recommendations) {
              const index = rec?.recommendations?.findIndex((item) => item.recommendationsId === rec.topRecommendationId);
              if (index > 0) {
                const [item] = rec.recommendations.splice(index, 1);
                rec.recommendations.unshift(item);
              }
            }
          });

          return {
            ...state,
            recommendations: recs,
            recommendationsSuccess: action.payload.navOnSuccess,
          };
        }
        case GET_CTA_GOALS_SUCCESS:
          if (action.payload.type === 'reset') {
            return {
              ...state,
              ctaGoalsSuccess: false,
            };
          }

          return {
            ...state,
            ctaGoals: action.payload,
            ctaGoalsSuccess: true
          };
        case EDIT_GOAL_SUCCESS:
          return {
            ...state,
            editingGoal: action.payload
          };
        case UPDATE_TUTORIAL:
          return {
            ...state,
            tutorial: action.payload,
          };
        case GET_COMPLETED_FAVORITE_RECOMMENDATIONS_SUCCESS:
          return {
            ...state,
            completedFavoriteRecommendations: {
              completedRecommendations: action.payload.completedRecommendations ?? [],
              favoriteRecommendations: action.payload.favoriteRecommendations ?? [],
            },
          };
        case UPDATE_COMPLETION_SUCCESS: {
          if (!action.payload.value) {
            return {
              ...state,
              recommendationCompletionSuccess: { success: false, isCompleted: false },
            };
          }

          const completionRecommendationsCopy = [...state.recommendations];
          const currentCompletedCopy = [...state.completedFavoriteRecommendations.completedRecommendations];
          const favroitesCopy = [...state.completedFavoriteRecommendations.favoriteRecommendations];

          if (!action.payload.isCompleted) {
            updateArray(currentCompletedCopy, {recommendationsId: action.payload.recommendationId, goalId: action.payload.goalId}, false);
          }

          completionRecommendationsCopy.forEach((item) => {
            if (item.goalId === parseInt(action.payload.goalId)) {
              item.recommendations.forEach((rec) => {
                if (rec.recommendationsId === action.payload.recommendationId) {
                  rec.isCompleted = action.payload.isCompleted;
                  if (action.payload.isCompleted) {
                    rec.completedDate = Date.now();
                  }
                  updateArray(currentCompletedCopy, {...rec, goalId: item.goalId }, action.payload.isCompleted);
                }
              });
            }
          });

          favroitesCopy.forEach((item) => {
            if (item.goalId === parseInt(action.payload.goalId) && item.recommendationsId === action.payload.recommendationId) {
              updateArray(favroitesCopy, {...item, isCompleted: action.payload.isCompleted, completedDate: action.payload.isCompleted ? Date.now() : null}, item.isFavorite);
            }
          });

          return {
            ...state,
            recommendationCompletionSuccess: { success: true, isCompleted: action.payload.isCompleted },
            recommendations: completionRecommendationsCopy,
            completedFavoriteRecommendations: {
              completedRecommendations: currentCompletedCopy,
              favoriteRecommendations: favroitesCopy,
            }
          };
        }
        case UPDATE_FAVORITES_SUCCESS: {
          if (!action.payload.value) {
            return {
              ...state,
              recommendationFavoritesSuccess: { success: false, isFavorite: false },
            };
          }

          const favoritesRecommendationsCopy = [...state.recommendations];
          const currentFavoritesCopy = [...state.completedFavoriteRecommendations.favoriteRecommendations];
          let recommendationData = [];

          if (!action.payload.isFavorite) {
            action.payload.recommendations.forEach((rec) => {
              updateArray(currentFavoritesCopy, {recommendationsId: rec.recommendationId, goalId: rec.goalId}, false);
            });
          }

          favoritesRecommendationsCopy.forEach((item) => {
            if (action.payload.recommendations.map((r) => r.goalId).includes(item.goalId) && item.recommendations) {
              item.recommendations.forEach((rec) => {
                if (action.payload.recommendations.map((r) => r.recommendationId).includes(rec.recommendationsId)) {
                  rec.isFavorite = action.payload.isFavorite;
                  if (action.payload.isFavorite) {
                    rec.favoriteDate = Date.now();
                  }
                  recommendationData.push({ ...rec, goalId: item.goalId });
                  updateArray(currentFavoritesCopy, { ...rec, goalId: item.goalId }, action.payload.isFavorite);
                }
              });
            }
          });

          if (action.payload.isFavorite) {
            action.payload.recommendations.forEach((payloadRec) => {
              let goalId = payloadRec.goalId;
              let recommendationsId = payloadRec.recommendationId;

              if (currentFavoritesCopy.filter((favorite) => favorite.goalId === goalId && favorite.recommendationsId === recommendationsId).length === 0) {
                updateArray(currentFavoritesCopy, { ...recommendationData[0], goalId, recommendationsId }, true);
              }
            });
          }

          return {
            ...state,
            recommendationFavoritesSuccess: { success: true, isFavorite: action.payload.isFavorite },
            recommendations: favoritesRecommendationsCopy,
            completedFavoriteRecommendations: {
              ...state.completedFavoriteRecommendations,
              favoriteRecommendations: currentFavoritesCopy,
            }
          };
        }
      default:
        return state;
    }
  };
  