import {colors} from 'styles/config';

export const styles = {
  reviewHeading: {
    fontWeight:'900',
    color: colors.obsidian,
  },
  goalTitle: {
    marginTop: 24,
  },
  financialGoalContainer: {
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 10px',
  },
  financialGoalCheckbox: {
    marginLeft: 'auto',
    marginRight: 0
  },
  financialGoalText: {
    marginTop: 0, 
    marginBottom: 0,
    color: colors.stone,
  }
};
