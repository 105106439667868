import React from 'react';
import {Form} from 'react-bootstrap';

import { universalStyles } from 'styles/universalStyles';

const Quiz = ({addFinancialQuizAnswer, headers, answers, topic, index}) => {
  const title = headers.financialProfileTopicAnswers.filter((financialTopic) => financialTopic.financialTopic.topic === topic).map((x) => x.financialTopic)[0].topicIntroduction;
  const financialQuizAnswers = headers.financialProfileTopicAnswers.filter((financialTopic) => financialTopic.financialTopic.topic === topic).map((x) => x.financialAnswers)[0];
  const answerId = answers.filter((topicAnswers) => topicAnswers.topic === topic).map((x) => x.financialProfileAnswerId)[0];
  const selectedAnswer = financialQuizAnswers.filter(x => x.financialProfileAnswerId === answerId).map((y) => y);

  return (
    <Form.Group controlId={`quiz-${index}`} style={{marginTop: 30}}>
      <Form.Label style={universalStyles.form.inputLabel}>{title}</Form.Label>
      <Form.Select
        value={selectedAnswer[0]?.financialProfileAnswerId}
        onChange={(e) => addFinancialQuizAnswer(parseInt(e.target.value), topic)}
        style={universalStyles.form.inputContainer}
      >
        {financialQuizAnswers.map((answerOption, index) => (
          <option key={index} value={answerOption.financialProfileAnswerId}>
            {answerOption.answer}
          </option>
        ))}
      </Form.Select>
    </Form.Group> 
  );
};

export default Quiz;