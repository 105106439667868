import React, {useState, createRef, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useLocation} from 'react-router-dom';

const MetaDataAnnouncer = () => {
  const [title, setTitle] = useState('');
  const titleRef = createRef();
  const {pathname} = useLocation();
  const onHelmetChange = ({title}) => setTitle(title);

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }, [pathname]);

  return (
    <>
      <p tabIndex={-1} ref={titleRef} className="visually-hidden">
        {title}
      </p>
      <Helmet onChangeClientState={onHelmetChange} />
    </>
  );
};

export default MetaDataAnnouncer;
