import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Flexbox from 'flexbox-react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import PageMetaData from 'components/app/PageMetaData';
import Header from 'components/app/Header';
import Breadcrumbs from 'components/app/Breadcrumbs';
import FeedbackTab from 'components/app/FeedbackTab';
import AlertBanner from 'components/app/AlertBanner';
import TipBox from 'components/app/TipBox';
import AdditionalQuestions from '../Goals/AdditionalQuestions';
import RecommendationCard from 'components/financial-health/RecommendationCard';

import { styles } from './styles';
import { universalStyles } from 'styles/universalStyles';
import { BECU_BASE_URL, GOAL_PAGE, HOME_PAGE, RETURN_TO_OLB_LINK, TUTORIAL_PAGE } from 'config/constants';
import { authSelector, breadcrumbsSelector, intializedSelector, recommendationCompletedSuccessSelector, recommendationFavoritesSuccessSelector, recommendationsSelector, sessionTokenSelector, userSelector } from 'selectors';
import { getCompletedFavoriteRecommendations, getRecommendations, loginWithToken, resetRecommendationCompletion, resetRecommendationFavorites, setSessionPath, updateBreadcrumbs, updateTutorial } from 'actions';
import { formatBreadcrumbs } from 'utils/breadcrumbs';
import { tagRecommendationSelected } from 'services/gtm';

const Goal = () => {
  const { goalId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector(authSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const initialized = useSelector(intializedSelector);
  const user = useSelector(userSelector);
  const recommendations = useSelector(recommendationsSelector);
  const recommendationCompletedSuccess = useSelector(recommendationCompletedSuccessSelector);
  const recommendationFavoritesSuccess = useSelector(recommendationFavoritesSuccessSelector);
  const [alerts, setAlerts] = useState([]);
  const [mobileAlert, setMobileAlert] = useState(null);
  const breadcrumbs = useSelector(breadcrumbsSelector);
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});
  const isBigScreen = useMediaQuery({query: '(min-width: 768px)'});
  const alertLargeSize = useMediaQuery({query: '(min-width: 992px)'});
  const selectedGoal = user?.financialGoal?.filter(goal => goal.financialGoalId === parseInt(goalId))[0];
  const [recommendationsFetched, setRecommendationsFetched] = useState(false);
  const [currentRecommendations, setCurrentRecommendations] = useState([]);

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      window.open(RETURN_TO_OLB_LINK, '_self');
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    }
  }, [loggedIn, sessionToken, initialized, history]);

  useEffect(() => {
    if (selectedGoal) {
      const goalBreadcrumbs = [
        {
          title: 'Financial Goals & Resource Guide',
          page: HOME_PAGE,
          active: false,
        },
        {
          title: selectedGoal?.goal,
          page: GOAL_PAGE,
          active: true,
        },
      ];
      dispatch(updateBreadcrumbs(goalBreadcrumbs));
    }
  }, [selectedGoal]);

  useEffect(() => {
    if (recommendations.length === 0 && user?.financialProfileTopicAnswers?.length && !recommendationsFetched) {
      setRecommendationsFetched(true);
      dispatch(getRecommendations());
      dispatch(getCompletedFavoriteRecommendations());
    } else {
      setCurrentRecommendations(recommendations?.filter((reco) => parseInt(reco.goalId) === parseInt(goalId))[0]);
    }
  }, [recommendations, user, currentRecommendations]);

  useEffect(() => {
    if (recommendationCompletedSuccess.success || recommendationFavoritesSuccess.success) {
      const alertId = Date.now();
      const timeoutId = setTimeout(() => {
        setAlerts(prevAlerts => prevAlerts.filter(alert => alert.id !== alertId));
        setMobileAlert(mobileAlert => mobileAlert?.id === alertId ? null : mobileAlert);
      }, 10000);

      const newAlert = {
        id: alertId,
        message: `Your recommended action is ${recommendationCompletedSuccess.success ? `marked as ${recommendationCompletedSuccess.isCompleted ? 'completed' : 'incomplete'}` : (recommendationFavoritesSuccess.isFavorite ? 'added to Favorites' : 'removed from Favorites')}.`,
        timeoutId,
      };

      const updatedAlerts = [...alerts, newAlert];
      
      if (updatedAlerts.length > 3) {
        updatedAlerts.shift();
      }

      setAlerts(updatedAlerts);
      setMobileAlert(newAlert);

      if (recommendationCompletedSuccess.success) {
        dispatch(resetRecommendationCompletion());
      }
      if (recommendationFavoritesSuccess.success) {
        dispatch(resetRecommendationFavorites());
      }
    }
  }, [recommendationCompletedSuccess, recommendationFavoritesSuccess, alerts, mobileAlert]);

  const tagSelection = (path, item) => {
    tagRecommendationSelected(user, parseInt(goalId), item.packageName, path);
  };

  const navToRecommendation = (item) => {
    const path = {pathname: GOAL_PAGE + `/${selectedGoal?.financialGoalId}/${item?.recommendationsId}`};
    dispatch(setSessionPath(path));
    dispatch(updateBreadcrumbs(formatBreadcrumbs([...breadcrumbs, {title: item.packageName, page: null, active: true}])));
    history.push(path);    
  };

  const navToTutorial = (tutorial, recommendation) => {
    const path = {pathname: TUTORIAL_PAGE + `/${tutorial.header.title.split(' ').join('-')}`};
    dispatch(setSessionPath(path));
    dispatch(updateTutorial({tutorial, recommendation}));
    history.push(path);
  };

  const headerImage = () => {
    let imgName = selectedGoal?.imgName.substring(0, selectedGoal?.imgName.indexOf('.'));
    if (imgName) {
      const image = require(`../../assets/images/goals/${imgName + '.svg'}`);
      return <img src={image} style={{...(isBigScreen ? styles.goalImageBig : styles.goalImageSmall), marginLeft: 'auto'}} role='none' aria-hidden='true' />;
    }

    return null;
  };

  const formatDescription = (desc) => {
    if (!desc) {
      return [];
    }

    const updatedMessage = desc.replace('{{firstName}}', user?.firstName);

    return updatedMessage.split('\\n');
  };

  const handleAlertClose = (alertId, isMobileAlert) => {
    const alertToClose = alerts.find(alert => alert.id === alertId);
    if (alertToClose && alertToClose.timeoutId) {
      clearTimeout(alertToClose.timeoutId);
    }

    if (isMobileAlert) {
      setAlerts(prevAlerts => prevAlerts.filter(a => a.id !== alertId));
      setMobileAlert(null);
    } else {
      let newAlerts = alerts.filter(a => a.id !== alertId);
      if (newAlerts.length > 0) {
        setMobileAlert(newAlerts[newAlerts.length - 1]);
      } else {
        setMobileAlert(null);
      }
      setAlerts(newAlerts);
    }
  };

  return (
    (selectedGoal?.questions > 0 && selectedGoal?.answers === 0) ? (
      <AdditionalQuestions selectedGoal={selectedGoal} />
    ) : (
      <Flexbox flexDirection='column' style={universalStyles.container}>
        <PageMetaData pageTitle={`${selectedGoal?.goal ? selectedGoal.goal : 'Goal'} | BECU`} />
        <Header />
        <div id='main-content'>
          <div style={styles.subheaderWrapper}>
            <Container style={{paddingTop: 8, paddingBottom: 8}}>
              <Breadcrumbs />
            </Container>
            <Container style={{...styles.subheaderContainer, flexDirection: isBigScreen ? 'row' : 'column'}}>
              <h1 style={isBigScreen ? styles.goalTitleBig : styles.goalTitleSmall}>{selectedGoal?.goal}</h1>
              {headerImage()}
            </Container>
          </div>
          <Container style={{...styles.columnPadding}}>
            <Row>
              <Col md={7} style={{ zIndex: 2 }}>
                <h2 style={{marginTop: (isSmallScreen) ? 40 : 0}}>Recommended for You</h2>
                <div style={{marginTop: 24}}>
                  {(formatDescription(selectedGoal?.goalLongDesc)).map((desc, index) => (
                    <p key={index}>{desc}</p>
                  ))}
                </div>
                <div style={styles.listWrapper}>
                  {currentRecommendations?.recommendations?.map((recommendation, index) => (
                    RecommendationCard(recommendation, index, goalId, isSmallScreen, formatDescription, tagSelection, dispatch, navToTutorial, navToRecommendation, currentRecommendations?.topRecommendationId)
                  ))}
                </div>
              </Col>
              <Col lg={{span: 4, offset: 1}} md={5} style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                {selectedGoal?.goal === 'Pay Down Credit Card Debt' &&
                  <TipBox
                    topMargin={(!isBigScreen) ? 30 : 80}
                    title={'Tip: ‘Put Away, Keep Away’'} 
                    content='Take credit cards out of your physical and digital wallets. If possible, use a debit card or cash instead to avoid adding to your credit card debt.'
                  />
                }
                {selectedGoal?.goal === 'Improve Credit Standing' &&
                  <TipBox
                    topMargin={(!isBigScreen) ? 30 : 80}
                    title={'Tip: Monitor Your Credit Report'} 
                    content='A credit report is different from your credit score – it’s more than just a number. Everyone should review their full credit report once a year. You can get your full report for free from all three credit bureaus.'
                    learnMoreTag={'Get Full Report'}
                    learnMoreLink={'https://www.annualcreditreport.com'}
                    externalLink
                  />
                }
                <TipBox
                  topMargin={(isBigScreen && !['Pay Down Credit Card Debt', 'Improve Credit Standing'].includes(selectedGoal?.goal)) ? 80 : 30}
                  title={'Want More Support?'} 
                  content={'Our phone-based Financial Health Check is a powerful one-on-one coaching service designed to help you achieve your financial goals – and it\'s free to BECU members.'}
                  learnMoreTag={'Schedule Now'}
                  learnMoreLink={`${BECU_BASE_URL}/members-matter/education/financial-health-check`}
                />
              </Col>
              {(mobileAlert && !alertLargeSize) &&
                <Flexbox id='RecommendationStatusSuccessMobile' style={{position: 'sticky', padding: 0, bottom: 0, width: '100%', zIndex: 1001}} flexDirection='column' justifyContent='flex-end'>
                  <AlertBanner
                    key={mobileAlert.id}
                    type={'success'}
                    name={'saveSuccess'}
                    show={() => true}
                    dismissible={true}
                    message={mobileAlert.message}
                    alertStyle={{marginBottom: 0}}
                    onClose={() => handleAlertClose(mobileAlert.id, true)}
                  />
                </Flexbox>
              }
              <Col>
                <Button
                  variant='secondary'
                  onClick={() => history.push({pathname: HOME_PAGE})}
                  style={{marginTop: 30, width: (isSmallScreen) ? '100%' : 'auto'}}
                >
                  Back
                </Button>
              </Col>
            </Row>
            {alertLargeSize &&
              <div style={{position: 'sticky', bottom: 0, right: 0}}>
                <Flexbox id='RecommendationStatusSuccessDesktop' className={'recommendation-status-alerts'} style={{position: 'absolute', bottom: 0, right: 0}} flexDirection='column' justifyContent='flex-end' alignItems='flex-end'>
                  {alerts.map((alert) => (
                    <AlertBanner
                      key={alert.id}
                      type={'success'}
                      name={'saveSuccess'}
                      show={() => true}
                      dismissible={true}
                      message={alert.message}
                      alertStyle={{width: '100%'}}
                      onClose={() => handleAlertClose(alert.id, false)}
                    />
                  ))}
                </Flexbox>
              </div>
            } 
          </Container>
          <FeedbackTab />
        </div>
      </Flexbox>
    )
  );
};

export default Goal;