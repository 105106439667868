import {colors} from 'styles/config';

export const styles = {
  subheaderWrapper: {
    backgroundColor: colors.cloud,
    padding: '45px 0',
  },
  subheaderTitle: {
    marginBottom: 0,
  },
};
