import {colors} from 'styles/config';

export const styles = {
  financialGoalColumn: {
    position: 'relative',
    padding: '10px 20px',
    borderRadius: 4, 
    marginTop: 4, 
    marginBottom: 16,
    cursor: 'pointer',
    boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 2,
  },
  financialGoalTextContainer: {
    fontSize: 16,
  },
  financialGoalTextSpan: {
    color: colors.stone,
  },
  financialGoalText: {
    marginTop: 0, 
    marginBottom: 0,
    color: colors.stone,
  },
  financialGoalCheckbox: {
    marginLeft: 'auto',
    marginRight: 0,
  }

};
