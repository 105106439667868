import {
  ACCELERATE_PAYMENTS,
  CREATE_BUDGET,
  SNOWBALL
} from "config/tutorials";

export const getGoalTutorial = (packageName) => {
  switch (packageName) {
    case 'Snowball':
      return SNOWBALL;
    case 'Accelerate Payments':
      return ACCELERATE_PAYMENTS;
    case 'Create a Budget':
      return CREATE_BUDGET;
    default:
      return null;
  }
};
