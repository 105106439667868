import {combineReducers} from 'redux';

import {auth} from './auth';
import {financialHealth} from './financialHealth';
import {session} from './session';
import {ui} from './ui';
import {user} from './user';

import {LOGOUT_SUCCESS} from '../config/actionTypes';

const rootReducer = combineReducers({
  auth,
  financialHealth,
  session,
  ui,
  user,
});

export default (state, action) => rootReducer(action.type === LOGOUT_SUCCESS ? undefined : state, action);
