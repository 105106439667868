import React from 'react';
import {PropTypes} from 'prop-types';
import {Col, Container, Row} from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import {styles} from './styles';

const Subheader = ({ title, titleAriaLabel, textAlign }) => {
  const isMobileBreakpoint = useMediaQuery({query: '(max-width: 767px)'});

  return (
    <div style={styles.subheaderWrapper}>
      <Container>
        <Row>
          <Col>
            {isMobileBreakpoint ? (
              <h4 style={{...styles.subheaderTitle, textAlign}} aria-label={titleAriaLabel}>
                {title}
              </h4>
            ) : (
              <h1 style={{...styles.subheaderTitle, textAlign}} aria-label={titleAriaLabel}>
                {title}
              </h1>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Subheader.propTypes = {
  title: PropTypes.string,
  titleAriaLabel: PropTypes.string,
  textAlign: PropTypes.string,
};

Subheader.defaultProps = {
  title: '',
  titleAriaLabel: '',
  textAlign: 'center',
};

export default Subheader;
