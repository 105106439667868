import Flexbox from 'flexbox-react';
import React, {useEffect, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Modal} from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';

import {logout, setMaxSessionModal} from 'actions';
import { getSsoAuthKey } from 'services/sessionStorage';
import {MAX_SESSION_MODAL_TIME, RETURN_TO_OLB_LINK, SSO_SESSION_LOGOUT_PAGE, TOKEN_TIME} from 'config/constants';
import {uiMaxSessionModalVisibleSelector} from 'selectors';

const MaxSessionModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const maxSessionModalVisible = useSelector(uiMaxSessionModalVisibleSelector);
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});
  const [minutes, setMinutes] = useState(Math.floor(TOKEN_TIME - MAX_SESSION_MODAL_TIME));
  const [seconds, setSeconds] = useState(Number(((TOKEN_TIME - MAX_SESSION_MODAL_TIME) % 1).toFixed(1)) * 60);
  const [count, setCount] = useState((TOKEN_TIME - MAX_SESSION_MODAL_TIME) * 60000); //milliseconds
  const requestRef = useRef();
  const previousTimeRef = useRef();

  const animateCountdown = (currentTime) => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = currentTime - previousTimeRef.current;
      if (deltaTime > 1000 && count > 0) {
        // console.log('decrement time: ' + deltaTime); // great for debugging
        setCount((prevCount) => {
          const newCount = prevCount - deltaTime;
          setMinutes((prevMinutes) => Math.floor(newCount / 60000));
          setSeconds((prevSeconds) => Math.floor((newCount / 1000) % 60));
          return newCount;
        });
        previousTimeRef.current = currentTime;
      }
    } else {
      previousTimeRef.current = currentTime;
    }
    requestRef.current = requestAnimationFrame(animateCountdown);
  };

  useEffect(() => {
    if (maxSessionModalVisible) {
      requestRef.current = requestAnimationFrame(animateCountdown);
      return () => cancelAnimationFrame(requestRef.current);
    }
  }, [maxSessionModalVisible]); // Make sure the effect runs only once

  useEffect(() => {
    if (maxSessionModalVisible) {
      window.onpopstate = (e) => {
        dispatch(setMaxSessionModal(false));
      };
    }
  }, [maxSessionModalVisible]);

  const handleLogout = () => {
    if (getSsoAuthKey() === 'true') {
      history.replace(SSO_SESSION_LOGOUT_PAGE);
    } else {
      window.open(RETURN_TO_OLB_LINK, '_self');
      setTimeout(() => {
        dispatch(logout());
      }, 225);
    }
  };

  const ReturnToOLBButton = () => (
    <Button
      id="MaxSessionModalLogoutNow"
      className={'envelope-confirmation-button'}
      variant="secondary"
      style={{width: isSmallScreen ? '100%' : 'auto', margin: 0, marginTop: isSmallScreen ? 15 : 0}}
      onClick={handleLogout}
    >
      Return to Online Banking
    </Button>
  );

  const StayButton = () => (
    <Button
      id="MaxSessionModalContinue"
      className={'envelope-cancel-button'}
      variant="primary"
      style={{width: isSmallScreen ? '100%' : 'auto', margin: 0, marginLeft: isSmallScreen ? 0 : 15}}
      onClick={() => dispatch(setMaxSessionModal(false))}
    >
      Stay on Page
    </Button>
  );

  return (
    <Modal id={'SessionTimeoutModal'} show={maxSessionModalVisible} onHide={() => dispatch(setMaxSessionModal(false))}>
      <Modal.Header closeButton className='border-0'>
        <Modal.Title style={universalStyles.modalHeader}>Need More Time?</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body-footer-present'>
        <p>Your session may not exceed the maximum duration.</p>
        <div id="countDownText" style={styles.countdownText}>
          Session ending in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </div>
      </Modal.Body>
      <Modal.Footer className='border-0' style={{padding: 24, paddingTop: 8}}>
        {isSmallScreen ? (
          <Flexbox flexDirection='column' alignItems='center' style={{width: '100%'}}>
            {StayButton()}
            {ReturnToOLBButton()}
          </Flexbox>
        ) : (
          <Flexbox alignItems='center'>
            {ReturnToOLBButton()}
            {StayButton()}
          </Flexbox>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default MaxSessionModal;
