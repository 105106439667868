import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Flexbox from 'flexbox-react';
import { Card, Dropdown } from 'react-bootstrap';
import { ClipboardData, PencilSquare, XCircle } from 'react-bootstrap-icons';
import DeleteGoalModal from 'components/modals/DeleteGoalModal';
import {useDispatch} from 'react-redux';

import { styles } from './styles';
import { deleteFinancialGoal } from 'actions';
import { recommendationsSelector, userSelector } from 'selectors';
import { tagFinishRemoveGoal, tagStartRemoveGoal } from 'services/gtm';
import dotIcon from '../../../assets/images/icons/dots.svg';

export const GoalTile = ({financialGoal, index, navToGoal, isAdditionalActionsNeeded, removeGoalFunction, navToEditGoal, currentGoalMenu, setCurrentGoalMenu}) => {
  const recommendations = useSelector(recommendationsSelector);
  const user = useSelector(userSelector);
  const [numActions, setNumActions] = useState(0);
  const [deleteGoalModalVisible, setDeleteGoalModalVisible] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState({});  
  const dispatch = useDispatch();

  useEffect(() => {
    const goalRecommendations = recommendations.filter((reco) => reco.goalId === financialGoal.financialGoalId);
    if (goalRecommendations.length > 0) {
      setNumActions(goalRecommendations[0]?.recommendations?.length);
    }
  }, [recommendations]);

  const handleDeleteModal = (goal) => {
    removeGoalFunction(goal);
    setSelectedGoal(goal);
    tagStartRemoveGoal(user, goal.financialGoalId);
    setDeleteGoalModalVisible(true);
  };

  const deleteGoal = (goal) => {
    let goalsToDelete = [];
    tagFinishRemoveGoal(user, goal.financialGoalId);
    goalsToDelete.push(goal);
    dispatch(deleteFinancialGoal(goalsToDelete));
  };

  const dropdownToggleHandler = (nextShow, index) => {
    if (nextShow) {
      setCurrentGoalMenu(index);
    } else {
      if (currentGoalMenu === index) {
        setCurrentGoalMenu(null);
      }
    }
  };

  return (
    <Card key={index} style={{...styles.card, marginTop: (index !== 0) ? 30 : 0}}>
      <Card.Body style={styles.cardBody}>
        <Flexbox alignItems='center'>
          <Flexbox justifyContent='center' alignItems='center' style={styles.iconWrapper}>
            <img src={require(`../../../assets/images/financial/${financialGoal.imgName}`)} style={{maxWidth: 32}} aria-hidden='true' />
          </Flexbox>
          <div>
            <button name={`FinancialGoal-${index + 1}`} className={'link-button stretched-link'} style={{textAlign: 'left', fontWeight: 700, marginLeft: -5}} onClick={() => navToGoal(financialGoal, 'tile')}>
              {financialGoal.goal}
            </button>
            <div style={styles.recommendedActions}>{isAdditionalActionsNeeded ? financialGoal.questionsActionRequiredSubtitle : ((numActions > 0) ? `${numActions} recommended action${(numActions === 1) ? '' : 's'}` : '')}</div>
          </div>
        </Flexbox>
        <Dropdown align={'end'} style={{zIndex: (currentGoalMenu === index || currentGoalMenu === null) ? 2 : 1}} onToggle={(nextShow) => dropdownToggleHandler(nextShow, index)}>
          <Dropdown.Toggle
            className={'link-button link-dropdown'}
            variant={'link'}
            role='button'
            data-toggle='dropdown'
            id={`${financialGoal.goal}-Menu`}
            style={styles.dropdownToggle}
          >
            <img
              src={dotIcon}
              style={{width: 16}}
              alt={'financial goal menu'}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu aria-labelledby={`${financialGoal.goal}-Menu`} role='menu' style={{ position: 'absolute', top: '100%', right: 0 }}>
            {!isAdditionalActionsNeeded &&
              <Dropdown.Item
                role='menuitem'
                onClick={() => navToEditGoal(financialGoal)}
                style={styles.dropdownItem}
              >
                <PencilSquare size={16} color={'black'} style={{marginRight: 8}} />
                Edit Goal
              </Dropdown.Item>
            }
            <Dropdown.Item
              role='menuitem'
              onClick={() => handleDeleteModal(financialGoal)}
              style={styles.dropdownItem}
            >
              <XCircle size={16} color={'black'} style={{marginRight: 8}} />
              Remove from Goals
            </Dropdown.Item>
            <Dropdown.Item
              role='menuitem'
              onClick={() => navToGoal(financialGoal, 'view-recommendations')}
              style={styles.dropdownItem}
            >
              <ClipboardData size={16} color={'black'} style={{marginRight: 8}} />
              View Recommendations
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <DeleteGoalModal
          modalVisible={deleteGoalModalVisible}
          setModalVisible={setDeleteGoalModalVisible}
          financialGoal={selectedGoal}
          modalFunction={deleteGoal}
        />
      </Card.Body>
    </Card>    
  );
};