import {GET_USER_SUCCESS, SET_FIRST_TIME_USER} from '../config/actionTypes';

const INITIAL_STATE = {
  user: {},
  initialized: false,
  mobileAppUser: false,
};

export const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        initialized: true,
      };
    case SET_FIRST_TIME_USER:
      return {
        ...state,
        user: {...state.user, isFirstTimeUser: action.payload},
      };
    default:
      return state;
  }
};
