import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import Flexbox from 'flexbox-react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import PageMetaData from 'components/app/PageMetaData';
import Header from 'components/app/Header';
import Subheader from 'components/app/Subheader';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {FAQ_PAGE, HOME_PAGE, LOGIN_PAGE, RETURN_TO_OLB_LINK} from 'config/constants';
import {authSelector, intializedSelector, sessionTokenSelector} from 'selectors';
import {loginWithToken} from 'actions';
import notFoundImage from '../../assets/images/not-found.svg';

const NotFound = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedIn = useSelector(authSelector);
  const initialized = useSelector(intializedSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const isBigScreen = useMediaQuery({query: '(min-width: 576px)'});
  const loginRedirect = history?.location?.pathname === LOGIN_PAGE;

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      window.open(RETURN_TO_OLB_LINK, '_self');
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    }
  }, [loggedIn, initialized]);

  const handleReturnButton = () => {
    if (loginRedirect) {
      window.open(RETURN_TO_OLB_LINK, '_self');
    } else {
      history.push({pathname: HOME_PAGE});
    }
  };

  return (
    <Flexbox flexDirection={'column'} style={universalStyles.container}>
      <PageMetaData pageTitle={'Page Not Found | BECU'} />
      <Header />
      <Subheader title={'404 Error'} />
      <Container role={'main'} id="main-content">
        <Row>
          <Col md={{span: 8, offset: 2}} style={{...universalStyles.columnPadding, textAlign: 'center'}}>
            <img
              src={notFoundImage}
              alt={'page not found'}
              style={{...styles.notFoundImage, ...(isBigScreen ? styles.largerImage : styles.smallerImage)}}
            />
            <div style={styles.bodyText}>It looks like your page cannot be found.</div>
            <Button id="ReturnHomeButton" variant={'primary'} style={styles.notFoundButton} onClick={handleReturnButton}>
              Return {loginRedirect ? 'to Online Banking' : 'Home'}
            </Button>
            {!loginRedirect &&
              <Button id="FAQButton" variant={'secondary'} style={styles.notFoundButton} onClick={() => history.push({pathname: FAQ_PAGE})} aria-label="Frequently asked questions and tips">
                FAQs and Tips
              </Button>
            }
          </Col>
        </Row>
      </Container>
    </Flexbox>
  );
};

export default NotFound;
