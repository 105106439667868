/*========== AUTH ==========*/
export const LOGIN_FAILURE = 'login_failure';
export const LOGIN_SUCCESS = 'login_success';
export const LOGOUT_SUCCESS = 'logout_success';

/*========== SESSION ==========*/
export const UPDATE_SESSION_TOKEN = 'set_session_token';
export const UPDATE_SESSION_PATH = 'set_session_path';

/*========== UI ==========*/
export const UPDATE_BREADCRUMBS = 'update_breadcrumbs';
export const SAVE_CHANGE_MODAL = 'update_save_change_modal';
export const UPDATE_ACCOUNTS_MODAL = 'update_accounts_modal';
export const UPDATE_ERROR_MODAL = 'update_error_modal';
export const UPDATE_MAX_SESSION_MODAL = 'update_max_session_modal';
export const SET_API_ERROR = 'set_api_error';
export const SURVEY_MONKEY_MODAL = 'update_survey_monkey_modal';
export const EXTERNAL_LINK_MODAL = 'update_external_link_modal';
export const UPDATE_TERMS_MODAL = 'update_terms_modal';

/*========== USER ==========*/
export const GET_USER_SUCCESS = 'get_user_success';
export const SET_FIRST_TIME_USER = 'set_first_time_user';

/*========== FINANCIAL HEALTH ==========*/
export const GET_FINANCIAL_GOAL_SUCCESS = 'get_financial_goal_success';
export const GET_FINANCIAL_TOPIC_SUCCESS = 'get_financial_topic_success';
export const GET_FINANCIAL_RECOMMENDATIONS_SUCCESS = 'get_financial_recommendations_success';
export const CREATE_FINANCIAL_GOAL_SUCCESS = 'create_financial_goal_success';
export const CREATE_FINANCIAL_TOPIC_SUCCESS = 'create_financial_topic_success';
export const DELETE_FINANCIAL_GOAL_SUCCESS = 'delete_financial_goal_success';
export const DELETE_FINANCIAL_TOPIC_SUCCESS = 'delete_financial_topic_success';
export const GET_CTA_GOALS_SUCCESS = 'get_cta_goals_success';
export const EDIT_GOAL_SUCCESS = 'edit_goal_success';
export const UPDATE_TUTORIAL = 'update_tutorial';
export const UPDATE_COMPLETION_SUCCESS = 'update_completion_success';
export const UPDATE_FAVORITES_SUCCESS = 'update_favorites_success';
export const GET_COMPLETED_FAVORITE_RECOMMENDATIONS_SUCCESS = 'get_completed_favorite_recommendations_success';