export const formatBreadcrumbs = (breadcrumbs) => {
  breadcrumbs.forEach((breadcrumb, index) => {
    if (index < breadcrumbs.length - 1) {
      breadcrumb.active = false;
    } else {
      breadcrumb.active = true;
    }
  });

  return breadcrumbs;
};