import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Flexbox from 'flexbox-react';
import { Button } from 'react-bootstrap';

import { styles } from 'modules/Home/styles';
import { ONBOARD_DATA } from 'config/financialOnboard';

const OnboardGoalsStart = ({ getStarted }) => {
  const imgMobileBreakpoint = useMediaQuery({query: '(max-width: 767px)'});
  const isBigScreen = useMediaQuery({query: '(min-width: 575px)'});
  const info = ONBOARD_DATA[0];

  return (
    <Flexbox flexDirection={(imgMobileBreakpoint) ? 'row' : 'column'} style={{marginBottom: 5}}>
      <div>
        <div style={{ marginBottom: 24 }}>
          {imgMobileBreakpoint ? (
            <h2>Your Goals</h2>
          ) : (
            <h4>Your Goals</h4>
          )}
        </div>
        <p style={{fontSize: 14}}>You do not have any goals at this time.</p>
        <hr style={{marginTop: 30, marginBottom: 46}} />
        <h4>Ready to start another goal?</h4>
        <Flexbox flexDirection={(imgMobileBreakpoint) ? 'column' : 'row'}>
          <div style={{width: imgMobileBreakpoint ? '100%' : 212}}>
            <img src={!imgMobileBreakpoint ? require(`../../../assets/images/${info.image}`) : require(`../../../assets/images/${info.mobileImage}`)} aria-hidden='true' />
          </div>
          <Flexbox flexDirection='column' style={{marginTop: (imgMobileBreakpoint) ? 10 : 0, marginLeft: (imgMobileBreakpoint) ? 0 : 20}}>
            <span style={{fontSize: 14}}>{info.body}</span>
            <Button variant='primary' style={{...styles.financialHealthButton, width: (isBigScreen) ? 120 : '100%'}} onClick={getStarted}>Get Started</Button>
          </Flexbox>
        </Flexbox>
      </div>
    </Flexbox>
  );
};

export default OnboardGoalsStart;
