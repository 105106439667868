import {colors} from 'styles/config';

export const styles = {
  categoryTitle: {
    marginBottom: '1.5rem',
  },
  subcategoryTitle: {
    marginBottom: '1rem',
  },
  subheaderWrapper: {
    width: '100%',
    backgroundColor: colors.cloud,
  },
  breadcrumbList: {
    backgroundColor: colors.snow,
    marginBottom: 0,
  },
};
