import Flexbox from 'flexbox-react';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Bookmarks, BookmarksFill, Check, CheckSquareFill } from 'react-bootstrap-icons';
import InfoPopover from '../../../components/app/InfoPopover';

import { styles } from './styles';
import { colors } from 'styles/config';
import { updateRecommendationCompletion, updateRecommendationFavorites } from 'actions';
import { getMatchingRecommendations, getStarted, learnMore } from 'utils/recommendations';

const RecommendationCard = (item, index, goalId, isSmallScreen, formatDescription, tagSelection, dispatch, navToTutorial, navToRecommendation, topRecommendationId) => (
  <div key={index} style={{...styles.card, marginTop: 30, paddingTop: (topRecommendationId === item.recommendationsId) ? 17 : 30 }}>
    <Flexbox flexDirection={(isSmallScreen) ? 'column' : 'row'} alignItems={(isSmallScreen) ? 'flex-start' : 'center'} justifyContent='space-between' style={{ paddingBottom: (topRecommendationId === item.recommendationsId) ? 12 : (isSmallScreen ? 8 : 24), borderBottom: `1px solid ${colors.storm}` }}>
      <h4 style={{fontSize: 20, marginBottom: (isSmallScreen) ? 10 : 0}} name={`RecommendationTitle-${index + 1}`} className='recommendation-title'>{item.packageName}</h4>
      {(topRecommendationId === item.recommendationsId) &&
        <InfoPopover
          index={1}
          title={'Top Recommendation'}
          content={'Based on your profile answers, we recommend this as the first step to take.'}
          ariaLabel={'View top recommendation information'}
        />
      }
    </Flexbox>
    <button
      id={`Favorites-${index + 1}`}
      className='text-link-button'
      style={{display: 'flex', alignItems: 'center', marginTop: 15, color: colors.darkTeal}}
      onClick={() => dispatch(updateRecommendationFavorites(getMatchingRecommendations(item.packageName) ?? [{recommendationId: parseInt(item.recommendationsId), goalId: parseInt(goalId)}], !item.isFavorite))}
    >
      {item.isFavorite ? <BookmarksFill size={24} color={colors.darkTeal} style={{marginRight: 10}} /> : <Bookmarks size={24} color={colors.darkTeal} style={{marginRight: 10}} />}
      {item.isFavorite ? 'Saved to Favorites' : 'Add to Favorites'}
    </button>
    <button
      id={`Complete-${index + 1}`}
      className='text-link-button'
      style={{display: 'flex', alignItems: 'center', marginTop: 15, color: colors.darkTeal}}
      onClick={() => dispatch(updateRecommendationCompletion(item.recommendationsId, goalId, !item.isCompleted))}
    >
      {item.isCompleted ? <CheckSquareFill size={24} color={colors.darkTeal} style={{marginRight: 10}} /> : <Check size={24} color={colors.darkTeal} style={{marginRight: 10}} />}
      {item.isCompleted ? 'Completed' : 'Mark Complete'}
    </button>
    <div style={{marginTop: 30}}>
      {formatDescription((item.longDescription === '') ? item.description : item.longDescription).map((desc, index) => (
        <p key={index}>{desc}</p>
      ))}
    </div>
    <div style={{marginTop: 30}}>
      {isSmallScreen ? (
        <Flexbox flexDirection='column'>
          {(item.getStartedLink !== '' && item.longDescription !== '') &&
            <Button
              id="Recommendation-GetStarted"
              variant="primary"
              style={{width: '100%'}}
              onClick={() => {
                tagSelection('get-started', item);
                getStarted(item, dispatch);
              }}
            >
              Get Started
            </Button>
          }
          <Button
            id="Recommendation-LearnMore"
            variant="secondary"
            style={{width: '100%', marginTop: 16 }}
            onClick={() => {
              tagSelection('learn-more', item);
              learnMore(item, navToTutorial, navToRecommendation, dispatch);
            }}
          >
            Learn More
          </Button>
        </Flexbox>
      ) : (
        <Flexbox justifyContent='flex-start' alignItems='center'>
          <Button
            id="Recommendation-LearnMore"
            variant="secondary"
            style={{width: 'auto'}}
            onClick={() => {
              tagSelection('learn-more', item);
              learnMore(item, navToTutorial, navToRecommendation, dispatch);
            }}
          >
            Learn More
          </Button>
          {(item.getStartedLink !== '' && item.longDescription !== '') &&
            <Button
              id="Recommendation-GetStarted"
              variant="primary"
              style={{width: 'auto', marginLeft: 16}}
              onClick={() => {
                tagSelection('get-started', item);
                getStarted(item, dispatch);
              }}
            >
              Get Started
            </Button>
          }
        </Flexbox>
      )}
    </div>
  </div>
);

export default RecommendationCard;
