import { colors } from "styles/config";

export const styles = {
  columnPadding: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  subheaderWrapper: {
    width: '100%',
    backgroundColor: colors.cloud,
  },
  subheaderContainer: {
    height: 205,
    display: 'flex',
    justifyContent: 'space-between',
  },
  tutorialImageBig: {
    width: 236,
    height: 225,
    marginTop: 60,
  },
  tutorialImageSmall: {
    width: 177,
    height: 168,
    marginTop: -12,
  },
  tutorialTitleBig: {
    width: '100%',
    paddingTop: 110,
  },
  tutorialTitleSmall: {
    width: '70%',
    paddingTop: 32,
  },
};
