import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Flexbox from 'flexbox-react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Header from 'components/app/Header';
import FeedbackTab from 'components/app/FeedbackTab';
import PageMetaData from 'components/app/PageMetaData';
import FinancialHealthGoals from 'components/profile/goals';
import FinancialHealthQuiz from 'components/profile/topics';
import QuizConfirmation from 'components/profile/confirm';
import Breadcrumbs from 'components/app/Breadcrumbs';
import AlertBanner from 'components/app/AlertBanner';
import RecommendedGoalsModal from 'components/modals/RecommendedGoalsModal';

import {
  CheckCircle,
  CircleFill,
} from 'react-bootstrap-icons';

import { colors } from 'styles/config';
import { styles } from './styles';
import { universalStyles } from 'styles/universalStyles';
import { FINANCIAL_HEALTH_HOME_PAGE_TITLE, HOME_PAGE, PROFILE_PAGE, RETURN_TO_OLB_LINK } from 'config/constants';
import {
  authSelector,
  financialHealthGoalAddedSelector,
  financialHealthGoalSelector,
  financialHealthTopicAddedSelector,
  financialHealthTopicSelector,
  intializedSelector,
  recommendationsSuccessSelector,
  ctaGoalsSuccessSelector,
  ctaGoalsSelector,
  sessionTokenSelector,
  userSelector
} from '../../selectors';
import { getFinancialGoal, getFinancialTopic, createFinancialTopic, createFinancialGoal, resetFinancialGoalAdded, loginWithToken, setSessionPath, getUser, getRecommendations, resetRecommendationsSuccess, getCTAGoals, resetCTAGoalsSuccess, updateBreadcrumbs } from '../../actions';
import { tagOnboardingComplete, tagRecommendedGoals } from 'services/gtm';


const steps = ['Goals', 'Expenses', 'Income', 'Bills', 'Confirm'];

const FinancialHealthProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const financialHealthGoal = useSelector(financialHealthGoalSelector);
  const financialHealthTopic = useSelector(financialHealthTopicSelector);
  const financialGoalAdded = useSelector(financialHealthGoalAddedSelector);
  const financialTopicAdded = useSelector(financialHealthTopicAddedSelector);
  const recommendationsSuccess = useSelector(recommendationsSuccessSelector);
  const ctaGoalsSuccess = useSelector(ctaGoalsSuccessSelector);
  const ctaGoals = useSelector(ctaGoalsSelector);
  const [recommendedGoalsModalVisible, setRecommendedGoalsModalVisible] = useState(false);
  const [recommendedGoals, setRecommendedGoals] = useState([]);
  const user = useSelector(userSelector);
  const loggedIn = useSelector(authSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const initialized = useSelector(intializedSelector);
  const [step, setStep] = useState(0);
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});
  const largeScreen = useMediaQuery({query: '(min-width: 768px)'});
  const [financialGoal, setFinancialGoal] = useState([]);
  const [financialAnswerId, setFinancialAnswerId] = useState([]);
  const [disableNextBtn, setDisableBtn] = useState(true);
  const [addGoalView, setAddGoalView] = useState(false);
  const [addedGoals, setAddedGoals] = useState([]);
  const [prepopulatedBanner, setPrepopulatedBanner] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      window.open(RETURN_TO_OLB_LINK, '_self');
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    }
      dispatch(getFinancialGoal());
      dispatch(getFinancialTopic());
  }, [loggedIn, sessionToken, initialized, history]);

  useEffect(() => {
    const goalBreadcrumbs = [
      {
        title: 'Financial Goals & Resource Guide',
        page: HOME_PAGE,
        active: false,
      },
      {
        title: 'Your Financial Profile',
        page: PROFILE_PAGE,
        active: true,
      },
    ];
    dispatch(updateBreadcrumbs(goalBreadcrumbs));
  }, []);

  useEffect(() => {
    if (financialGoalAdded && financialTopicAdded) {
      const goalIds = financialHealthGoal.financialGoal.map((x) => x.financialGoalId);
      const selectedGoalIds = financialGoal.map((x) => parseInt(x.goalId));
      tagOnboardingComplete(user, selectedGoalIds, goalIds.filter((x) => !selectedGoalIds.includes(x)), financialAnswerId.map((x) => x.financialProfileAnswerId));

      dispatch(getUser());
      dispatch(getRecommendations(true));
      dispatch(resetFinancialGoalAdded()); // to save recommendations again
    }
  }, [financialGoalAdded, financialTopicAdded]);

  useEffect(() => {    
    if(recommendedGoals.length > 0) // recommendedGoals saved
    {
      processCompleteFc();
    }
    else
    if (recommendationsSuccess) {      
      dispatch(getCTAGoals( ["CTARecommendedGoals"] ));
      dispatch(resetRecommendationsSuccess());
    }
      
  }, [recommendationsSuccess]);

  useEffect(() => {
    if (ctaGoalsSuccess) {

      dispatch(resetCTAGoalsSuccess());

      let recommendedGoalsIn = [];
      ctaGoals?.ctaRecommendedGoalsKey && ctaGoals.ctaRecommendedGoalsKey.ctaRecommendedGoalsValue.forEach((el) => {

        if ((addedGoals.some(el2 => el2.goalId == el.goal.financialGoalId) && el.recommendedGoals.length > 0)) { 
          el.recommendedGoals.forEach((el3) => {
            if(!recommendedGoalsIn.some(x => x.financialGoalId == el3.financialGoalId) && !addedGoals.some(y => y.goalId == el3.financialGoalId)) { // avoid duplicates and avoid recommend a goal already added
              recommendedGoalsIn.push(el3);
            }            
          });          
        }
      });

      if (recommendedGoalsIn.length > 0) {
        setRecommendedGoals(recommendedGoalsIn);
        setRecommendedGoalsModalVisible(true);
      } else {
        processCompleteFc();
      }
    }
  }, [ctaGoalsSuccess]);

  const recommendedGoalFc = () => {
    tagRecommendedGoals(user, true);
    dispatch(createFinancialGoal(recommendedGoals.map((el) => ({financialGoalId: parseInt(el.financialGoalId), goal: el.goal}))));      
  };

  useEffect(() => {
    if (financialGoalAdded && recommendedGoals.length > 0) {
      dispatch(getRecommendations(true));
    }
  }, [financialGoalAdded, recommendedGoals]);


  const processCompleteFc = (tagEvent = false) => {
    if (tagEvent) {
      tagRecommendedGoals(user, false);
    }

    const path = {pathname: HOME_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
  };

  useEffect(() => {
    if (user?.financialProfileTopicAnswers) {
      let userAnswers = ['Expenses', 'Income', 'Bills'];
      userAnswers = userAnswers.map((topic) => addTopicAnswer(topic));
      setFinancialAnswerId(userAnswers);
      setPrepopulatedBanner(true);
    }    
  }, [user]);  

  const addTopicAnswer = (topic) =>  {
    let financialProfileAnswerId = user?.financialProfileTopicAnswers.filter((financialTopic) => financialTopic.financialTopic.topic === topic).map((x) => x.financialAnswers)[0][0].financialProfileAnswerId;
    return {topic, financialProfileAnswerId};
  };
  
  const addFinancialGoal = (goalId, goal, imgName) => { 
    const goalFound = (!addGoalView) ? financialGoal.some(el => el.goalId === goalId) : addedGoals.some(el => el.goalId === goalId);
    let financialGoalCopy = addGoalView ? [...addedGoals] : [...financialGoal];

    if (!goalFound) {
      financialGoalCopy.push({goalId, goal, imgName});
    } else {
      financialGoalCopy = financialGoalCopy.filter(id => id.goalId !== goalId);
    }

    if (!addGoalView) {
      setFinancialGoal(financialGoalCopy);
      setDisableBtn(!financialGoalCopy.length > 0);
    }

    setAddedGoals(financialGoalCopy);    
  };
  
  const addFinancialTopicAnswer = (financialProfileAnswerId, topic) => {
    const itemFound = financialAnswerId && financialAnswerId.some(el => el.topic === topic);

    if (itemFound) {
      let index = financialAnswerId.findIndex(el => el.topic == topic);
      let arrayCopy = [...financialAnswerId];
      arrayCopy[index] = {...arrayCopy[index], financialProfileAnswerId: financialProfileAnswerId};
      setFinancialAnswerId(() => arrayCopy);
    } else {
      financialAnswerId.push({topic, financialProfileAnswerId});
      setFinancialAnswerId(() => financialAnswerId);
    }

    if(financialGoal?.length > 0)
      setDisableBtn(false);
  };

  const checkButtonStatus = (step, updateFocus = false) => {
    if (step >= steps.length && !addGoalView) {  
      dispatch(createFinancialTopic(financialAnswerId.map((el) => el.financialProfileAnswerId)));
      dispatch(createFinancialGoal(financialGoal.map((el) => ({financialGoalId: parseInt(el.goalId), goal: el.goal}))));
    } else {
      setStep(step);
      setDisableBtn(setDisableButtonValue(step));
      if (updateFocus) {
        focusHeader();
      }
    }
  };

  const focusHeader = () => {
    setTimeout(() => {
      headerRef.current.focus();
    }, 200);
  };
  
  const setDisableButtonValue = (step) => {
    switch (step) {
      case 0:
        return !financialGoal.length > 0;
      case 1:
        return financialAnswerId.findIndex(el => el.topic === 'Expenses') === -1;
      case 2:
        return financialAnswerId.findIndex(el => el.topic === 'Income') === -1;
      case 3:
        return financialAnswerId.findIndex(el => el.topic === 'Bills') === -1;
      default:
        return false;
    }
  };

  const addSelectedGoals = () => {
    setFinancialGoal([...addedGoals]);
    setAddGoalView(false);
    checkButtonStatus(step, true);
  };

  const displayScreen = () => {
    switch (step) {
      case 0:
        return <FinancialHealthGoals addFinancialGoal={addFinancialGoal} financialHealthGoal={financialHealthGoal} selectedGoals={financialGoal} addGoalView={addGoalView} headerRef={headerRef} />;
      case 1:
        return <FinancialHealthQuiz addFinancialTopicAnswer={addFinancialTopicAnswer} financialHealthTopic={financialHealthTopic} topic='Expenses' answers={financialAnswerId} headerRef={headerRef} />;
      case 2:
        return <FinancialHealthQuiz addFinancialTopicAnswer={addFinancialTopicAnswer} financialHealthTopic={financialHealthTopic} topic='Income' answers={financialAnswerId} headerRef={headerRef} />;
      case 3:
        return <FinancialHealthQuiz addFinancialTopicAnswer={addFinancialTopicAnswer} financialHealthTopic={financialHealthTopic} topic='Bills' answers={financialAnswerId} headerRef={headerRef} />;
      case 4:
        return <QuizConfirmation addFinancialGoal={addFinancialGoal} addFinancialQuizAnswer={addFinancialTopicAnswer} financialHealthGoal={financialHealthGoal} selectedGoals={financialGoal}  financialHealthQuizAnswers={financialAnswerId} financialHealthTopicHeaders={financialHealthTopic} setAddGoalView={setAddGoalView} headerRef={headerRef} />;
      default:
        return null;
    }
  };

  const BackButton = () => (
    <Button
      id={'FinancialProfileBack'}
      variant={'secondary'}
      style={{width: isSmallScreen ? '100%' : 'auto', marginTop: isSmallScreen ? 15 : 0}}
      onClick={() => {
        if (step === 0) {
          const path = {pathname: HOME_PAGE};
          dispatch(setSessionPath(path));
          history.push(path);
        } else {
          if (addGoalView) {
            setAddGoalView(false);
            setAddedGoals([...financialGoal]);
            focusHeader();
          } else {
            checkButtonStatus(step - 1, true);
          }
        }
      }}
    >
      {(step === 0) ? 'Cancel' : 'Back'}
    </Button>
  );

  const NextButton = () => (
    <Button
      id={addGoalView ? 'FinancialProfileAddGoals' : 'FinancialProfileNext'}
      variant={'primary'}
      style={{width: isSmallScreen ? '100%' : 'auto', marginLeft: isSmallScreen ? 0 : 15}}
      onClick={() => addGoalView ? addSelectedGoals() : checkButtonStatus(step + 1, true)}
      disabled={addGoalView ? addedGoals.length === 0 : disableNextBtn}                
      aria-disabled={addGoalView ? false : disableNextBtn}
    >
      {addGoalView ? 'Add Selected Goals' : ((step + 1 === steps.length) ? 'Finish and Save' : 'Next')}
    </Button>
  );

  const getPageTitle = () => {
    const profilePageTitle = FINANCIAL_HEALTH_HOME_PAGE_TITLE.split('|');
    profilePageTitle[0] += `${steps[step]}, step ${step + 1} of ${steps.length} `;
    return profilePageTitle.join('|');
  };

  return (
    <Flexbox flexDirection='column' style={universalStyles.container}>
      <PageMetaData pageTitle={getPageTitle()} />
      <Header />
      <Container role={'main'} id="main-content" style={{paddingTop: 50}}>
        <Breadcrumbs />
        <Row style={{paddingTop: 20, paddingBottom: 60}}>
          <Col md={8}>
            <AlertBanner
              type={'info'}
              name={'PrepopulatedTopicAnswersInfo'}
              show={() => prepopulatedBanner && step > 0}
              dismissible={true}
              onClose={() => setPrepopulatedBanner(false)}
              message={'Your previous selections have been pre-populated. Review & update if needed.'}
            />
          </Col>
          <Col md={7}>
            <h1>Your Financial Profile</h1>
            <p>{step === 4 ? 'Please review the following selections. You can change these later at any time.' : 'In just a few short steps, we will help you identify actions that you can take today to improve your financial health.'}</p>
            <hr style={{backgroundColor: colors.storm, marginTop: '2rem', marginBottom: 0}} />
          </Col>
          <Col md={7} style={{position: 'relative'}}>
            {/* TODO: Move step tracker out of Profile component */}
            <>
              <div className='step-tracker-line' style={styles.trackerLine}>
                <div style={{...styles.filledTrackerLine, width: `${step * 25}%`}} />
              </div>
              {largeScreen ? (
                <ol aria-label="progress" style={styles.trackerContainer}>
                  {steps.map((stepName, index) => {
                    return (
                      <li key={index} style={styles.trackerItem}>
                        <Flexbox justifyContent={'center'} alignItems={'center'} style={step === index ? styles.currentStep : styles.step}>
                          {step === index ? <CircleFill color={colors.harbor} size={20} /> : (
                            step > index ? <CheckCircle color={colors.harbor} size={24} /> : <CircleFill color={colors.storm} size={24} />
                          )}
                          {step <= index && <span style={{position: 'absolute', color: step === index ? colors.snow : colors.stone, fontSize: 14, fontWeight: 700}}> {index + 1} </span>}
                        </Flexbox>
                        <button
                          id={`TargetStep-${index}`}
                          className={(step <= index || addGoalView) ? 'inactive-step btn-hide-pointer link-button no-underline' : 'link-button no-underline'}
                          onClick={() => checkButtonStatus(index)}
                          disabled={step < index}
                          aria-disabled={step < index}
                          aria-label={`${stepName}, step ${index + 1} of ${steps.length}`}
                        >
                          {stepName}
                        </button>
                      </li>
                    );
                  })}
                </ol>
              ) : (
                <div style={{...styles.trackerContainer, justifyContent: 'flex-start'}}>
                  <div style={{transition: 'width 0.5s linear', width: `${step * 20}%`}} />
                  <div style={{...styles.trackerItem, minWidth: 84}}>
                    <Flexbox justifyContent={'center'} alignItems={'center'} style={styles.currentStep}>
                      <CircleFill color={colors.harbor} size={20} />
                      <span style={{position: 'absolute', color: colors.snow, fontSize: 14, fontWeight: 700}}> {step + 1} </span>
                    </Flexbox>
                    Step {step + 1} of {steps.length}
                  </div>
                </div>
              )}
            </>
          </Col>
          <Col md={7}>
            {addGoalView ? (
              <FinancialHealthGoals addFinancialGoal={addFinancialGoal} financialHealthGoal={financialHealthGoal} selectedGoals={addedGoals} addGoalView={addGoalView} />
              ) : (
              displayScreen()
            )}
            <div style={{marginTop: 30}}>
              {isSmallScreen ? (
                <Flexbox flexDirection='column'>
                  {NextButton()}
                  {BackButton()}
                </Flexbox>
              ) : (
                <Flexbox justifyContent='flex-start' alignItems='center'>
                  {BackButton()}
                  {NextButton()}
                </Flexbox>
              )}
            </div>
          </Col>
        </Row>
        <RecommendedGoalsModal
          modalVisible={recommendedGoalsModalVisible}
          setModalVisible={setRecommendedGoalsModalVisible} 
          recommendedGoals={recommendedGoals}         
          modalFunction={recommendedGoalFc}
          cancelFunction={processCompleteFc}
        />
        <FeedbackTab />
      </Container>
    </Flexbox>
  );
};

export default FinancialHealthProfile;