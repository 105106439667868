import React from 'react';
import { useDispatch } from 'react-redux';
import {styles} from './styles';
import { updateExternalLinkModal } from 'actions';

const TipBox = ({title, content, topMargin, learnMoreTag, learnMoreLink, externalLink = false}) => {
  const dispatch = useDispatch();

  const handleLink = () => {
    if (!externalLink) {
      window.open(learnMoreLink, '_blank');
    } else {
      dispatch(updateExternalLinkModal(true, learnMoreLink, false));
    }
  };

  return (
    <div style={{...styles.tipWrapper, marginTop: topMargin }}>
      <h4>{title}</h4>
      <p>{content}</p>
      {learnMoreLink &&
        <button
          id="learnMoreLink"
          className={'text-link-button'}
          onClick={handleLink}
        >
          {learnMoreTag ?? 'Learn More'}
        </button>
      }
    </div> 
  );
};

export default TipBox;