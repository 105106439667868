import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Flexbox from 'flexbox-react';
import {Button, Modal} from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

import {universalStyles} from 'styles/universalStyles';
import { externalLinkModalSelector } from 'selectors';
import { updateExternalLinkModal } from 'actions';

const ExternalLinkModal = () => {
  const dispatch = useDispatch();
  const externalLinkModal = useSelector(externalLinkModalSelector);
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});

  const CancelButton = () => (
    <Button
      id="SSOCancelLogout"
      className={'envelope-cancel-button'}
      variant="secondary"
      style={{width: (isSmallScreen) ? '100%' : 'auto', marginTop: isSmallScreen ? 16 : 0 }}
      onClick={() => dispatch(updateExternalLinkModal(false, ''))}
    >
      No Thanks
    </Button>
  );

  const ContinueButton = () => (
    <Button
      id="SSOLogout"
      variant="primary"
      style={{width: (isSmallScreen) ? '100%' : 'auto', marginLeft: !isSmallScreen ? 16 : 0 }}
      onClick={() =>  {
        externalLinkModal.openinsametab ? window.open(externalLinkModal.link, '_self') : window.open(externalLinkModal.link);
        dispatch(updateExternalLinkModal(false, '', false));
      }}
    >
      Continue
    </Button>
  );

  return (
    <Modal id={'SSOLogoutModal'} show={externalLinkModal.visible} onHide={() => dispatch(updateExternalLinkModal(false, '', false))} size={'lg'}>
      <Modal.Header closeButton className='border-0'>
        <Modal.Title style={universalStyles.modalHeader}>You Are Leaving BECU&apos;s Website </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please note: We provide links to other websites for your convenience. Please note that linked sites may have a privacy and security policy different from our own, and we cannot attest to the accuracy of information. If you wish to leave BECU&apos;s Website, select Continue. If not, select No Thanks.</p>
        {isSmallScreen ? (
          <Flexbox flexDirection='column'>
            {ContinueButton()}
            {CancelButton()}
          </Flexbox>
        ) : (
          <Flexbox justifyContent='flex-end' alignItems='center'>
            {CancelButton()}
            {ContinueButton()}
          </Flexbox>
        )}
        
        <hr style={{height: 2}} />
        <Flexbox>
          <p style={{marginRight: 8}}>*</p>
          <p style={{ marginBottom: 0 }}>Products and services advertised on these sites are offered by independent businesses that are solely responsible for the delivery and quality of those products and services. BECU does not guarantee nor expressly endorse any particular product or service.</p>
        </Flexbox>
      </Modal.Body>
    </Modal>
  );
};

export default ExternalLinkModal;
