import {colors} from 'styles/config';

export const styles = {
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  navbar: {
    padding: '15px 10px',
  },
  faviconImg: {
    marginRight: 12,
  },
  faPlusIcon: {
    color: colors.harbor,
    fontWeight: 400,
  },
  bannerTitle: {
    marginBottom: '0.5rem',
    padding: '0 10px',
  },
  label: {
    color: colors.obsidian,
    fontWeight: 600,
    fontSize: 18,
  },
};
