import TagManager from 'react-gtm-module';

const mapSubscriberCode = (code) => {
  switch (code) {
    case '3':
      return 'mobile';
    case '2':
      return 'olb';
    case '0':
    case '1':
    default:
      return 'login-page';
  }
};

export const tagLogin = (personNumber, subscriberCode) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'login',
      personNumber: personNumber,
      referrer: mapSubscriberCode(subscriberCode),
      application: 'dFHC',
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const tagForgotUser = () => {
  const tagManagerArgs = {dataLayer: {event: 'forgotUser'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagRetrieveUser = () => {
  const tagManagerArgs = {dataLayer: {event: 'retrieveUser'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagReturnLoginUser = () => {
  const tagManagerArgs = {dataLayer: {event: 'returnLoginUser'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagForgotPassword = () => {
  const tagManagerArgs = {dataLayer: {event: 'forgotPassword'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagResetPassword = () => {
  const tagManagerArgs = {dataLayer: {event: 'resetPassword'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagReturnLoginPassword = () => {
  const tagManagerArgs = {dataLayer: {event: 'returnLoginPW'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagHelpAndSupport = () => {
  const tagManagerArgs = {dataLayer: {event: 'helpSupport'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagBecuOrgLink = () => {
  const tagManagerArgs = {dataLayer: {event: 'becuOrgLink'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagBecuPhone = () => {
  const tagManagerArgs = {dataLayer: {event: 'becuPhone'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagReturnLoginSupport = () => {
  const tagManagerArgs = {dataLayer: {event: 'returnLoginSupport'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagLogout = (user) => {
  const tagManagerArgs = {dataLayer: {event: 'logout', personNumber: user.personNumber, application: 'dFHC'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagFeedbackLink = (user) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'feedback',
      personNumber: user.personNumber,
      application: 'dFHC',
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagGetStarted = (user) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'getStarted',
      personNumber: user.personNumber,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagOnboardingComplete = (user, goalsSelected, unselectedGoals, primaryQuestions) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'onboardingComplete',
      personNumber: user.personNumber,
      goalsSelected,
      unselectedGoals,
      primaryQuestions,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagRecommendedGoals = (user, acceptRecommendation) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'recommendedGoals',
      personNumber: user.personNumber,
      acceptRecommendation,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagGoalSelected = (user, goalId, path, answeringAdditionalQuestions) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'goalSelected',
      personNumber: user.personNumber,
      goal: goalId,
      path,
      answeringAdditionalQuestions,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagRecommendationSelected = (user, goalId, recommendationId, path) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'recommendationSelected',
      personNumber: user.personNumber,
      goal: goalId,
      recommendation: recommendationId,
      path,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagAdditionalQuestions = (user, goalId, additionalQuestions) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'additionalQuestions',
      personNumber: user.personNumber,
      goal: goalId,
      additionalQuestions,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagStartAddNewGoals = (user) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'startAddNewGoals',
      personNumber: user.personNumber,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagFinishAddNewGoals = (user) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'finishAddNewGoals',
      personNumber: user.personNumber,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagStartRemoveGoal = (user, goalId) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'startRemoveGoal',
      personNumber: user.personNumber,
      goalSelected: goalId,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagFinishRemoveGoal = (user, goalId) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'finishRemoveGoal',
      personNumber: user.personNumber,
      goalRemoved: goalId,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagStartEditProfile = (user) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'startEditProfile',
      personNumber: user.personNumber,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagFinishEditProfile = (user, topicsEdited) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'finishEditProfile',
      personNumber: user.personNumber,
      topicsEdited,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagStartEditGoal = (user, goalId) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'startEditGoal',
      personNumber: user.personNumber,
      goal: goalId,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagFinishEditGoal = (user, goalId, topicsEdited) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'finishEditGoal',
      personNumber: user.personNumber,
      goal: goalId,
      topicsEdited,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};
