import React, {useEffect, useState} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import EnvelopeFooter from '../components/app/Footer';
import MaxSessionModal from '../components/modals/MaxSessionModal';
import SurveyMonkeyModal from '../components/modals/SurveyMonkeyModal';
import ExternalLinkModal from 'components/modals/ExternalLinkModal';
import TermsModal from 'components/modals/TermsModal';
import MetaDataAnnouncer from '../components/app/MetaDataAnnouncer';
import ConfirmationModal from 'components/modals/ConfirmationModal';

import {
  FAQ_PAGE,
  ENABLE_MAX_SESSION_MODAL,
  GOAL_PAGE,
  HOME_PAGE,
  LOGIN_PAGE,
  MAX_SESSION_MODAL_TIME,
  PROFILE_PAGE,
  EDIT_PROFILE_PAGE,
  SSO_PAGE,
  SSO_SESSION_LOGOUT_PAGE,
  TOKEN_TIME,
  EDIT_GOAL_PAGE,
  MY_GOALS_PAGE,
  TUTORIAL_PAGE,
  UNSAVED_MODAL_DATA
} from '../config/constants';
import FAQ from './FAQ';
import {setMaxSessionModal, updateSaveChangeModal} from 'actions';
import {authSelector, uiMaxSessionModalVisibleSelector, sessionTokenSelector, saveChangeModalSelector} from 'selectors';

import EditProfile from './EditProfile';
import FinancialHealthHome from './Home';
import FinancialHealthProfile from './Profile';
import Login from './Login';
import NotFound from './NotFound';
import Sso from './SSO';
import SSOLogout from './SSOLogout';
import Goal from './Goal';
import Recommendation from './Recommendation';
import EditGoal from './EditGoal';
import AddGoals from './AddGoals';
import Tutorial from './Tutorial';

const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginSuccess = useSelector(authSelector);
  const maxSessionModalVisible = useSelector(uiMaxSessionModalVisibleSelector);
  const unsavedChanges = useSelector(saveChangeModalSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (unsavedChanges && action === 'POP') {
        setUnsavedChangesModal(true);
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [unsavedChanges]);

  const confirmBack = () => {
    dispatch(updateSaveChangeModal(false));
    setUnsavedChangesModal(false);
    history.goBack();
  };

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      if (sessionStorage.getItem('sessionStart')) {
        sessionStorage.setItem('timerRefresh', moment());
      }
    });
  }, []);

  useEffect(() => {
    if (loginSuccess && sessionToken && ENABLE_MAX_SESSION_MODAL === 'true') {
      let modalTimer;
      let showModal = true;
      let timerTimeout = TOKEN_TIME * 1000 * 60;
      let modalTimeout = MAX_SESSION_MODAL_TIME * 1000 * 60;

      if (sessionStorage.getItem('timerRefresh')) {
        const timerRefresh = moment(sessionStorage.getItem('timerRefresh'));
        const sessionStart = moment(sessionStorage.getItem('sessionStart'));
        const minDiff = (timerRefresh.minute() - sessionStart.minute()) * 1000 * 60;
        const secDiff = (timerRefresh.second() - sessionStart.second()) * 1000;

        timerTimeout -= minDiff + secDiff;
        if (minDiff + secDiff > modalTimeout) {
          showModal = false;
        } else {
          modalTimeout -= minDiff + secDiff;
        }

        sessionStorage.removeItem('timerRefresh');
      } else {
        sessionStorage.setItem('sessionStart', moment());
      }

      const sessionTimer = setTimeout(() => {
        history.replace(SSO_SESSION_LOGOUT_PAGE);
      }, timerTimeout);

      if (showModal) {
        modalTimer = setTimeout(() => {
          dispatch(setMaxSessionModal(true));
        }, modalTimeout);
      }

      return () => {
        clearTimeout(sessionTimer);
        if (showModal) {
          clearTimeout(modalTimer);
        }
      };
    }
  }, [loginSuccess]);

  return (
    <>
      <MetaDataAnnouncer />
      <Switch>
        <Route path={FAQ_PAGE} component={FAQ} />
        {(process.env.REACT_APP_ENV !== 'production' && process.env.REACT_APP_ENV !== 'stage') && <Route path={LOGIN_PAGE} component={Login} />}
        <Route path={SSO_PAGE} component={Sso} />
        <Route path={SSO_SESSION_LOGOUT_PAGE} component={SSOLogout} />
        <Route exact path={HOME_PAGE} component={FinancialHealthHome} />
        <Route path={PROFILE_PAGE} component={FinancialHealthProfile} />
        <Route path={EDIT_PROFILE_PAGE} component={EditProfile} />
        <Route exact path={`${GOAL_PAGE}/:goalId`} component={Goal} />
        <Route exact path={`${GOAL_PAGE}/:goalId/:recommendationId`} component={Recommendation} />
        <Route path={`${EDIT_GOAL_PAGE}/:goalId`} component={EditGoal} />
        <Route path={MY_GOALS_PAGE} component={AddGoals} />
        <Route path={`${TUTORIAL_PAGE}/:tutorialName`} component={Tutorial} />
        <Route component={NotFound} />
      </Switch>
      <EnvelopeFooter />
      {/* (errorModal.visible) && <ErrorModal component using errorModal.message & errorModal.code> */}
      {maxSessionModalVisible && <MaxSessionModal />}
      <SurveyMonkeyModal />
      <ExternalLinkModal />
      <TermsModal />
      <ConfirmationModal
        modalData={UNSAVED_MODAL_DATA}
        modalFunction={confirmBack}
        showModal={unsavedChangesModal}
        setModalVisibleStatus={setUnsavedChangesModal}
      />
    </>
  );
};

export default App;
