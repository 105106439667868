export const authSelector = (state) => state.auth.loggedIn;
export const authErrorSelector = (state) => state.auth.authError;
export const userSelector = (state) => state.user.user;
export const accountsSelector = (state) => state.accounts.currentAccounts;
export const editingAccountSelector = (state) => state.accounts.editingAccount;
export const uiAccountsModalVisibleSelector = (state) => state.ui.accountsModalVisible;
export const saveChangeModalSelector = (state) => state.ui.saveChangeModal;
export const uiMaxSessionModalVisibleSelector = (state) => state.ui.maxSessionModalVisible;
export const externalLinkModalSelector = (state) => state.ui.externalLinkModal;
export const sessionPathSelector = (state) => state.ui.pathname;
export const breadcrumbsSelector = (state) => state.ui.breadcrumbs;
export const termsModalSelector = (state) => state.ui.termsModalVisible;
export const sessionTokenSelector = (state) => state.session.token;
export const uiSurveyMonkeyFormModalSelector = (state) => state.ui.surveyMonkeyFormModal;
export const intializedSelector = (state) => state.user.initialized;
export const uiApiErrorSelector = (state) => state.ui.apiError;
export const financialHealthGoalSelector = (state) => state.financialHealth.financialGoal;
export const financialHealthTopicSelector = (state) => state.financialHealth.financialTopic;
export const financialHealthGoalAddedSelector = (state) => state.financialHealth.financialGoalAdded;
export const financialHealthTopicAddedSelector = (state) => state.financialHealth.financialTopicAdded;
export const financialHealthGoalDeletedSelector = (state) => state.financialHealth.financialGoalDeleted;
export const recommendationsSelector = (state) => state.financialHealth.recommendations;
export const recommendationsSuccessSelector = (state) => state.financialHealth.recommendationsSuccess;
export const completedFavoriteRecommendationsSelector = (state) => state.financialHealth.completedFavoriteRecommendations;
export const recommendationCompletedSuccessSelector = (state) => state.financialHealth.recommendationCompletionSuccess;
export const recommendationFavoritesSuccessSelector = (state) => state.financialHealth.recommendationFavoritesSuccess;
export const ctaGoalsSelector = (state) => state.financialHealth.ctaGoals;
export const ctaGoalsSuccessSelector = (state) => state.financialHealth.ctaGoalsSuccess;
export const editGoalSelector = (state) => state.financialHealth.editingGoal;
export const tutorialSelector = (state) => state.financialHealth.tutorial;
export const mobileAppUserSelector = (state) => state.user.mobileAppUser;
