import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Flexbox from 'flexbox-react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import PageMetaData from 'components/app/PageMetaData';
import Header from 'components/app/Header';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import Breadcrumbs from 'components/app/Breadcrumbs';

import { universalStyles } from 'styles/universalStyles';
import { EDIT_PROFILE_PAGE, EDIT_PROFILE_PAGE_TITLE, HOME_PAGE, RETURN_TO_OLB_LINK, UNSAVED_MODAL_DATA } from 'config/constants';
import { createFinancialTopic, deleteFinancialTopic, getFinancialTopic, getRecommendations, getUser, loginWithToken, resetRecommendationsSuccess, setSessionPath, updateBreadcrumbs, updateEditingGoal, updateSaveChangeModal } from 'actions';
import { authSelector, financialHealthTopicAddedSelector, financialHealthTopicSelector, intializedSelector, recommendationsSuccessSelector, saveChangeModalSelector, sessionTokenSelector, userSelector } from 'selectors';
import FinancialHealthQuiz from 'components/profile/topics';
import { tagFinishEditProfile } from 'services/gtm';

// TODO: Update financial topic items to include parent topic ID / name, group list by ID's, one map to render all additional questions groups, map for each question w/ special handling for manage spending
const topics = ['Expenses', 'Income', 'Bills'];
const topicsPayDownCreditCardDebt = ['Cards', 'Interest Rate', 'Payment Status'];
const topicsImproveCreditStanding = ['Credit Score', 'Household Debts', 'Debt Status'];
const topicsManageSpending = ['Using a Budget', 'Budget Success'];
const topicsPlanForRetirement = ['Emergency Savings', 'Employer-Sponsored Retirement Plan', 'Employer Match', 'Retirement Timeline'];

const homeBreadcrumb = {
  title: 'Financial Goals & Resource Guide',
  page: HOME_PAGE,
  active: false,
};

const financialInfoBreadcrumb = {
  title: 'Your Financial Profile',
  page: EDIT_PROFILE_PAGE,
  active: true,
};

const EditProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector(authSelector);
  const sessionToken = useSelector((sessionTokenSelector));
  const initialized = useSelector(intializedSelector);
  const user = useSelector(userSelector);
  const financialHealthTopics = useSelector(financialHealthTopicSelector);
  const saveChangeModal = useSelector(saveChangeModalSelector);
  const financialTopicAdded = useSelector(financialHealthTopicAddedSelector);
  const recommendationsSuccess = useSelector(recommendationsSuccessSelector);
  const [saving, setSaving] = useState(false);
  const [topicAnswers, setTopicAnswers] = useState([]);
  const [saveChangesModals, setSaveChangesModal] = useState(false);
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});

  useEffect(() => {
    dispatch(updateBreadcrumbs([homeBreadcrumb, financialInfoBreadcrumb]));
  }, []);

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      window.open(RETURN_TO_OLB_LINK, '_self');
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    }
  }, []);

  useEffect(() => {
    resetSelections(user);
  }, [user]);

  useEffect(() => {
    if (financialTopicAdded) {
      dispatch(getRecommendations(true));
    }
  }, [financialTopicAdded]);

  useEffect(() => {
    if (recommendationsSuccess) {
      dispatch(getUser());
      dispatch(resetRecommendationsSuccess());
      navHome();
    }
  }, [recommendationsSuccess]);

  const navHome = () => {
    setSaving(false);
    const path = { pathname: HOME_PAGE };
    dispatch(setSessionPath(path));
    history.push(path);
  };

  const resetSelections = (user) => {
    dispatch(updateSaveChangeModal(false));
    if (user?.financialProfileTopicAnswers?.length) {
      const topicAnswersSetList = [];
      user.financialProfileTopicAnswers.forEach((item) => {
        topicAnswersSetList.push({ financialProfileAnswerId: item.financialAnswers[0].financialProfileAnswerId, topic: item.financialTopic.topic });
      });
      setTopicAnswers(topicAnswersSetList);
    }

    if (!financialHealthTopicSelector?.financialProfileTopicAnswers?.length && user?.financialProfileTopicAnswers?.length) {
      dispatch(getFinancialTopic());
    }
  };

  // TODO: Simplify check or change back-end behaviour for Manage Spending / recommendations
  const submit = () => {
    setSaving(true);
    let topicsToAdd = topicAnswers.map((el) => el.financialProfileAnswerId);

    const userAnswers = user?.financialProfileTopicAnswers?.filter((x) =>  x.financialTopic.financialProfileTopicId > 3).map((x) => x.financialAnswers[0].financialProfileAnswerId);
    const userAnswerCheck1 = userAnswers.includes(20);
    const userAnswerCheck2 = userAnswers.includes(21);
    const topicsCheck1 = topicsToAdd.includes(20);
    const topicsCheck2 = topicsToAdd.includes(21);

    if (topicAnswers.some(x => x.financialProfileAnswerId === 19)) {
      if (userAnswerCheck1 || userAnswerCheck2) {
        dispatch(deleteFinancialTopic(userAnswerCheck1 ? 20 : 21));
      }

      if (topicsCheck1 || topicsCheck2) {
        topicsToAdd.splice(topicsToAdd.indexOf((topicsCheck1) ? 20 : 21), 1);
      }
    }

    const userAnswerCheck1Improve = userAnswers.includes(28);
    const userAnswerCheck2Improve = userAnswers.includes(29);
    const userAnswerCheck3Improve = userAnswers.includes(30);
    const topicsCheck1Improve = topicsToAdd.includes(28);
    const topicsCheck2Improve = topicsToAdd.includes(29);
    const topicsCheck3Improve = topicsToAdd.includes(30);

    if (topicAnswers.some(x => x.financialProfileAnswerId === 27)) {
      if (userAnswerCheck1Improve) {
        dispatch(deleteFinancialTopic(28));
      }
      if (userAnswerCheck2Improve) {
        dispatch(deleteFinancialTopic(29));
      }
      if (userAnswerCheck3Improve) {
        dispatch(deleteFinancialTopic(30));
      }

      if (topicsCheck1Improve || topicsCheck2Improve || topicsCheck3Improve) {
        topicsToAdd.splice(topicsToAdd.indexOf((topicsCheck1Improve) ? 28 : (topicsCheck2Improve ? 29 : 30)), 1);
      }
    }

    const userAnswerCheck1Retirement = userAnswers.includes(37);
    const userAnswerCheck2Retirement = userAnswers.includes(38);
    const userAnswerCheck3Retirement = userAnswers.includes(39);
    const topicsCheck1Retirement = topicsToAdd.includes(37);
    const topicsCheck2Retirement = topicsToAdd.includes(38);
    const topicsCheck3Retirement = topicsToAdd.includes(39);

    if (topicAnswers.some(x => x.financialProfileAnswerId === 36)) {
      if (userAnswerCheck1Retirement) {
        dispatch(deleteFinancialTopic(37));
      }
      if (userAnswerCheck2Retirement) {
        dispatch(deleteFinancialTopic(38));
      }
      if (userAnswerCheck3Retirement) {
        dispatch(deleteFinancialTopic(39));
      }

      if (topicsCheck1Retirement || topicsCheck2Retirement || topicsCheck3Retirement) {
        topicsToAdd.splice(topicsToAdd.indexOf((topicsCheck1Retirement) ? 37 : (topicsCheck2Retirement ? 38 : 39)), 1);
      }
    }

    const previousAnswerIds = user?.financialProfileTopicAnswers.map((x) => x.financialAnswers[0].financialProfileAnswerId);
    tagFinishEditProfile(user, topicsToAdd.filter((x) => !previousAnswerIds.includes(x)));

    dispatch(createFinancialTopic(topicsToAdd));
    dispatch(updateSaveChangeModal(false));
    dispatch(updateEditingGoal(['profile', '']));
  };

  const topicAnswersCheck = (topicAnswersList, disableButtonCheck = false) => {
    const sortedTopicsList = topicAnswersList?.map((topicAnswer) => topicAnswer.financialProfileAnswerId.toString()).sort((a, b) => a - b).join('');
    const userTopicsList = user?.financialProfileTopicAnswers?.map((profile) => profile.financialAnswers[0].financialProfileAnswerId.toString()).sort((a, b) => a - b).join('');
    const specialHandeling = [
      {
        mainTopic: 'Using a Budget',
        subTopic: 'Budget Success',
        checkFunction: manageSpendingHandling,
      },
      {
        mainTopic: 'Household Debts',
        subTopic: 'Debt Status',
        checkFunction: improveCreditHandling,
      },
      {
        mainTopic: 'Employer-Sponsored Retirement Plan',
        subTopic: 'Employer Match',
        checkFunction: planForRetirementHandling,
      },
    ];
    let filteredTopicAnswersList = sortedTopicsList;
    let checks = [];
    let filters = [];

    specialHandeling.forEach((item) => {
      if (topicAnswers.filter(el => el.topic === item.mainTopic).length > 0) {
        if (!item.checkFunction(topicAnswersList)) {
          checks.push(topicAnswersList.filter(el => el.topic === item.subTopic).length === 1);
        } else {
          filters.push(item.subTopic);
        }
      }
    });

    if (filters.length) {
      filteredTopicAnswersList = topicAnswersList?.filter((el) => !filters.includes(el.topic)).map((el) => el.financialProfileAnswerId.toString()).sort((a, b) => a - b).join('');
    }

    if (checks.length) {
      if (disableButtonCheck) {
        return checks.filter(Boolean).length === checks.length && (filteredTopicAnswersList !== userTopicsList);
      } else {
        return checks.filter(Boolean).length === checks.length || (filteredTopicAnswersList !== userTopicsList);
      }
    }

    return (filteredTopicAnswersList !== userTopicsList);
  };

  const addFinancialTopicAnswer = (financialProfileAnswerId, topic) => {
    const itemFound = topicAnswers && topicAnswers.some(el => el.topic === topic);
    let topicAnswersCopy = [...topicAnswers];

    if (itemFound) {
      let index = topicAnswersCopy.findIndex(el => el.topic === topic);
      topicAnswersCopy[index] = { ...topicAnswersCopy[index], financialProfileAnswerId };
    } else {
      topicAnswersCopy.push({financialProfileAnswerId, topic});
    }
    
    setTopicAnswers(topicAnswersCopy);
    dispatch(updateSaveChangeModal(topicAnswersCheck(topicAnswersCopy)));
  };

  const back = () => {
    const homeBreadcrumb = {
      title: 'Financial Goals & Resource Guide',
      page: HOME_PAGE,
      active: true,
    };
    dispatch(updateBreadcrumbs([homeBreadcrumb]));
    navHome();
  };

  const manageSpendingHandling = (topicAnswersList) => {
    const answer = topicAnswersList.filter(el => el.topic === 'Using a Budget').map((el) => el.financialProfileAnswerId);
    return (answer.length && answer[0] === 19);
  };

  const improveCreditHandling = (topicAnswersList) => {
    const answer = topicAnswersList.filter(el => el.topic === 'Household Debts').map((el) => el.financialProfileAnswerId);
    return (answer.length && answer[0] === 27);
  };

  const planForRetirementHandling = (topicAnswersList) => {
    const answer = topicAnswersList.filter(el => el.topic === 'Employer-Sponsored Retirement Plan').map((el) => el.financialProfileAnswerId);
    return (answer.length && answer[0] === 36);
  };

  const BackButton = () => (
    <Button
      id={'EditFinancialProfileBack'}
      variant={'secondary'}
      style={{width: isSmallScreen ? '100%' : 'auto', marginTop: isSmallScreen ? 15 : 0}}
      disabled={saving}
      aria-disabled={saving}
      onClick={() => {
        if (saveChangeModal) {
          setSaveChangesModal(true);
        } else {
          back();
        }
      }}
    >
      Back
    </Button>
  );

  const SaveButton = () => (
    <Button
      id={'EditFinancialProfileSave'}
      variant={'primary'}
      aria-label='Finish and Save'
      style={{width: isSmallScreen ? '100%' : 'auto', marginLeft: isSmallScreen ? 0 : 15}}
      disabled={saving || !topicAnswersCheck(topicAnswers, true)}
      aria-disabled={saving || !topicAnswersCheck(topicAnswers, true)}
      onClick={submit}
    >
      Finish & Save
    </Button>
  );

  return (
    <Flexbox flexDirection={'column'} style={universalStyles.container}>
      <PageMetaData pageTitle={EDIT_PROFILE_PAGE_TITLE} />
      <Header editView={true} toggleEditView={back} />
      <Container role={'main'} aria-label={''} className={'financial-health'} id="main-content" style={{ paddingTop: 30 }}>
        <Breadcrumbs editView={true} toggleEditView={back} />
        <Row style={{ paddingTop: 20, paddingBottom: 60 }}>
          <Col lg={7}>
            <div className='hero'>Your Financial Profile</div>
            <div>
              <h1 style={{ marginTop: 30 }}>Profile Questions</h1>
              {financialHealthTopics?.financialProfileTopicAnswers?.length > 0 && topics?.map((topic) => (
                <div key={topic}>
                  <h2 style={{ marginTop: 30, marginBottom: -10 }}>{topic}</h2>
                  <FinancialHealthQuiz addFinancialTopicAnswer={addFinancialTopicAnswer} financialHealthTopic={financialHealthTopics} topic={topic} answers={topicAnswers} hideDesc={true} formLabelAs={'p'} />
                </div>
              ))}
              {!!user?.financialProfileTopicAnswers?.filter((financialTopic) => financialTopic.financialTopic.topic === 'Using a Budget').length &&
                <>
                  <hr style={{ marginTop: 37, marginBottom: 30 }} />
                  <h1>Manage Spending</h1>
                  {financialHealthTopics?.financialProfileTopicAnswers?.length > 3 && topicsManageSpending?.map((topic) => {
                    return (topic === 'Budget Success' && manageSpendingHandling(topicAnswers)) ? null : (
                      <div key={topic}>
                        <h2 style={{ marginTop: 30, marginBottom: -10 }}>{topic}</h2>
                        <FinancialHealthQuiz addFinancialTopicAnswer={addFinancialTopicAnswer} financialHealthTopic={financialHealthTopics} topic={topic} answers={topicAnswers} hideDesc={true} formLabelAs={'p'} />
                      </div>
                    );
                  })}
                </>
              }
              {!!user?.financialProfileTopicAnswers?.filter((financialTopic) => financialTopic.financialTopic.topic === 'Interest Rate').length &&
                <>
                  <hr style={{ marginTop: 37, marginBottom: 30 }} />
                  <h1>Pay Down Credit Card Debt</h1>
                  {financialHealthTopics?.financialProfileTopicAnswers?.length > 3 && topicsPayDownCreditCardDebt?.map((topic) => (
                    <div key={topic}>
                      <h2 style={{ marginTop: 30, marginBottom: -10 }}>{topic}</h2>
                      <FinancialHealthQuiz addFinancialTopicAnswer={addFinancialTopicAnswer} financialHealthTopic={financialHealthTopics} topic={topic} answers={topicAnswers} hideDesc={true} formLabelAs={'p'} />
                    </div>
                  ))}
                </>
              }
              {!!user?.financialProfileTopicAnswers?.filter((financialTopic) => financialTopic.financialTopic.topic === 'Credit Score').length &&
                <>
                  <hr style={{ marginTop: 37, marginBottom: 30 }} />
                  <h1>Improve Credit Standing</h1>                  
                  {financialHealthTopics?.financialProfileTopicAnswers?.length > 3 && topicsImproveCreditStanding?.map((topic) => {
                    return (topic === 'Debt Status' && improveCreditHandling(topicAnswers)) ? null : (
                      <div key={topic}>
                        <h2 style={{ marginTop: 30, marginBottom: -10 }}>{topic}</h2>
                        <FinancialHealthQuiz addFinancialTopicAnswer={addFinancialTopicAnswer} financialHealthTopic={financialHealthTopics} topic={topic} answers={topicAnswers} hideDesc={true} formLabelAs={'p'} />
                      </div>
                    );
                  })}
                </>
              }
              {!!user?.financialProfileTopicAnswers?.filter((financialTopic) => financialTopic.financialTopic.topic === 'Emergency Savings').length &&
                <>
                  <hr style={{ marginTop: 37, marginBottom: 30 }} />
                  <h1>Plan for Retirement</h1>
                  {financialHealthTopics?.financialProfileTopicAnswers?.length > 3 && topicsPlanForRetirement?.map((topic) => {
                    return (topic === 'Employer Match' && planForRetirementHandling(topicAnswers)) ? null : (
                      <div key={topic}>
                        <h2 style={{ marginTop: 30, marginBottom: -10 }}>{topic}</h2>
                        <FinancialHealthQuiz addFinancialTopicAnswer={addFinancialTopicAnswer} financialHealthTopic={financialHealthTopics} topic={topic} answers={topicAnswers} hideDesc={true} formLabelAs={'p'} />
                      </div>
                    );
                  })}
                </>
              }
              <div style={{ marginTop: 30 }}>
                {isSmallScreen ? (
                  <Flexbox flexDirection='column'>
                    {SaveButton()}
                    {BackButton()}
                  </Flexbox>
                ) : (
                  <Flexbox justifyContent='flex-start' alignItems='center'>
                    {BackButton()}
                    {SaveButton()}
                  </Flexbox>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ConfirmationModal
        modalData={UNSAVED_MODAL_DATA}
        modalFunction={back}
        showModal={saveChangesModals}
        setModalVisibleStatus={setSaveChangesModal}
      />
    </Flexbox>
  );
};

export default EditProfile;
