import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Flexbox from 'flexbox-react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import PageMetaData from 'components/app/PageMetaData';
import Header from 'components/app/Header';
import Subheader from 'components/app/Subheader';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import { logout } from 'actions';
import { tagLogout } from 'services/gtm';
import { userSelector } from 'selectors';
import { RETURN_TO_OLB_LINK } from 'config/constants';
import loggedOutImage from '../../assets/images/logged-out.svg';

const SSOLogout = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});

  useEffect(() => {
    tagLogout(user);
    dispatch(logout());
  }, []);

  return (
    <Flexbox flexDirection={'column'} style={universalStyles.container}>
      <PageMetaData pageTitle={'Logged Out | BECU Financial Health'} />
      <Header noHeaderLink />
      <Subheader title={'Your session has ended.'} />
      <Container role={'main'} id="main-content">
        <Row>
          <Col md={{span: 8, offset: 2}} style={{...universalStyles.columnPadding, textAlign: 'center'}}>
            <img
              src={loggedOutImage}
              aria-hidden='true'
              style={styles.loggedOutImage}
            />
            <h4 style={{margin: '2rem 0 1rem'}}>
              We securely ended your Financial Goals & Resource Guide session.
            </h4>
            <p style={{fontSize: 14}}>
              You can now close this window.
            </p>
            <Button
              id='ReturnToOLB'
              onClick={() => window.open(RETURN_TO_OLB_LINK, '_self')}
              style={{width: isSmallScreen ? '100%' : 'auto', marginTop: '1rem'}}
              >
              Return to Online Banking
            </Button>
          </Col>
        </Row>
      </Container>
    </Flexbox>
  );
};

export default SSOLogout;
