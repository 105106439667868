import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Flexbox from 'flexbox-react';
import {Col, Container, Row} from 'react-bootstrap';
import Header from 'components/app/Header';
import Subheader from 'components/app/Subheader';
import FeedbackTab from 'components/app/FeedbackTab';
import PageMetaData from 'components/app/PageMetaData';
import CategorySelector from 'components/faq/CategorySelector';
import FAQAccordion from 'components/faq/FAQAccordion';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {FAQ_DATA} from '../../config/faq';
import {FAQ_PAGE, FAQ_PAGE_TITLE, HOME_PAGE, LOGIN_PAGE} from '../../config/constants';
import {authSelector, sessionTokenSelector, intializedSelector, userSelector, mobileAppUserSelector} from 'selectors';
import {setSessionPath, loginWithToken, updateBreadcrumbs} from 'actions';
import Breadcrumbs from 'components/app/Breadcrumbs';


const FAQ = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const loggedIn = useSelector(authSelector);
  const initialized = useSelector(intializedSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const mobileAppUser = useSelector(mobileAppUserSelector);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [active, setActive] = useState('');

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      dispatch(setSessionPath({pathname: LOGIN_PAGE}));
      history.replace(LOGIN_PAGE);
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    }
  }, [loggedIn, initialized]);

  useEffect(() => {
    const faqBreadcrumbs = [
      {
        title: 'Financial Goals & Resource Guide',
        page: HOME_PAGE,
        active: false,
      },
      {
        title: 'FAQs and Tips',
        page: FAQ_PAGE,
        active: true,
        ariaLabel: 'Frequently Asked Questions and Tips'
      },
    ];
    dispatch(updateBreadcrumbs(faqBreadcrumbs));
  }, []);

  useEffect(() => {
    setActive(categoryIndex.toString() + '0');
  }, [categoryIndex]);

  const updateCategory = (index) => {
    setCategoryIndex(index);
  };

  return (
    <Flexbox flexDirection={'column'} style={universalStyles.container}>
      <PageMetaData pageTitle={FAQ_PAGE_TITLE} />
      <Header />
      <div style={styles.subheaderWrapper}>
        <Container style={{ paddingTop: 15 }}>
          <Breadcrumbs />
        </Container>
      </div>
      <Subheader title={'FAQs and Tips'} titleAriaLabel={'Frequently asked questions and tips'} textAlign={'left'} />
      <Container role={'main'} aria-label={'select a topic from the list below'} id="main-content">
        {mobileAppUser && <Breadcrumbs />}
        <Row>
          <CategorySelector active={active} updateCategory={updateCategory} />
          <Col md={8} style={universalStyles.columnPadding}>
            <div id={'FAQ-Title'} style={styles.categoryTitle}>
              <h2>{FAQ_DATA[categoryIndex].title}</h2>
            </div>
            {FAQ_DATA[categoryIndex].topics.map((topic, index) => (
               <div key={categoryIndex.toString() + index.toString()}>
                  <>
                    {/* <h3 id={`FAQ-Subtitle-${index}`} style={styles.subcategoryTitle}>
                      {topic.subtitle}
                    </h3> */}
                    <FAQAccordion index={index} topic={topic} categoryIndex={categoryIndex} user={user} />
                  </>
              </div>
            ))}
          </Col>
        </Row>
        <FeedbackTab />
      </Container>
    </Flexbox>
  );
};

export default FAQ;