import snowballHeaderImage from '../assets/images/tutorial/snowball/snowball.svg';
import acceleratePaymentsHeaderImage from '../assets/images/tutorial/accelerate-payments/accelerate-payments.svg';
import createBudgetHeaderImage from '../assets/images/tutorial/accelerate-payments/accelerate-payments.svg';

export const SNOWBALL = {
  header: {
    title: 'Debt Snowball',
    image: snowballHeaderImage,
  },
  description: {
    title: 'What is Debt Snowball?',
    body: 'The debt snowball is a method of debt repayment that focuses on the smallest balance first. You make minimum payments on all other debts and put any extra on the smallest. Once that debt is gone, you roll that payment into the next one repeating the process until all debt is paid off. This helps you get out of debt faster and save money on interest.',
  },
  content: {
    title: 'Using Money Manager to Visualize Snowball',
    body: {
      video: 'https://www.youtube.com/embed/CSVFSYtIj9Q',
      label: 'The snowball method can be broken down into the following steps:',
      steps: [
        'Create a list of all of your debts, excluding your mortgage. Sort the debts in order from smallest to largest balance.',
        'Each month, pay the minimum amount on each balance, except the smallest one — put as much cash as you can afford toward that one.',
        'After you’ve paid off the smallest balance, roll the extra money you were using for that balance into the monthly payment for the next-smallest balance.',
        'Continue making the minimum payments on all other debts. Repeat this process until you’re debt-free.'
      ],
    },
  },
  walkthrough: {
    title: 'How to get started with Money Manager',
    steps: [
      {
        title: 'Step 1:',
        body: 'In Money Manager, add your accounts by clicking on the ‘Add an Account’  button.',
        image: require('../assets/images/tutorial/shared/add-account.png'),
        imageAlt: 'Money Manager, a box highlights Add an Account',
      },
      {
        title: 'Step 2:',
        body: 'Search for institutions you use and provide card credentials.',
        image: require('../assets/images/tutorial/shared/add-institution.png'),
        imageAlt: 'Money Manager, a box highlights select your Institution input box',
      },
      {
        title: 'Step 3:',
        body: 'Select the Debts tab, then click on ‘What is Snowballing?’.',
        image: require('../assets/images/tutorial/snowball/debts.png'),
        imageAlt: 'Money Manager, a box highlights the Debts tab',
      },
      {
        title: 'Example',
        body: 'See how the snowball method helps you pay off debts quickly.',
        image: require('../assets/images/tutorial/snowball/snowball-example.png'),
        imageAlt: 'Money Manager example that shows results of Snowball',
      },
    ],
  },
  buttons: [
    {
      type: 'cancel',
      title: 'Back',
    },
    {
      type: 'confirm',
      title: 'Take Me to Money Manager',
    },
  ],
  goalId: 4,
};

export const ACCELERATE_PAYMENTS = {
  header: {
    title: 'Accelerate Payments',
    image: acceleratePaymentsHeaderImage,
  },
  description: {
    title: 'What are Accelerated Payments?',
    body: 'Paying more than the minimum amount due on your debt each month shortens the time it takes to pay it off – and saves you money! Why? Because as your balance comes down, so does the amount of interest you’re charged.',
  },
  content: {
    title: 'Using Money Manager to Accelerate Payments',
    body: {
      video: 'https://www.youtube.com/embed/57ei1en6K3c',
      label: 'Follow these steps to visualize the benefits of accelerating your payments',
      steps: [
        'Visit Money Manager in your online banking account',
        'Go to the ‘Debts’ tab in the top menu',
        'Select one of your credit cards or loans',
        'Go to ‘Details’',
        'Add more money to your ‘Monthly Payment’',
        'Return back to ‘Debts’ tab to see an updated Final Payment date'
      ],
    },
  },
  walkthrough: {
    title: 'How to Get Started with Money Manager',
    steps: [
      {
        title: 'Step 1:',
        body: 'In Money Manager, add your accounts by clicking on the ‘Add an Account’ button.',
        image: require('../assets/images/tutorial/shared/add-account.png'),
        imageAlt: 'Money Manager, a box highlights Add an Account',
      },
      {
        title: 'Step 2:',
        body: 'Search for institutions you use and provide card credential.',
        image: require('../assets/images/tutorial/shared/add-institution.png'),
        imageAlt: 'Money Manager, a box highlights select your Institution input box',
      },
      {
        title: 'Step 3:',
        body: 'Select the ‘Debts’ tab, then click on a card or loan you want to pay down.',
        image: require('../assets/images/tutorial/accelerate-payments/debts.png'),
        imageAlt: 'Money Manager, a box highlights the Debts tab. Another box beneath it highlights the Mastercard account',
      },
      {
        title: 'Step 4:',
        body: 'Select the ‘Details’ tab, then increase your monthly payments.',
        image: require('../assets/images/tutorial/accelerate-payments/debt-details.png'),
        imageAlt: 'Within Details tab, a box highlights the monthly payment amount of $50.00',
      },
      {
        title: 'Example',
        body: 'See how the accelerating payments have shortened the time it takes you to pay off the debt.',
        image: require('../assets/images/tutorial/accelerate-payments/accelerated-example.png'),
        imageAlt: 'Within Debts tab, a box highlights the Final Payment date as January 2025',
      },
    ],
  },
  buttons: [
    {
      type: 'cancel',
      title: 'Back',
    },
    {
      type: 'confirm',
      title: 'Take Me to Money Manager',
    },
  ],
  goalId: 4,
};

export const CREATE_BUDGET = {
  header: {
    title: 'Create a Budget',
    image: createBudgetHeaderImage,
  },
  description: {
    title: 'What is a Budget?',
    body: 'Budgeting ensures you\'re not spending more than you\'re making. You can use Money Manager to auto-generate budgets based on your spending history, income, debts, and expenses. The interface communicates this data in colorful budget bubbles that easily and instantly visualize your finances.',
  },
  content: {
    title: 'Setting Up a Budget',
    body: {
      video: 'https://www.youtube.com/embed/0oFKoP9qubs',
      label: 'Follow these steps to set up your budget in Money Manager',
      steps: [
        'Visit Money Manager in your online banking account',
        'Go to the ‘Budgets’ tab',
        'Select \'Auto-Generate Budgets\' (Recommended)',
        'Review your generated budgets and adjust them if necessary',
        'To edit your budget, click on a desired budget',
        'For more information, access the ? icon'
      ],
    },
  },
  walkthrough: {
    title: 'How to Get Started with Money Manager',
    steps: [
      {
        title: 'Step 1:',
        body: 'Money Manager is located in your Online Banking account. To create your budget, click on the ‘Budgets’ tab.',
        image: require('../assets/images/tutorial/create-budget/budgets-tab.png'),
        imageAlt: 'Money Manager, a box highlights Budgets tab',
      },
      {
        title: 'Step 2:',
        body: 'Select ‘Auto-Generate Budgets’',
        image: require('../assets/images/tutorial/create-budget/generate.png'),
        imageAlt: 'Money Manager, a box highlights Auto-Generate Budgets button',
      },
      {
        title: 'Step 3:',
        body: 'Review your budgets. To edit a budget, click on the budget bubble.',
        image: require('../assets/images/tutorial/create-budget/review.png'),
        imageAlt: 'Money Manager, a circle highlights a budget bubble',
      },
      {
        title: 'Step 4:',
        body: 'Click the ‘Edit Budget’ button. Enter your desired budget amount.',
        image: require('../assets/images/tutorial/create-budget/edit.png'),
        imageAlt: 'Money Manager, edit screen',
      },
    ],
  },
  buttons: [
    {
      type: 'cancel',
      title: 'Back',
    },
    {
      type: 'confirm',
      title: 'Take Me to Money Manager',
    },
  ],
  goalId: 8,
};