import React from 'react';
import Flexbox from 'flexbox-react';
import { styles } from './styles';
import { colors } from 'styles/config';

const FinancialGoals = ({addFinancialGoal, financialGoal, selected, index}) => {
  return (
    <Flexbox
      key={index}
      alignItems='center'
      justifyContent='space-between'
      className={'selection-tile'}
      style={{...styles.financialGoalColumn, border: selected ? `2px solid ${colors.harbor}` : `1px solid ${colors.stone}`}}
      onKeyDown={(e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
          e.preventDefault();
          addFinancialGoal(financialGoal.financialGoalId.toString(), financialGoal.goal, financialGoal.imgName);
        }
      }}
      onClick={() => {
        addFinancialGoal(financialGoal.financialGoalId.toString(), financialGoal.goal, financialGoal.imgName);
      }}
      tabIndex={0}
      role="checkbox"
      aria-checked={selected}
      aria-label={`${financialGoal.goal} Goal`}
    >
      <Flexbox alignItems='center'>
        <img src={require(`../../../assets/images/financial/${financialGoal.imgName}`)} style={{maxWidth: 32, marginRight: 8}} aria-hidden='true' /> 
        <div style={styles.financialGoalTextContainer}>
          <span style={styles.financialGoalTextSpan}>{financialGoal.goal}</span>
        </div>
      </Flexbox>
      <input
        id={`${financialGoal.goal}`}
        aria-label={`${financialGoal.goal}`}
        type={'checkbox'}
        style={styles.financialGoalCheckbox}
        value={`${financialGoal.financialGoalId}`}
        checked={selected}
        readOnly
        aria-hidden="true"
        tabIndex={-1}
        role="none"
      />
    </Flexbox>
  );
};

export default FinancialGoals;
