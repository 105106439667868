import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Flexbox from 'flexbox-react';
import moment from 'moment-timezone';
import { Card } from 'react-bootstrap';
import { CheckSquareFill, XCircleFill } from 'react-bootstrap-icons';

import { styles } from './styles';
import { colors } from 'styles/config';
import { GOAL_PAGE, TUTORIAL_PAGE } from 'config/constants';
import { tagRecommendationSelected } from 'services/gtm';
import { breadcrumbsSelector, financialHealthGoalSelector, userSelector } from 'selectors';
import { setSessionPath, updateBreadcrumbs, updateRecommendationCompletion, updateRecommendationFavorites, updateTutorial } from 'actions';
import { formatBreadcrumbs } from 'utils/breadcrumbs';
import { getMatchingRecommendations, getRelatedGoals, getStarted, learnMore } from 'utils/recommendations';

export const Recommendation = ({item, showsComplete, recommendations, updateCompletedFavorite}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(userSelector);
  const breadcrumbs = useSelector(breadcrumbsSelector);
  const financialHealthGoal = useSelector(financialHealthGoalSelector);
  const relatedGoals = getRelatedGoals(item, user, financialHealthGoal, recommendations);

  const getGoalList = () => {
    return (relatedGoals?.length) ? `Goals: ${relatedGoals.join(', ')}` : ((relatedGoals?.goal) ? `Goal:  ${relatedGoals?.goal}` : `No associated goal${!showsComplete && 's'}.`);
  };

  const getCompletedDisplay = () => {
    const now = moment();
    const date = moment(moment.tz(item.completedDate, 'UTC').local().format());
    const time = date.format('h:mm A');

    if (date.isSame(now, 'day')) {
      return `Today, ${time}`;
    }

    if (date.isSame(now.clone().subtract(1, 'days'), 'day')) {
      return `Yesterday, ${time}`;
    }

    return `${date.format('M/D/YY')} ${time}`;
  };

  const toggleRecommendation = () => {
    if (item.getStartedLink !== '' && item.longDescription !== '') {
      tagSelection('get-started');
      getStarted(item, dispatch);
    } else {
      tagSelection('learn-more');
      learnMore(item, navToTutorial, navToRecommendation, dispatch);
    }
  };

  const tagSelection = (path) => {
    tagRecommendationSelected(user, item.goalId, item.packageName, path);
  };

  const navToRecommendation = () => {
    const path = {pathname: GOAL_PAGE + `/${item.goalId}/${item?.recommendationsId}`};
    dispatch(setSessionPath(path));
    dispatch(updateBreadcrumbs(formatBreadcrumbs([...breadcrumbs, {title: item.packageName, page: null, active: true}])));
    history.push(path);    
  };

  const navToTutorial = (tutorial) => {
    const path = {pathname: TUTORIAL_PAGE + `/${tutorial.header.title.split(' ').join('-')}`};
    dispatch(setSessionPath(path));
    dispatch(updateTutorial({tutorial, item}));
    history.push(path);
  };

  return (
    <Card style={{...styles.card, marginTop: 15}}>
      <Card.Body style={styles.cardBody}>
        <div>
          <button
            className='text-link-button auto-height'
            style={{width: 'auto', marginLeft: -5, textAlign: 'left', fontWeight: 700}}
            onClick={toggleRecommendation}
          >
            {item?.packageName}
          </button>
          <p style={{marginBottom: 0, fontSize: 14}}>{getGoalList()}</p>
          {(item?.isCompleted && showsComplete) &&
            <Flexbox flexDirection='row' alignItems='center'>
              <div style={styles.iconWrapper}>
                <CheckSquareFill color='black' sizee={16} />
              </div>
              <p style={{marginBottom: 0, fontSize: 14}}>Completed: {getCompletedDisplay()}</p>
            </Flexbox>
          }
        </div>
        <button
          id={`Remove-${item?.recommendationsId}`}
          aria-label={'Remove recommenation'}
          type={'button'}
          className={'link-button'}
          style={{width: 40, height: 40}}
          onClick={() => {
            let modalAction = null;
            let modalBodyLanguage = 'This will clear all completion information associated with this recommendation. Based on your goals and financial profile answers, there is a possibility you may not see this recommendation again.';
            if (!showsComplete) {
              modalBodyLanguage = (relatedGoals.length) ? 'You will need to go back to your goals if you wish to add it again.' : 'Since there are no associated goals, this recommendation is currently unavailable to add back to your Favorites.';
            }
            const modalData = {
              title: `Remove from ${showsComplete ? 'Completed' : 'Favorites'}?`,
              body: `Are you sure you want to remove this from your ${showsComplete ? 'completed' : 'favorite'} recommendations?\n\n${modalBodyLanguage}`,
              yesText: `Yes, Remove from ${showsComplete ? 'Completed' : 'Favorites'}`,
              noText: 'Cancel',
            };
            if (showsComplete) {
              modalAction = () => dispatch(updateRecommendationCompletion(item.recommendationsId, item.goalId, false));
            } else {
              modalAction = () => dispatch(updateRecommendationFavorites(getMatchingRecommendations(item.packageName) ?? [{recommendationId: parseInt(item.recommendationsId), goalId: parseInt(item.goalId)}], false));
            }

            updateCompletedFavorite(modalData, modalAction);
          }}
        >
          <XCircleFill color={colors.harbor} style={styles.financialGoalCheckbox}/>
        </button>
      </Card.Body>
    </Card>
  );
};