import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb } from 'react-bootstrap';
import ConfirmationModal from 'components/modals/ConfirmationModal';

import { styles } from './styles';
import { breadcrumbsSelector, saveChangeModalSelector } from 'selectors';
import { updateSaveChangeModal } from 'actions';
import { GOAL_PAGE, UNSAVED_MODAL_DATA, RETURN_TO_OLB_LINK } from 'config/constants';

const Breadcrumbs = ({ editView = false, toggleEditView = () => {}, altAction = () => {} }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const breadcrumbs = useSelector(breadcrumbsSelector);
  const saveChangeModal = useSelector(saveChangeModalSelector);
  const [saveChangeModalVisible, setSaveChangeModalVisible] = useState(false);
  const [saveChangeModalFunction, setSaveChangeModalFunction] = useState(() => {});

  const setModalVisibleStatus = (status) => {
    setSaveChangeModalVisible(status);
  };

  const setModalFunction = (callback) => {
    setSaveChangeModalFunction(() => callback);
  };

  const clickHandler = (action) => {
    if (action) {
      if (!saveChangeModal) {
        action();
      } else {
        setModalFunction(action);
        setModalVisibleStatus(true);
      }
    }
  };

  const returnToOLB = () => {
    window.open(RETURN_TO_OLB_LINK, '_self');
  };

  const cancelEditView = () => {
    dispatch(updateSaveChangeModal(false));
    toggleEditView();
  };

  return (
    <Breadcrumb listProps={{style: styles.breadcrumbList}}>
      <Breadcrumb.Item onClick={() => clickHandler(returnToOLB)}>Online Banking</Breadcrumb.Item>
      {breadcrumbs.map((breadcrumb, index) => (
        <Breadcrumb.Item
          key={index}
          active={breadcrumb.active}
          onClick={() => {if (!breadcrumb.active) clickHandler(editView ? cancelEditView : (breadcrumb.page === GOAL_PAGE ? altAction : () => history.push(breadcrumb.page)));}}
          aria-label={breadcrumb.ariaLabel ?? breadcrumb.title}
        >
          {breadcrumb.title}
        </Breadcrumb.Item>
      ))}
      <ConfirmationModal
        modalData={UNSAVED_MODAL_DATA}
        modalFunction={saveChangeModalFunction}
        showModal={saveChangeModalVisible}
        setModalVisibleStatus={setModalVisibleStatus}
      />
    </Breadcrumb>
  );
};

export default Breadcrumbs;