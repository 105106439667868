import { colors } from "styles/config";

export const styles = {
  headerImageBig: {
    width: 236,
    height: 225,
    marginTop: '10%',
  },
  headerImageSmall: {
    width: 177,
    height: 168,
    marginTop: '-15%',
  },
  recommendationHeaderSubtitle: {    
    marginLeft: 0,
    paddingBottom: 12,
  },
  goalImageBig: {
    width: 291,
    height: 'auto',
  },
  goalImageSmall: {
    width: 345,
    height: 'auto',
  },
  listWrapper: {
    marginTop: 16,
  },
  subheaderWrapper: {
    width: '100%',
    backgroundColor: colors.cloud,
  },
  subheaderContainer: {
    paddingBottom: 40,
    minHeight: 205,
    maxHeight: 225,
    display: 'flex',
    justifyContent: 'space-between',
  },
  recommendationIconContainer: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
