import {GET_USER_SUCCESS, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT_SUCCESS} from '../config/actionTypes';
import {HEALTH_SERVICE_API, AUTH_API} from '../services/apiConfig';
import {handleGenericError} from '../services/errorConfig';
import {API_ERROR_MESSAGE, FINANCIAL_PROFILE_API, LOGIN_API, LOGIN_TOKEN_API, USER_API} from '../config/constants';
import {setSessionToken} from './session';
import {tagLogin} from '../services/gtm';
import {setSessionTimeoutKey,setSsoAuthKey} from 'services/sessionStorage';
import store from '../store';
import {setApiError} from './ui';

export const login = (userId, password) => (dispatch) => {
  AUTH_API.post(LOGIN_API, {userId, password})
    .then((res) => {
      HEALTH_SERVICE_API.get(USER_API + FINANCIAL_PROFILE_API, {headers: {Authorization: 'Bearer ' + res.data.token}})
        .then((result) => {
          // set the session token here
          setSessionTimeoutKey('');
          setSsoAuthKey('false');
          dispatch(setSessionToken(res.data.token));
          dispatch(loginSuccess(res.data.token));
          dispatch(getUserSuccess(result.data));
          tagLogin(result.data.personNumber, '1');
        })
        .catch((err) => {
          handleGenericError(err, dispatch, true);
        });
    })
    .catch((err) => {
      handleGenericError(err, dispatch, true);
    });
};

const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS,
    payload: true,
  };
};

const getUserSuccess = (data) => {
  return {
    type: GET_USER_SUCCESS,
    payload: data,
  };
};

export const loginSso = (token, subscriberCode) => (dispatch) => {
  AUTH_API.post(LOGIN_TOKEN_API, {token, subscriberCode})
    .then((res) => {
      HEALTH_SERVICE_API.get(USER_API + FINANCIAL_PROFILE_API, {headers: {Authorization: 'Bearer ' + res.data.token}})
        .then((result) => {
          // set the session token here
          setSessionTimeoutKey('');
          setSsoAuthKey('true');
          dispatch(setSessionToken(res.data.token));
          dispatch(loginSuccess(res.data.token));
          dispatch(getUserSuccess(result.data));
          tagLogin(result.data.user.personNumber, subscriberCode);
        })
        .catch((err) => {
          handleGenericError(err, dispatch, true);
        });
    })
    .catch((err) => {
      handleGenericError(err, dispatch, true);
    });
};

export const loginWithToken = () => (dispatch) => {
  // get the token from the session and if it's missing we need to catch the error
  const token = store.getState().session.token;
  if (!token) handleGenericError('token is missing from state, cannot loginWithToken', dispatch);
  HEALTH_SERVICE_API.get(USER_API + FINANCIAL_PROFILE_API, {headers: {Authorization: 'Bearer ' + token}})
    .then((result) => {
      setSessionTimeoutKey('');
      dispatch(loginSuccess(token));
      dispatch(getUserSuccess(result.data));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
      if (err.response.status !== 401) {
        dispatch(setApiError(true, API_ERROR_MESSAGE));
      }
    });
};

export const logout = (code) => (dispatch) => {
  const success = () => {
    sessionStorage.clear();
    if (code && code === 401) {
      setSessionTimeoutKey('true');
    }
    return {
      type: LOGOUT_SUCCESS,
      payload: false, // why are we sending false/
    };
  };

  dispatch(success());
};

export const resetAuthError = () => (dispatch) => {
  const reset = () => {
    sessionStorage.clear();
    return {
      type: LOGIN_FAILURE,
      payload: null,
    };
  };

  dispatch(reset());
};
