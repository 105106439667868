import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import Flexbox from 'flexbox-react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import PageMetaData from 'components/app/PageMetaData';
import Header from 'components/app/Header';
import Subheader from 'components/app/Subheader';
import AlertBanner from 'components/app/AlertBanner';

import { styles } from './styles';
import {universalStyles} from 'styles/universalStyles';
import {loginSso, logout, setSessionPath} from 'actions';
import {HOME_PAGE} from '../../config/constants';
import {authSelector, authErrorSelector} from '../../selectors';

const ErrorAlert = (
  <AlertBanner
    type={'danger'}
    message={''}
    template={
      <div style={{textAlign: 'left'}}>
        <strong>There was a problem.</strong>
        <div>
          Please try accessing Financial Health later from BECU Online Banking.
        </div>                      
      </div>
    }
    show={() => true}
    dismissible={false}
  />
);

const TermsWarning = (
  <AlertBanner
    type={'warning'}
    message={''}
    template={
      <div style={{textAlign: 'left'}}>
        <strong>NOTE: You must agree to the terms and conditions to use Financial Health.</strong>
        <div>
          Return to your BECU Online Banking to access Financial Health again.
        </div>                      
      </div>
    }
    show={() => true}
    dismissible={false}
  />
);

const CloseWindowButton = (
  <Button
    id="SSOCloseWindow"
    variant="primary"
    style={{...universalStyles.noSetWidth, marginTop: '2rem'}}
    onClick={() => window.close()}
  >
    Close this Window
  </Button>
);

const SSO = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const jwt = new URLSearchParams(search).get('jwt');
  const subscribercode =  new URLSearchParams(search).get('subscribercode');
  const [displayError, setDisplayError] = useState(false);
  const [termsWarn, setTermsWarn] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);
  const authError = useSelector(authErrorSelector);
  const loggedIn = useSelector(authSelector);

  useEffect(() => {
    if (jwt && subscribercode && authError !== 407) {
      handleLogin(jwt, subscribercode);
    } else {
      setDisplayError(true);
      if (authError === 407) {
        setTermsWarn(true);
        dispatch(logout());
      }
    }
  }, []);

  useEffect(() => {
    if (loggedIn && loggingIn) {
      setLoggingIn(false);
      updatePath(HOME_PAGE);
    } else if (authError) {
      setDisplayError(true);
    }
  }, [loggedIn, loggingIn, authError]);

  const handleLogin = (jwt, subscribercode) => {
    setLoggingIn(true);
    dispatch(loginSso(jwt, subscribercode));
  };

  const updatePath = (path_name) => {
    const path = {pathname: path_name};
    dispatch(setSessionPath(path));
    history.replace(path_name);
  };
      
  return (
    <Flexbox flexDirection={'column'} style={{...universalStyles.container, flex: 10}}>
      <PageMetaData pageTitle={termsWarn ? 'Terms and Conditions Declined' : 'Accessing Financial Health'} />
      <Header noHeaderLink />
      {displayError ? (
        <>
          <Subheader title={termsWarn ? 'Terms and Conditions' : 'System Unavailable'} />
          <Container role={'main'} id="main-content">
            <Row>
              <Col md={{span: 8, offset: 2}} style={{...universalStyles.columnPadding, textAlign: 'center'}}>
                <div style={styles.title}>{termsWarn ? 'Accepting Financial Health terms and conditions is required.' : 'BECU Financial Health encountered a problem.'}</div>
                <p style={{marginBottom: '2rem'}}>Please try again.</p>
                {termsWarn ? TermsWarning : ErrorAlert}
                {CloseWindowButton}
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <Container role={'main'} style={styles.container} id="main-content">
          <Row>
            <Col style={{...universalStyles.columnPadding, textAlign: 'center'}}>
              <div className="spinner"></div>
              <div style={styles.title}>Opening BECU Financial Goals & Resource Guide</div>
            </Col>
          </Row>
        </Container>
      )}
    </Flexbox>
  );
};

export default SSO;
