import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import Flexbox from 'flexbox-react';

const RecommendedGoalsModal = ({ modalVisible, setModalVisible, recommendedGoals, modalFunction, cancelFunction }) => {
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});

  const NoButton = () => (
    <Button
      variant="secondary"
      style={{width: (isSmallScreen) ? '100%' : 'auto', marginTop: isSmallScreen ? 16 : 0 }}
      onClick={() => {cancelFunction(true); setModalVisible(false);}}
    >
      No Thanks
    </Button>
  );

  const AddGoalButton = () => (
    <Button
      aria-label='Recommended Goals'
      className={'envelope-confirmation-button'}
      variant="primary"
      style={{width: (isSmallScreen) ? '100%' : 'auto', marginLeft: !isSmallScreen ? 16 : 0 }}
      onClick={() => {modalFunction(); setModalVisible(false);}}
    >
      + Add to Goals
    </Button>
  );

  return (
    <Modal centered id={'RecommendedGoalsModal'} show={modalVisible} onHide={() => {cancelFunction(true); setModalVisible(false);}}> 
      <Modal.Header closeButton className='border-0' onClick={() => {cancelFunction(true); setModalVisible(false);}}>
        <Modal.Title style={universalStyles.modalHeader}>Recommended Goals</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You have selected great goals! Based on your responses, we also recommend this goal to help you.</p>
        {recommendedGoals?.map((financialGoal, index) => (
          <Flexbox
            key={index}
            alignItems='center'
          >
            <React.Fragment>
              <img src={require(`../../../assets/images/financial/${financialGoal.imgName}`)} style={{maxWidth: 32, marginRight: 8}} aria-hidden='true' />
              <div style={styles.financialGoalTextContainer}>
                <span style={styles.financialGoalTextSpan}>{financialGoal.goal}</span>
              </div>
            </React.Fragment>
          </Flexbox>
        ))}
        <br></br>
        {isSmallScreen ? (
          <Flexbox flexDirection='column'>
            {AddGoalButton()}
            {NoButton()}
          </Flexbox>
        ) : (
          <Flexbox justifyContent='flex-end' alignItems='center'>
            {NoButton()}
            {AddGoalButton()}
          </Flexbox>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default RecommendedGoalsModal;

