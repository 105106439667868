import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Flexbox from 'flexbox-react';
import { Button, Container, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import PageMetaData from 'components/app/PageMetaData';
import Header from 'components/app/Header';
import EditAdditionalQuiz from 'components/financial-health/EditAdditionalQuiz';
import Breadcrumbs from 'components/app/Breadcrumbs';
import { universalStyles } from 'styles/universalStyles';
import { EDIT_PROFILE_PAGE_TITLE, HOME_PAGE, GOAL_PAGE, UNSAVED_MODAL_DATA, EDIT_PROFILE_PAGE, RETURN_TO_OLB_LINK } from 'config/constants';
import { getFinancialTopic, loginWithToken, setSessionPath, updateBreadcrumbs, createFinancialTopic, getUser, getRecommendations, resetRecommendationsSuccess, deleteFinancialTopic, updateSaveChangeModal, updateEditingGoal, getFinancialGoal } from 'actions';
import { authSelector, financialHealthTopicSelector, intializedSelector, sessionTokenSelector, userSelector, financialHealthTopicAddedSelector, recommendationsSuccessSelector, financialHealthGoalSelector } from 'selectors';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { formatBreadcrumbs } from 'utils/breadcrumbs';
import { tagFinishEditGoal } from 'services/gtm';

const EditGoal = () => {
  const { goalId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(userSelector);
  const selectedGoal = user?.financialGoal?.filter(goal => goal.financialGoalId === parseInt(goalId))[0];
  const [topicAnswers, setTopicAnswers] = useState([]);
  const financialHealthGoals = useSelector(financialHealthGoalSelector);
  const financialHealthTopics = useSelector(financialHealthTopicSelector);
  const loggedIn = useSelector(authSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const initialized = useSelector(intializedSelector);
  const financialQuiz = financialHealthTopics?.financialProfileTopicAnswers?.filter(x =>  x.financialTopic.financialProfileTopicId > 3);
  const financialAnsw = user?.financialProfileTopicAnswers?.filter(x => x.financialTopic.financialProfileTopicId > 3).map((x) => x.financialAnswers[0].financialProfileAnswerId);
  const saveForEmergencies = 'Save for Emergencies';
  const [saveChangesModals, setSaveChangesModals] = useState(false);
  const financialTopicAdded = useSelector(financialHealthTopicAddedSelector);
  const recommendationsSuccess = useSelector(recommendationsSuccessSelector);
  const [disableBtn, setDisableBtn] = useState(true);
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      window.open(RETURN_TO_OLB_LINK, '_self');
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    } else {
      const id = goalId;
      dispatch(getFinancialTopic(id));
    }
  }, [loggedIn, sessionToken, initialized, history]);

  useEffect(() => {
    const goalBreadcrumbs = [
      {
        title: 'Financial Goals & Resource Guide',
        page: HOME_PAGE,
        active: false,
      },
      {
        title: selectedGoal?.goal,
        page: GOAL_PAGE,
        active: true,
      },
    ];
    dispatch(updateBreadcrumbs(goalBreadcrumbs));
  }, [history]);

  useEffect(() => {
    if (financialTopicAdded) {
      dispatch(getRecommendations(true));    
    }
  }, [financialTopicAdded]);
  
  useEffect(() => {
    if (recommendationsSuccess) {      
      dispatch(getUser());
      dispatch(resetRecommendationsSuccess());
      saveAnswers();      
    }
  }, [recommendationsSuccess]);

  useEffect(() => {
    if (user?.financialProfileTopicAnswers?.length) {
      setTopicAnswers(getTopicAnswersList());
    }
  }, [user, financialHealthTopics]);

  const getTopicAnswersList = () => {
    const topicAnswersList = [];
    const selectedTopicList = [];
    let financialQuizAnswers = financialQuiz?.map((item) => item.financialAnswers);

    if (financialQuizAnswers) {
      financialQuizAnswers.forEach((answers) => {
        answers.forEach((item) => {
          selectedTopicList.push(item.financialProfileAnswerId);
        });
      });
    }

    user.financialProfileTopicAnswers.forEach((item) => {
      if (selectedTopicList.includes(item.financialAnswers[0].financialProfileAnswerId)) {
        topicAnswersList.push({financialProfileAnswerId: item.financialAnswers[0].financialProfileAnswerId, topic: item.financialTopic.topic});
      }
    });

    return topicAnswersList;
  };

  const back = () => {
    const path = {pathname: HOME_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
  };

  const addFinancialQuizAnswer = (financialProfileAnswerId, topic) => {
    const itemFound = topicAnswers && topicAnswers.some(el => el.topic === topic);
    const topicAnswersCopy = [...topicAnswers];

    if (itemFound) {
      let index = topicAnswers.findIndex(el => el.topic == topic);
      topicAnswersCopy[index] = {...topicAnswersCopy[index], financialProfileAnswerId: financialProfileAnswerId};
    } else {
      topicAnswersCopy.push({topic, financialProfileAnswerId});
    }

    setTopicAnswers(topicAnswersCopy);
    updateGoalEdited(!topicAnswersCheck(topicAnswersCopy));
  };

  const updateGoalEdited = (disableBtn) => {
    setDisableBtn(disableBtn);
    dispatch(updateSaveChangeModal(!disableBtn));
  };

  const topicAnswersCheck = (topicAnswersList, disableButtonCheck = false) => {
    const sortedTopicsList = topicAnswersList?.map((topicAnswer) => topicAnswer.financialProfileAnswerId.toString()).sort((a, b) => a - b).join('');
    let userTopicsList = [];

    if (user?.financialProfileTopicAnswers?.length) {
      userTopicsList = getTopicAnswersList().map((x) => x.financialProfileAnswerId).join('');
    }

    if (topicAnswers.filter(el => el.topic === 'Using a Budget').length > 0) {
      if (!manageSpendingHandling(topicAnswersList)) {
        if (disableButtonCheck) {
          return (topicAnswersList.filter(el => el.topic === 'Budget Success').length === 1) && (sortedTopicsList !== userTopicsList); 
        } else {
          return (topicAnswersList.filter(el => el.topic === 'Budget Success').length === 1) || (sortedTopicsList !== userTopicsList);
        }
      } else {
        let filteredTopicAnswersList = topicAnswersList?.filter(el => el.topic !== 'Budget Success')?.map((topicAnswer) => topicAnswer.financialProfileAnswerId.toString()).sort((a, b) => a - b).join('');
        return (userTopicsList !== filteredTopicAnswersList);
      }
    } else if (topicAnswers.filter(el => el.topic === 'Household Debts').length > 0) {
      if (!improveCreditScoreHandling(topicAnswersList)) {
        if (disableButtonCheck) {
          return (topicAnswersList.filter(el => el.topic === 'Debt Status').length === 1) && (sortedTopicsList !== userTopicsList); 
        } else {
          return (topicAnswersList.filter(el => el.topic === 'Debt Status').length === 1) || (sortedTopicsList !== userTopicsList); 
        }
      } else {
        let filteredTopicAnswersList = topicAnswersList?.filter(el => el.topic !== 'Debt Status')?.map((topicAnswer) => topicAnswer.financialProfileAnswerId.toString()).sort((a, b) => a - b).join('');
        return (userTopicsList !== filteredTopicAnswersList);
      }
    } else if (topicAnswers.filter(el => el.topic === 'Employer-Sponsored Retirement Plan').length > 0) {
      if (!planForRetirementHandling(topicAnswersList)) {
        if (disableButtonCheck) {
          return (topicAnswersList.filter(el => el.topic === 'Employer Match').length === 1) && (sortedTopicsList !== userTopicsList); 
        } else {
          return (topicAnswersList.filter(el => el.topic === 'Employer Match').length === 1) || (sortedTopicsList !== userTopicsList);
        }
      } else {
        let filteredTopicAnswersList = topicAnswersList?.filter(el => el.topic !== 'Employer Match')?.map((topicAnswer) => topicAnswer.financialProfileAnswerId.toString()).sort((a, b) => a - b).join('');
        return (userTopicsList !== filteredTopicAnswersList);
      }
    }

    return (sortedTopicsList !== userTopicsList);
  };

  const manageSpendingHandling = (topicAnswersList) => {
    const answer = topicAnswersList.filter(el => el.topic === 'Using a Budget')?.map((el) => el.financialProfileAnswerId);
    return (answer.length && answer[0] === 19);
  };

  const improveCreditScoreHandling = (topicAnswersList) => {
    const answer = topicAnswersList.filter(el => el.topic === 'Household Debts')?.map((el) => el.financialProfileAnswerId);
    return (answer.length && answer[0] === 27);
  };

  const planForRetirementHandling = (topicAnswersList) => {
    const answer = topicAnswersList.filter(el => el.topic === 'Employer-Sponsored Retirement Plan')?.map((el) => el.financialProfileAnswerId);
    return (answer.length && answer[0] === 36);
  };

  const checkAdditionalAnswerHandling = (answerArray, answerId, ids) => {
    let found = false;
    
    if (topicAnswers.some(x => x.financialProfileAnswerId === answerId)) {
      const arrayCheck = ids.map(x => answerArray.includes(x));
      found = !!arrayCheck.filter(x => !!x).length;
    }

    return found;
  };

  const saveAdditionalAnswers = () => {
    if (checkAdditionalAnswerHandling(financialAnsw, 19, [20, 21])) {
      const id = financialAnsw.includes(20) ? 20 : 21;
      dispatch(deleteFinancialTopic(id));
    } else if (checkAdditionalAnswerHandling(financialAnsw, 27, [28, 29, 30])) {
      const id = financialAnsw.includes(28) ? 28 : (financialAnsw.includes(29) ? 29 : 30);
      dispatch(deleteFinancialTopic(id));
    } else if (checkAdditionalAnswerHandling(financialAnsw, 36, [37, 38, 39])) {
      const id = financialAnsw.includes(37) ? 37 : (financialAnsw.includes(38) ? 38 : 39);
      dispatch(deleteFinancialTopic(id));
    }
    
    if (topicAnswers.some(x => x.financialProfileAnswerId === 19) && (topicAnswers.some(x => x.financialProfileAnswerId === 20) || topicAnswers.some(x => x.financialProfileAnswerId === 21))) {
      const answerIds = topicAnswers.filter((x) => x.financialProfileAnswerId !== 20 && x.financialProfileAnswerId !== 21).map(el => el.financialProfileAnswerId);
      createTopics(answerIds, answerIds);
    } else if (topicAnswers.some(x => x.financialProfileAnswerId === 27) && (topicAnswers.some(x => x.financialProfileAnswerId === 28) || topicAnswers.some(x => x.financialProfileAnswerId === 29) || topicAnswers.some(x => x.financialProfileAnswerId === 30))) {
      const answerIds = topicAnswers.filter((x) => x.financialProfileAnswerId !== 28 && x.financialProfileAnswerId !== 29 && x.financialProfileAnswerId !== 30).map(el => el.financialProfileAnswerId);
      createTopics(answerIds, answerIds);
    } else if (topicAnswers.some(x => x.financialProfileAnswerId === 36) && (topicAnswers.some(x => x.financialProfileAnswerId === 37) || topicAnswers.some(x => x.financialProfileAnswerId === 38) || topicAnswers.some(x => x.financialProfileAnswerId === 39))) {
      const answerIds = topicAnswers.filter((x) => x.financialProfileAnswerId !== 37 && x.financialProfileAnswerId !== 38 && x.financialProfileAnswerId !== 39).map(el => el.financialProfileAnswerId);
      createTopics(answerIds, answerIds);
    } else {
      const answerIds = topicAnswers.map(el => el.financialProfileAnswerId);
      createTopics(answerIds, topicAnswers);
    }
  };

  const createTopics = (answerIds, arrayCheck) => {
    const previousAnswerIds = user?.financialProfileTopicAnswers.map((x) => x.financialAnswers[0].financialProfileAnswerId);

    tagFinishEditGoal(user, parseInt(goalId), arrayCheck.filter((x) => !previousAnswerIds.includes(x)));
    dispatch(createFinancialTopic(answerIds));
  };

  const saveAnswers = () => {    
    dispatch(updateEditingGoal(['editGoalFlow',selectedGoal.goal]));
    const path = {pathname: HOME_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
    dispatch(updateSaveChangeModal(false));
  };

  const navToEditProfile = () => {
    if (!financialHealthGoals?.financialGoal?.length) {
      dispatch(getFinancialGoal());
    }
    dispatch(getFinancialTopic());
    const path = {pathname: EDIT_PROFILE_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
    dispatch(updateBreadcrumbs(formatBreadcrumbs([{title: 'Financial Goals & Resource Guide', page: HOME_PAGE}, {title: 'Financial Information', page: EDIT_PROFILE_PAGE}])));
  };

  const BackButton = () => (
    <Button
      id={'AdditionalQuestionsBack'}
      variant={'secondary'}
      onClick={() => {
        if (!disableBtn) {
          setSaveChangesModals(true); 
        } else {
          back();
        }
      }}
      style={{width: isSmallScreen ? '100%' : 'auto', marginTop: isSmallScreen ? 15 : 0}}
    >
      Back
    </Button>
  );

  const SaveChangesButton = () => (
    <Button
      id={'AdditionalQuestionsSave'}
      variant={'primary'}
      onClick={() => saveAdditionalAnswers()}
      disabled={!topicAnswersCheck(topicAnswers, true)}
      aria-disabled={!topicAnswersCheck(topicAnswers, true)}
      style={{width: isSmallScreen ? '100%' : 'auto', marginLeft: isSmallScreen ? 0 : 15}}
    >
      Save Changes
    </Button>
  );

  return (
    <Flexbox flexDirection={'column'} style={universalStyles.container}>
      <PageMetaData pageTitle={EDIT_PROFILE_PAGE_TITLE} />
      <Header editView={true} toggleEditView={back} />
      <Container role={'main'} aria-label={''} className={'financial-health'} id="main-content" style={{paddingTop: 50}}>
        <Breadcrumbs editView={true} toggleEditView={back} />
        <h1 style={{marginTop: 30}}>{selectedGoal?.goal}</h1>
        {selectedGoal?.goal === saveForEmergencies ? (
          <div style={{marginTop: 16}}> 
            <p>To edit information for this goal, you must edit the answers to your Financial Profile questions.</p>
            <button
               id={'EditFinancialProfile'}
               className='text-link-button'
               style={universalStyles.noSetWidth}
               onClick={navToEditProfile}
            >
              Edit Financial Profile
            </button>
          </div>
        ) : (
          <>
            {financialHealthTopics?.financialProfileTopicAnswers?.length > 0 && financialQuiz?.map((quiz, index) => {
              return (
                <div key={index}>
                  <EditAdditionalQuiz
                    index={index}
                    financialAnswers={quiz.financialAnswers}
                    addFinancialQuizAnswer={addFinancialQuizAnswer}
                    financialTopic={quiz.financialTopic}
                    selectedAnswers= {financialAnsw}
                    selectedGoal = {selectedGoal?.goal}
                  />
                </div>
              );
            })}
          </>
        )}
        <Col md={7} style={{marginTop: 30, marginBottom: 30}}>
          {isSmallScreen ? (
            <Flexbox flexDirection='column'>
              {(selectedGoal?.goal !== saveForEmergencies) && SaveChangesButton()}
              {BackButton()}
            </Flexbox>
          ) : (
            <Flexbox justifyContent='flex-start' alignItems='center'>
              {BackButton()}
              {(selectedGoal?.goal !== saveForEmergencies) && SaveChangesButton()}
            </Flexbox>
          )}
        </Col>
      </Container>
      <ConfirmationModal
        modalData={UNSAVED_MODAL_DATA}
        modalFunction={back}
        showModal={saveChangesModals}
        setModalVisibleStatus={setSaveChangesModals}
      />
    </Flexbox>
  );
};

export default EditGoal;