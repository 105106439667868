import { updateExternalLinkModal } from "actions";
import { ALERTS, AUTOMATE_PAYMENTS, FINANCIAL_HEALTH_SUBSCRIBER_CODE, GREENPATH, MONEY_MANAGER, SPECIALIST } from "config/constants";
import {
  EDUCATION,
  BEST_PRACTICES,
  CREDIT_BUILDER,
  EMPLOYER_SPONSORED_RETIREMENT_PLAN
} from "config/recommendations";
import store from "store";
import { getGoalTutorial } from "./tutorials";

export const getRecommendation = (packageName) => {
  switch (packageName) {
    case 'Follow These Best Practices to Improve Credit Standing':
      return BEST_PRACTICES;
    case 'Learn More About Credit Scores':
      return EDUCATION;
    case 'Consider a Credit Builder Product':
      return CREDIT_BUILDER;
    case 'Invest in an Employer Sponsored Retirement Plan':
      return EMPLOYER_SPONSORED_RETIREMENT_PLAN;
    default:
      return null;
  }
};

export const getSelectedRecommendation = (goalId, recommendationId, recommendations, completedFavoriteRecommendations) => {
  const activeGoal = recommendations?.filter(goal => goal.goalId === goalId)[0];
  const completedRecommendationCheck = completedFavoriteRecommendations.completedRecommendations.filter((rec) => rec.goalId === goalId && rec.recommendationsId === recommendationId);
  const favoriteRecommendationCheck = completedFavoriteRecommendations.favoriteRecommendations.filter((rec) => rec.goalId === goalId && rec.recommendationsId === recommendationId);

  if (completedRecommendationCheck.length) {
    return completedRecommendationCheck[0];
  } else if (favoriteRecommendationCheck.length) {
    return favoriteRecommendationCheck[0];
  }

  return activeGoal?.recommendations?.filter(goal => goal.recommendationsId === recommendationId)[0];
};

export const learnMore = (recommendation, navToTutorial, navToRecommendation, dispatch) => {
  const tutorial = getGoalTutorial(recommendation.packageName);

  if (tutorial) {
    navToTutorial(tutorial, recommendation);
  } else if (recommendation.getStartedLink === '') {
    navToRecommendation(recommendation);
  } else if (!recommendation.longDescription) {
    getStarted(recommendation, dispatch);
  } else {
    if (recommendation.packageUrl.includes('becu.org')) {
      window.open(recommendation.packageUrl, '_blank');
    } else {
      dispatch(updateExternalLinkModal(true, recommendation.packageUrl, false));
    }
  }
};

export const getStarted = (recommendation, dispatch) => {
  if (!recommendation.skipExternalModal) {
    dispatch(updateExternalLinkModal(true, recommendation.getStartedLink, recommendation.getStartedSametab));
  } else if (recommendation.getStartedSametab) {
      window.open(recommendation.getStartedLink, '_self');
  } else {
    if (recommendation.getStartedLink.includes('envelopes')) {
        window.open(recommendation.getStartedLink + '/sso?jwt=' + store.getState().session.token + '&subscribercode=' + FINANCIAL_HEALTH_SUBSCRIBER_CODE + '&subscriberType=persistent');
    } else {
      window.open(recommendation.getStartedLink);
    }
  }
};

export const getMatchingRecommendations = (packageName) => {
  if (packageName.includes('Financial Counseling with GreenPath')) {
    return GREENPATH;
  } else if (packageName.includes('Automate Payments')) {
    return AUTOMATE_PAYMENTS;
  } else if (packageName.includes('Alerts')) {
    return ALERTS;
  } else if (packageName.includes('Financial Health Check with a Specialist')) {
    return SPECIALIST;
  } else if (packageName.includes('Money Manager')) {
    return MONEY_MANAGER;
  }

  return null;
};

export const getRelatedGoals = (item, user, financialHealthGoal, recommendations) => {
  if (item.multipleGoalIds && item.multipleGoalIds.length) {
    const relatedGoals = [];
    item.multipleGoalIds.forEach((ids) => {
      const relatedGoal = user?.financialGoal?.find(goal => goal.financialGoalId === ids.goalId);
      if (relatedGoal?.goal) {
        const matchingRecommendation = recommendations?.find((recItem) => recItem.goalId === ids.goalId)?.recommendations?.find((rec) => rec.recommendationsId === ids.recommendationsId);
        if (matchingRecommendation) {
          relatedGoals.push(relatedGoal.goal);
        }
      }
    });
    return relatedGoals;
  } else {
    const relatedGoal = financialHealthGoal?.financialGoal?.find(goal => goal.financialGoalId === item.goalId);
    return relatedGoal;
  }
};