import {colors} from 'styles/config';

export const styles = {
  financialTopicHeading: {
    fontWeight: 900,
    color: colors.obsidian,
  },
  financialTopicIntroduction: {
    marginTop: 16,
  },
  formCheck: {
    paddingTop: '5px',
    paddingBottom: '5px',
    fontWeight: 600,
  },
};