import Flexbox from 'flexbox-react';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { universalStyles } from 'styles/universalStyles';

const DeleteGoalModal = ({ modalVisible, setModalVisible, financialGoal, modalFunction }) => {
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});

  const BackButton = () => (
    <Button 
      variant={'secondary'} 
      style={{width: (isSmallScreen) ? '100%' : 'auto', marginTop: isSmallScreen ? 16 : 0 }}
      onClick={() => setModalVisible(false)}
    >
      Back
    </Button>
  );

  const RemoveGoalButton = () => (
    <Button
      aria-label='Remove Goal'
      className={'envelope-confirmation-button'}
      variant="primary"
      style={{width: (isSmallScreen) ? '100%' : 'auto', marginLeft: !isSmallScreen ? 16 : 0 }}
      onClick={() => {modalFunction(financialGoal); setModalVisible(false);}}
    >
      Remove Goal
    </Button>
  );

  return (
    <Modal centered id={'DeleteGoalModal'} show={modalVisible} onHide={() => setModalVisible(false)}> 
      <Modal.Header closeButton className='border-0'>
        <Modal.Title style={universalStyles.modalHeader}>Remove from Goals</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to remove your <b>{financialGoal && financialGoal.goal}</b> goal and the related recommended actions?</p>
        <p>Any recommended actions you saved to your Favorites or marked as Completed will not be removed.</p>
        <p>This change cannot be reversed.</p>
        {isSmallScreen ? (
          <Flexbox flexDirection='column'>
            {RemoveGoalButton()}
            {BackButton()}
          </Flexbox>
        ) : (
          <Flexbox justifyContent='flex-end' alignItems='center'>
            {BackButton()}
            {RemoveGoalButton()}
          </Flexbox>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DeleteGoalModal;