import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Flexbox from 'flexbox-react';
import { Button } from 'react-bootstrap';

import { ONBOARD_DATA } from 'config/financialOnboard';

const OnboardStart = ({ getStarted }) => {
  const imgColBreakpoint1 = useMediaQuery({query: '(min-width: 1200px)'});
  const imgColBreakpoint2 = useMediaQuery({query: '(min-width: 991px)'});
  const imgMobileBreakpoint = useMediaQuery({query: '(max-width: 767px)'});

  return (
    <Flexbox flexDirection={'column'}>
      <div>
        <h2 style={{marginBottom: 30}}>Why Use the Financial Goals & Resource Guide?</h2>
        <Flexbox flexWrap='wrap'>
          {ONBOARD_DATA.map((data, index) => {
            return (
              <div key={index} style={{width: imgMobileBreakpoint ? '100%' : 212, marginTop: (imgMobileBreakpoint && index > 0) ? 30 : 0, marginLeft: (!imgMobileBreakpoint && index > 0) ? 20 : 0}}>
                <img src={!imgMobileBreakpoint ? require(`../../../assets/images/${data.image}`) : require(`../../../assets/images/${data.mobileImage}`)} aria-hidden='true' width={'100%'} />
                <div style={{paddingTop: 30}}>{data.body}</div>
              </div>
            );
          })}
        </Flexbox>
      </div>
      <Flexbox justifyContent={(imgMobileBreakpoint) ? 'flex-start' : 'flex-end'} style={{width: imgColBreakpoint1 ? '65%' : (imgColBreakpoint2 ? '72.5%' : '100%')}}>
        <Button variant='primary' style={{width: (imgMobileBreakpoint) ? '100%' : 120, marginTop: (imgMobileBreakpoint) ? 16 : 0}} onClick={getStarted}>Get Started</Button>
      </Flexbox>
    </Flexbox>
  );
};

export default OnboardStart;
