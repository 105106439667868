import React, { useRef, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {useMediaQuery} from 'react-responsive';

const InfoPopover = ({index, title, content, ariaLabel}) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});

  const handleClick = (toggle) => {
    setShow(toggle);
    setTimeout(() => {
      const popoverElement = document.getElementById(`InfoTipOverlay-${title}`);
      if (popoverElement && toggle) {
        popoverElement.focus();
      } else {
        setTimeout(() => {
          const buttonElement = document.getElementById(`popover-button-${title}`);
          buttonElement?.blur();
        }, 100);
      }
    }, 100);
    
  };

  return (
    <div style={{display: 'inline'}} ref={ref}>
      <OverlayTrigger
        key={index}
        trigger={isSmallScreen ? 'click' : ['click', 'hover']}
        placement={'bottom'}
        show={show}
        overlay={
          <Popover
            id={`InfoTipOverlay-${title}`}
            className='tooltip-popover'
            tabIndex={-1}
            onKeyDown={(e) => {
              if (e.keyCode === 27) {
                setShow(false);
                const buttonElement = document.getElementById(`popover-button-${title}`);
                buttonElement.focus();
              }
            }}
          >
            <Popover.Body>{content}</Popover.Body>
          </Popover>
        }
        container={ref.current}
        onToggle={handleClick}
      >
        <button
          id={`popover-button-${title}`}
          type={'button'}
          className={'popover-button'}
          aria-label={ariaLabel}
          aria-describedby={show ? `InfoPopoverOverlay-${title}` : undefined}
          onKeyDown={(e) => {
            if (e.keyCode === 27) {
              setShow(false);
            }
          }}
        >
          {title}
        </button>
      </OverlayTrigger>
    </div>
  );
};

export default InfoPopover;
