export const styles = {
  card: {
    border: 'none',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: 4,
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconWrapper: {
    display: 'flex',
    width: 40,
    height: 40,
    padding: 12,
  },
  financialGoalCheckbox: {
    marginLeft: 'auto',
    marginRight: 0
  },
};
