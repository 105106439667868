import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {styles} from './styles';
import { universalStyles } from 'styles/universalStyles';
import Flexbox from 'flexbox-react';

const EditAdditionalQuiz = ({addFinancialQuizAnswer, financialAnswers, financialTopic, selectedAnswers, index}) => {
const [topicAnswers, setTopicAnswers] = useState([]);
const title = financialTopic.topicIntroduction;

useEffect(() => {
  if (financialTopic?.financialProfileTopicId === 7 && selectedAnswers?.includes(19) && topicAnswers?.length < 1) {
    document.getElementById("flex-Budget Success").style.display = "none";
  } else if (financialTopic?.financialProfileTopicId === 10 && selectedAnswers?.includes(27) && topicAnswers?.length < 1) {
    document.getElementById("flex-Debt Status").style.display = "none";
  } else if (financialTopic?.financialProfileTopicId === 14 && selectedAnswers?.includes(36) && topicAnswers?.length < 1) {
    document.getElementById("flex-Employer Match").style.display = "none";
  }
}); 

const currentSelection = (financialProfileAnswerId, topic) => {
  const itemFound = topicAnswers && topicAnswers.some(el => el.topic === topic);

  if (itemFound) {
    let index = topicAnswers.findIndex(el => el.topic == topic);
    let arrayCopy = [...topicAnswers];
    arrayCopy[index] = {...arrayCopy[index], financialProfileAnswerId: financialProfileAnswerId};
    setTopicAnswers(() => arrayCopy);
  } else {
    topicAnswers.push({topic, financialProfileAnswerId});
    setTopicAnswers(() => topicAnswers);
  }

  if (financialTopic?.financialProfileTopicId === 7 && financialProfileAnswerId === 19) {
    document.getElementById("flex-Budget Success").style.display = "none";
  } else {
    if (document.getElementById("flex-Budget Success")) {
      document.getElementById("flex-Budget Success").style.display = "flex";
    }
  }

  if (financialTopic?.financialProfileTopicId === 10 && financialProfileAnswerId === 27) {
    document.getElementById("flex-Debt Status").style.display = "none";
  } else if (financialTopic?.financialProfileTopicId === 10) {
    if (document.getElementById("flex-Debt Status")) {
      document.getElementById("flex-Debt Status").style.display = "flex";
    }
  }

  if (financialTopic?.financialProfileTopicId === 14 && financialProfileAnswerId === 36) {
    document.getElementById("flex-Employer Match").style.display = "none";
  } else if (financialTopic?.financialProfileTopicId === 14) {
    if (document.getElementById("flex-Employer Match")) {
      document.getElementById("flex-Employer Match").style.display = "flex";
    }
  }
};

 return (
  <Flexbox justifyContent='center' flexDirection='column' id={`flex-${financialTopic.topic}`}>
    <Form>
      <Form.Group controlId={`quiz-${index}`} style={{marginTop: (index === 0) ? 24 : 36}}>
      <h2 style={{marginBottom: 24}}>{financialTopic.topic}</h2>
      <Form.Label as={'h4'} style={universalStyles.form.inputLabel}>{title}</Form.Label>
        {financialAnswers?.map((answer) => (
          <div key={answer.financialProfileAnswerId} style={{paddingLeft: 8}}>
            <Form.Check
              id={`${financialTopic.topic.toLowerCase()}-answer-${answer.financialProfileAnswerId}`}
              type={'radio'}
              name={`${financialTopic.topic}`}
              className="radio-button"
              label={answer.answer}                      
              defaultChecked={selectedAnswers?.includes(answer.financialProfileAnswerId)}
              onChange={(e) => {
                currentSelection(answer.financialProfileAnswerId);
                addFinancialQuizAnswer(answer.financialProfileAnswerId, financialTopic.topic);
              }}
              style={styles.formCheck}
            />
          </div>
        ))}
      </Form.Group> 
    </Form>
  </Flexbox>
 );
};

export default EditAdditionalQuiz;