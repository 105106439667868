import Flexbox from 'flexbox-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import PageMetaData from 'components/app/PageMetaData';
import Header from 'components/app/Header';
import FinancialGoals from 'components/financial-health/Goals';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import Breadcrumbs from 'components/app/Breadcrumbs';

import { universalStyles } from 'styles/universalStyles';
import { HOME_PAGE, MY_GOALS_PAGE, MY_GOALS_PAGE_TITLE, RETURN_TO_OLB_LINK, UNSAVED_MODAL_DATA } from 'config/constants';
import { createFinancialGoal, getRecommendations, getUser, loginWithToken, resetRecommendationsSuccess, setSessionPath, updateBreadcrumbs, updateSaveChangeModal } from 'actions';
import { authSelector, financialHealthGoalAddedSelector, financialHealthGoalSelector, intializedSelector, recommendationsSuccessSelector, saveChangeModalSelector, sessionTokenSelector, userSelector } from 'selectors';
import { tagFinishAddNewGoals } from 'services/gtm';

const homeBreadcrumb = {
  title: 'Financial Goals & Resource Guide',
  page: HOME_PAGE,
  active: false,
};

const addGoalsBreadcrumb = {
  title: 'Your Goals',
  page: MY_GOALS_PAGE,
  active: true,
};

const AddGoals = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector(authSelector);
  const sessionToken = useSelector((sessionTokenSelector));
  const initialized = useSelector(intializedSelector);
  const user = useSelector(userSelector);
  const financialHealthGoals = useSelector(financialHealthGoalSelector);
  const saveChangeModal = useSelector(saveChangeModalSelector);
  const financialGoalAdded = useSelector(financialHealthGoalAddedSelector);
  const recommendationsSuccess = useSelector(recommendationsSuccessSelector);
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});
  const [saving, setSaving] = useState(false);
  const [filteredGoals, setFilteredGoals] = useState([]);
  const [goals, setGoals] = useState([]);
  const [saveChangesModals, setSaveChangesModal] = useState(false);

  useEffect(() => {
    dispatch(updateBreadcrumbs([homeBreadcrumb, addGoalsBreadcrumb]));
  }, []);

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      window.open(RETURN_TO_OLB_LINK, '_self');
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    }
  }, []);

  useEffect(() => {
    dispatch(updateSaveChangeModal(false));
    if (user?.financialGoal?.length && financialHealthGoals?.financialGoal?.length) {
      const filtered = financialHealthGoals?.financialGoal?.filter(({financialGoalId}) => !user.financialGoal.some((e) => e.financialGoalId === financialGoalId));
      setFilteredGoals(filtered);
      setGoals([]);
    }
  }, [user, financialHealthGoals]);

  useEffect(() => {
    if (financialGoalAdded) {
      dispatch(getRecommendations(true));
    }
  }, [financialGoalAdded]);

  useEffect(() => {
    if (recommendationsSuccess) {
      dispatch(getUser());
      dispatch(resetRecommendationsSuccess());
      navHome();
    }
  }, [recommendationsSuccess]);

  const navHome = () => {
    setSaving(false);
    const path = {pathname: HOME_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
  };

  const save = () => {
    setSaving(true);
    tagFinishAddNewGoals(user);
    dispatch(updateSaveChangeModal(false));
    dispatch(createFinancialGoal(goals.map((el) => ({financialGoalId: parseInt(el.goalId), goal: el.goal})))); 
  };

  const goalsCheck = (goalList) => {
    const userGoalList = user?.financialGoal?.map((goal) => goal.financialGoalId.toString()).sort((a, b) => a - b).join('');
    const sortedGoalList = goalList?.map((goal) => goal.goalId).concat(userGoalList).sort((a, b) => a - b).join('');

    return (sortedGoalList !== userGoalList);
  };

  const addFinancialGoal = (goalId, goal, imgName) => {
    const goalFound = goals.some(el => el.goalId === goalId);
    let financialGoalCopy = [...goals];

    if (!goalFound) {
      financialGoalCopy.push({goalId, goal, imgName});
    } else {
      financialGoalCopy = financialGoalCopy.filter(id => id.goalId !== goalId);
    }

    setGoals(financialGoalCopy);
    dispatch(updateSaveChangeModal(goalsCheck(financialGoalCopy)));
  };

  const back = () => {
    const homeBreadcrumb = {
      title: 'Financial Goals & Resource Guide',
      page: HOME_PAGE,
      active: true,
    };
    dispatch(updateBreadcrumbs([homeBreadcrumb]));
    navHome();
  };

  const BackButton = () => (
    <Button
      id={'AddNewGoalsBack'}
      variant={'secondary'}
      style={{ width: isSmallScreen ? '100%' : 'auto', marginTop: isSmallScreen ? 15 : 0 }}
      disabled={saving}
      aria-disabled={saving}
      onClick={() => {
        if (saveChangeModal) {
          setSaveChangesModal(true);
        } else {
          back();
        }
      }}
    >
      Back
    </Button>
  );

  const AddGoalsButton = () => (
    <Button
      id={'AddNewGoalsSave'}
      variant={'primary'}
      aria-label='Add Goals'
      style={{ width: isSmallScreen ? '100%' : 'auto', marginLeft: isSmallScreen ? 0 : 15 }}
      disabled={saving || !goalsCheck(goals)}
      aria-disabled={saving || !goalsCheck(goals)}
      onClick={save}
    >
      Add Goals
    </Button>
  );

  return (
    <Flexbox flexDirection={'column'} style={universalStyles.container}>
      <PageMetaData pageTitle={MY_GOALS_PAGE_TITLE} />
      <Header editView={true} toggleEditView={back} />
      <Container role={'main'} aria-label={''} className={'financial-health'} id="main-content" style={{paddingTop: 50}}>
        <Breadcrumbs editView={true} toggleEditView={back} />
        <Row style={{paddingTop: 20, paddingBottom: 60}}>
          <Col lg={7}>
            <h1>Your Goals</h1>
            <div>
              <h2 style={{fontWeight: 600, marginTop: 36, marginBottom: 16}}>Add New Goals</h2>
              {filteredGoals && filteredGoals.map((financialGoal, index) => {
                const selected = goals?.filter((goal) => goal.goalId === financialGoal.financialGoalId.toString()).length === 1;
                return <FinancialGoals addFinancialGoal={addFinancialGoal} key={financialGoal.financialGoalId} financialGoal={financialGoal} selected={selected} index={index} />;
              })}
              <div style={{marginTop: 30}}>
                {isSmallScreen ? (
                  <Flexbox flexDirection='column'>
                    {AddGoalsButton()}
                    {BackButton()}
                  </Flexbox>
                ) : (
                  <Flexbox justifyContent='flex-start' alignItems='center'>
                    {BackButton()}
                    {AddGoalsButton()}
                  </Flexbox>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ConfirmationModal
        modalData={UNSAVED_MODAL_DATA}
        modalFunction={back}
        showModal={saveChangesModals}
        setModalVisibleStatus={setSaveChangesModal}
      />
    </Flexbox>
  );
};

export default AddGoals;
