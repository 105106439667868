import React from 'react';
import Flexbox from 'flexbox-react';
import {Form} from 'react-bootstrap';
import {styles} from './styles';

const FinancialHealthQuiz = ({addFinancialTopicAnswer, financialHealthTopic, topic, answers, hideDesc = false, headerRef = null, formLabelAs = 'h4'}) => {
  const financialAnswers = financialHealthTopic.financialProfileTopicAnswers.filter((financialTopic) => financialTopic.financialTopic.topic === topic).map((x) => x.financialAnswers);
  const financialTopic = financialHealthTopic.financialProfileTopicAnswers.filter((financialTopic) => financialTopic.financialTopic.topic === topic).map((x) => x.financialTopic)[0];
  const selected = answers.filter((topicAnswer) => topicAnswer.topic === topic)[0]?.financialProfileAnswerId;

  return (
    <Flexbox justifyContent='center' flexDirection='column'>
      {!hideDesc &&
        <>
          <h2 className='profile-question-header' tabIndex={-1} ref={headerRef} style={styles.financialTopicHeading}>{financialTopic?.topic}</h2>
          <p>{financialTopic?.topicDescription}</p>
        </>
      }
      <Form>
        <Form.Group controlId={`${topic.toLowerCase()}-answer`}>
          <Form.Label as={formLabelAs} style={styles.financialTopicIntroduction}>{financialTopic?.topicIntroduction}</Form.Label>
          {financialAnswers?.map((financialAnswer) => {
            return (
              financialAnswer.map((answer) => (
                <div key={answer.financialProfileAnswerId} style={{paddingLeft: 8}}>
                  <Form.Check
                    id={`${topic.toLowerCase()}-answer-${answer.financialProfileAnswerId}`}
                    type={'radio'}
                    name={'financialProfileAnswer'}
                    className="radio-button"
                    label={answer.answer}
                    onChange={(e) => addFinancialTopicAnswer(answer.financialProfileAnswerId,topic)}
                    checked={selected === answer.financialProfileAnswerId}
                    style={styles.formCheck}
                  />
                </div>
              ))
            );
          })}
        </Form.Group>
      </Form>
    </Flexbox>
  );
};

export default FinancialHealthQuiz;