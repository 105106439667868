export const BUSINESS_USER = 'The BECU Envelopes application is not available for Business Accounts at this time.';
export const GENERAL_ERROR = "There's a problem. Please try to log in later.";
export const LOGIN_FAILURE = 'Invalid User ID or Password. Please verify that your credentials are entered correctly.';
export const MAX_SESSION = 'You have reached the maximum session length. Please log in again to continue.';
export const RESTRICTED = 'Your Online Banking access has been restricted for further access at this time.';
export const TERMS_NOT_MET = 'You must agree to the terms and conditions to use BECU Envelopes. You can log in again to review.';

export const HEALTH_CHECK_FLOW = 'Your answers to goalName have been saved. View your new recommendations below.';
export const EDIT_GOAL_FLOW = 'Your answers to goalName have been modified. Some of your recommendations may have changed.';
export const EDIT_PROFILE_FLOW = 'Your Financial Profile has been updated. Some of your recommendations may have changed.';

export const GOALS_ADDED = 'Your new goals are added below.';
export const CREATE_PROFILE_SUCCESS = 'Your financial information has been saved! Select a goal below to see personalized recommendations you can take action on today.';
export const GOAL_REMOVED = 'goalName goal removed.';