import { colors } from "styles/config";

export const styles = {
  card: {
    border: 'none', 
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: 4,
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconWrapper: {
    width: 40,
    height: 40,
    backgroundColor: colors.cloud,
    borderRadius: 4,
    marginRight: 8,
  },
  recommendedActions: {
    fontSize: 14,
  },
  dropdownToggle: {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropdownItem: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center'
  },
};
