import {
  CREATE_FINANCIAL_GOAL_SUCCESS,
  CREATE_FINANCIAL_TOPIC_SUCCESS,
  DELETE_FINANCIAL_GOAL_SUCCESS,
  DELETE_FINANCIAL_TOPIC_SUCCESS,
  GET_FINANCIAL_GOAL_SUCCESS,
  GET_FINANCIAL_RECOMMENDATIONS_SUCCESS,
  GET_FINANCIAL_TOPIC_SUCCESS,
  GET_CTA_GOALS_SUCCESS,  
  EDIT_GOAL_SUCCESS,
  UPDATE_TUTORIAL,
  UPDATE_COMPLETION_SUCCESS,
  UPDATE_FAVORITES_SUCCESS,
  GET_COMPLETED_FAVORITE_RECOMMENDATIONS_SUCCESS
} from '../config/actionTypes';
import {HEALTH_SERVICE_API, getAuthHeader} from '../services/apiConfig';
import {FINANCIAL_HEALTH_API, FINANCIAL_GOAL, FINANCIAL_TOPIC, FINANCIAL_RECOMMENDATIONS, USER_API, CTA_GOALS_API, COMPLETION_API, FAVORITES_API, COMPLETED_FAVORITE_RECOMMENDATIONS} from '../config/constants';
import {handleGenericError} from '../services/errorConfig';

export const getFinancialGoal = () => (dispatch) => {
  HEALTH_SERVICE_API.get(FINANCIAL_HEALTH_API + FINANCIAL_GOAL, {
    headers: getAuthHeader(),
  })
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
    });

  const success = (financialgoal) => {
    return {
      type: GET_FINANCIAL_GOAL_SUCCESS,
      payload: financialgoal,
    };
  };
};

export const getFinancialTopic = (goalIds = '') => (dispatch) => {
  HEALTH_SERVICE_API.get(FINANCIAL_HEALTH_API + FINANCIAL_TOPIC, {
    headers: getAuthHeader(),
    params: {goalIds}
  })
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
    });

  const success = (financialtopic) => {
    return {
      type: GET_FINANCIAL_TOPIC_SUCCESS,
      payload: financialtopic,
    };
  };
};

export const createFinancialGoal = (financialGoals) => (dispatch) => {
  /*
    "financialGoal": [
  {
    "financialGoalId": 0,
    "goal": "string"
  }
  ]
  */
  const body = {
    financialGoal: financialGoals,
  };

  HEALTH_SERVICE_API.post(FINANCIAL_HEALTH_API + USER_API + FINANCIAL_GOAL, body, {headers: getAuthHeader()})
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((error) => {
      handleGenericError(error, dispatch);
    });

  const success = (data) => {
    return {
      type: CREATE_FINANCIAL_GOAL_SUCCESS,
      payload: true,
    };
  };
};

export const createFinancialTopic = (financialTopicAnswers) => (dispatch) => {
  /*
  {
    "financialProfileAnswerId": [
      0
    ]
  }
  */

  const body = {
    financialProfileAnswerId: financialTopicAnswers,
  };

  HEALTH_SERVICE_API.post(FINANCIAL_HEALTH_API + USER_API + FINANCIAL_TOPIC, body, {headers: getAuthHeader()})
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((error) => {
      handleGenericError(error, dispatch);
    });

  const success = (data) => {
    return {
      type: CREATE_FINANCIAL_TOPIC_SUCCESS,
      payload: true,
    };
  };
};

export const deleteFinancialGoal = (financialGoal) => (dispatch) => {
  HEALTH_SERVICE_API.delete(FINANCIAL_HEALTH_API + USER_API + FINANCIAL_GOAL, {headers: getAuthHeader(), data: {financialGoal}})
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
    });

  const success = (data) => {
    return {
      type: DELETE_FINANCIAL_GOAL_SUCCESS,
      payload: true,
    };
  };
};

export const deleteFinancialTopic = (financialProfileAnswerId) => (dispatch) => {
  HEALTH_SERVICE_API.delete(`${FINANCIAL_HEALTH_API + USER_API + FINANCIAL_TOPIC}/${financialProfileAnswerId}`, {headers: getAuthHeader()})
    .then((res) => {
      dispatch(success(financialProfileAnswerId, res.data));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
    });

  const success = (financialProfileAnswerId, data) => {
    return {
      type: DELETE_FINANCIAL_TOPIC_SUCCESS,
      payload: {financialProfileAnswerId, financialTopic: data},
    };
  };
};

export const resetFinancialGoalAdded = () => {
  return {
    type: CREATE_FINANCIAL_GOAL_SUCCESS,
    payload: false,
  };
};

export const resetFinancialTopicAdded = () => {
  return {
    type: CREATE_FINANCIAL_TOPIC_SUCCESS,
    payload: false,
  };
};

export const resetFinancialGoalDeleted = () => {
  return {
    type: DELETE_FINANCIAL_GOAL_SUCCESS,
    payload: false,
  };
};

export const getRecommendations = (navOnSuccess = false) => (dispatch) => {
  HEALTH_SERVICE_API.get(FINANCIAL_HEALTH_API + USER_API + FINANCIAL_RECOMMENDATIONS, {
    headers: getAuthHeader(),
  })
    .then((res) => {
      dispatch(success(res.data.recommendationsList ? res.data.recommendationsList : []));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
    });

  const success = (recommendations) => {
    return {
      type: GET_FINANCIAL_RECOMMENDATIONS_SUCCESS,
      payload: {type: 'get', recommendations, navOnSuccess },
    };
  };
};

export const resetRecommendationsSuccess = () => {
  return {
    type: GET_FINANCIAL_RECOMMENDATIONS_SUCCESS,
    payload: {type: 'reset'},
  };
};

export const getCTAGoals = (ctaList) => (dispatch) => {
  const body = {
    ctaList: ctaList,
  };

  HEALTH_SERVICE_API.post(CTA_GOALS_API, body, {headers: getAuthHeader()})
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((error) => {
      handleGenericError(error, dispatch);
    });

  const success = (data) => {
    return {
      type: GET_CTA_GOALS_SUCCESS,
      payload: data,
    };
  };
};

export const resetCTAGoalsSuccess = () => {
  return {
    type: GET_CTA_GOALS_SUCCESS,
    payload: {type: 'reset'},
  };
};

export const updateEditingGoal = (financialGoal) => {
  return {
    type: EDIT_GOAL_SUCCESS,
    payload: financialGoal,
  };
};

export const updateTutorial = (tutorial) => {
  return {
    type: UPDATE_TUTORIAL,
    payload: tutorial,
  };
};

// recommendationType: [1 (completed), 2 (favorite), 3 (default - both)]
export const getCompletedFavoriteRecommendations = (recommendationType = 3) => (dispatch) => {
  HEALTH_SERVICE_API.get(FINANCIAL_HEALTH_API + USER_API + COMPLETED_FAVORITE_RECOMMENDATIONS, {
    headers: getAuthHeader(),
    params: {
      recommendationType
    },
  })
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
    });

  const success = (data) => {
    return {
      type: GET_COMPLETED_FAVORITE_RECOMMENDATIONS_SUCCESS,
      payload: data,
    };
  };
};

export const updateRecommendationCompletion = (recommendationId, goalId, isCompleted) => (dispatch) => {
  HEALTH_SERVICE_API.put(FINANCIAL_HEALTH_API + USER_API + COMPLETION_API, null, {
    headers: getAuthHeader(),
    params: {
      goalId,
      recommendationId,
      completeStatus: isCompleted ? 1 : 0,
    }
  })
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((error) => {
      handleGenericError(error, dispatch);
    });

  const success = (data) => {
    return {
      type: UPDATE_COMPLETION_SUCCESS,
      payload: {value: true, recommendationId, goalId, isCompleted},
    };
  };
};

export const updateRecommendationFavorites = (recommendations, isFavorite) => (dispatch) => {
  const body = {
    recommendations,
    isFavorite: isFavorite ? 1 : 0,
  };

  HEALTH_SERVICE_API.put(FINANCIAL_HEALTH_API + USER_API + FAVORITES_API, body, {headers: getAuthHeader()})
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((error) => {
      handleGenericError(error, dispatch);
    });

  const success = (data) => {
    return {
      type: UPDATE_FAVORITES_SUCCESS,
      payload: {value: true, recommendations, isFavorite},
    };
  };
};

export const resetRecommendationCompletion = () => {
  return {
    type: UPDATE_COMPLETION_SUCCESS,
    payload: {value: false},
  };
};

export const resetRecommendationFavorites = () => {
  return {
    type: UPDATE_FAVORITES_SUCCESS,
    payload: {value: false},
  };
};
