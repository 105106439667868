import {createUseStyles} from 'react-jss';
import {colors, fontSize} from 'styles/config';

export const styles = createUseStyles({
  alert: {
    fontSize: fontSize.normal,
    '& p': {
      marginBottom: 0,
    },
    '& i': {
      marginRight: '1rem',
    },
    '& .close': {
      color: colors.obsidian,
      padding: '.65rem 1.25rem .85rem',
    },
    '& button.link-button': {
      fontWeight: 600,
      color: `${colors.obsidian} !important`,
    },
    '& a': {
      color: `${colors.obsidian} !important`,
    },
    '& button': {
      color: `${colors.obsidian} !important`,
    },
  },
  info: {
    extend: 'alert',
    borderColor: '#4470D6',
    backgroundColor: '#D7E4F4',
    color: colors.obsidian,
    '& svg': {
      color: '#4470D6',
    },
  },
  danger: {
    extend: 'alert',
    borderColor: '#AB111A',
    backgroundColor: '#F5D9DB',
    color: colors.obsidian,
    alignItems: 'center',
    '& svg': {
      color: '#AB111A !important',
    },
  },
  warning: {
    extend: 'alert',
    borderColor: '#ffa300',
    backgroundColor: '#ffeccc',
    color: colors.obsidian,
    '& svg': {
      color: '#ffa300',
    },
  },
  success: {
    extend: 'alert',
    borderColor: '#33c677',
    backgroundColor: '#d9f7ea',
    color: colors.obsidian,
    '& svg': {
      color: '#33c677',
    },
  },
  svg: {},
});
