import React from 'react';

// import {colors} from 'styles/config';

// const BoldText = (text) => (
//   <strong key={text} style={{color: colors.obsidian}}>
//     {text}
//   </strong>
// );

// const ParagraphText = (key, text) => <p key={key}>{text}</p>;

// const Button = (text, location) => (
//   <div style={{marginTop: '1.5rem'}}>
//     <button
//       id={`${text.replace(/ /g, '-')}`}
//       key={text}
//       onClick={() => window.open(location, '_self')}
//       className={'btn btn-primary'}
//       style={{width: 'auto', minWidth: '20%'}}
//     >
//       {text}
//     </button>
//   </div>
// );

// const OrderedList = (key, textArray) => (
//   <ol key={key}>
//     {textArray.map((text, index) => (
//       <li key={`${key}-${index}`}>{text}</li>
//     ))}
//   </ol>
// );

const UnOrderedList = (key, bottomMargin, textArray) => (
  <ul key={key} style={{marginBottom: bottomMargin ? '1em' : 0}}>
    {textArray.map((text, index) => (
      <li key={`${key}-${index}`}>{text}</li>
    ))}
  </ul>
);

const LinkText = (key, text, link, newPage) => (
  <a className='text-link-button no-padding' key={key} href={link} target={newPage ? '_blank' : '_self'} rel="noreferrer">
    {text}
  </a>
);

export const FAQ_DATA = [
  {
    title: 'Getting Started',
    topics: [
      {
        questions: [
          {
            q: 'What is the Financial Goals & Resource Guide?',
            a:
              'The Financial Goals and Resource Guide is a free tool designed to help you take control of your finances and work towards achieving your financial goals. Select your financial goals and answer a few questions, then you’ll immediately receive personalized recommendations, tips, and resources to help you improve your financial health now and in the future.',
          },
          {
            q: 'Is the Financial Goals & Resource Guide right for me?',
            a: [
              'Use this tool if any of the following apply:',
              UnOrderedList('usefulness', false, [
                'You’d like to build a savings plan to cover an unexpected emergency or avoid going into debt',
                'You’d like to decrease or pay off debt',
                'You’d like to receive help with budgeting and money management',
                'You can use expert guidance on planning and pursuing financial goals',
                'You want to start organizing your finances, but you aren’t sure where to start',
                'You want support with common financial goals',
              ]),
            ],
          },
          {
            q: 'How long will it take to use the Financial Goals & Resource Guide?',
            a: 'In five minutes or less, you’ll select your financial goals, answer a few questions, and receive personalized recommendations, tips, and resources to help you improve your financial health now and in the future. Return any time to review your personalized recommendations and access resources.',
          },
          {
            q: 'How is this tool different from the phone-based Financial Health Check BECU offers?',
            a: [
              'For those interested, BECU offers a free confidential one-on-one phone-based Financial Health Check session with a BECU financial coach. The average session runs 40 to 50 minutes and is conducted by phone. ',
              LinkText(0, 'Click here', 'https://www.becu.org/members-matter/education/financial-health-check', true),
              ' to learn more about it and to schedule your free session.',
            ],
          },
          {
            q: 'Is the Financial Goals & Resource Guide available in the BECU mobile app?',
            a: 'At this time, the tool is only available via web browser and accessible in Online Banking.',
          },
          {
            q: 'What sort of information or documents do I need to provide to use the Financial Goals & Resource Guide?',
            a: 'No documents are needed, simply answer a few short questions about your income, expenses, and financial goals. Your results are confidential and will not be shared.',
          },
        ],
      },
    ],
  },
  {
    title: 'Managing Your Goals and Recommended Actions',
    topics: [
      {
        questions: [
          {
            q: 'How do I take action on the recommendations, tips, and resources that are being recommended to me?',
            a: 'Each recommendation will have instructions on how you can act on them and improve your financial health.',
          },
          {
            q: 'Can I change or remove the Goals I’ve selected?',
            a: 'Yes! Just like real-life plans, your financial goals and situation will change over time. You can add or remove financial goals any time to keep you on track to reach your goals.',
          },
          {
            q: 'Can I change my answers to the questions?',
            a: 'Yes! Just like real-life plans, your financial goals and situation will change over time. Select Edit Profile on the homepage to update your answers any time and receive new, updated recommendations to keep you on track to reach your goals.',
          },
          {
            q: 'Why do some goals have the same recommendations?',
            a: 'Each goal will have a set of recommendations based on how you’ve answered the questions. Some goals, although different, share steps and recommendations that help you reach that goal.',
          },
        ],
      },
    ],
  },
  {
    title: 'Providing Feedback',
    topics: [
      {
        questions: [
          {
            q: 'How do I provide feedback?',
            a: 'Easily share feedback by clicking the feedback tab on the page. A window modal will appear with a few questions to answer. We encourage you to be candid with your feedback. You can also ',
            feedbackModalLink: true,
          },
          {
            q: 'Why should I provide feedback?',
            a: 'Your feedback will help improve the tool and financial health guidance for other BECU members.',
          },
        ],
      },
    ],
  },
];
