import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Flexbox from 'flexbox-react';
import { Col, Container } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import PageMetaData from 'components/app/PageMetaData';
import Header from 'components/app/Header';
import Breadcrumbs from 'components/app/Breadcrumbs';
import FinancialHealthQuiz from 'components/profile/topics';
import { colors } from 'styles/config';
import { styles } from './styles';
import { universalStyles } from 'styles/universalStyles';
import { HOME_PAGE, RETURN_TO_OLB_LINK } from 'config/constants';

import {
  authSelector,
  financialHealthTopicAddedSelector,
  financialHealthTopicSelector,
  intializedSelector,
  recommendationsSuccessSelector,
  sessionTokenSelector,
  userSelector,
} from 'selectors';
import { getFinancialTopic, createFinancialTopic, loginWithToken, setSessionPath, getUser, getRecommendations, resetRecommendationsSuccess, updateBreadcrumbs, updateSaveChangeModal, updateEditingGoal } from 'actions';

import {
  CheckCircle,
  CircleFill,
} from 'react-bootstrap-icons';
import { tagAdditionalQuestions } from 'services/gtm';

const AdditionalQuestions = (selectedGoal) => {

  const dispatch = useDispatch();
  const history = useHistory();  
  const financialHealthTopic = useSelector(financialHealthTopicSelector);  
  const financialTopicAdded = useSelector(financialHealthTopicAddedSelector);
  const recommendationsSuccess = useSelector(recommendationsSuccessSelector);
  const user = useSelector(userSelector);
  const loggedIn = useSelector(authSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const initialized = useSelector(intializedSelector);
  const [step, setStep] = useState(0);
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});
  const largeScreen = useMediaQuery({query: '(min-width: 767px)'});
  const [financialAnswerId, setFinancialAnswerId] = useState([]);
  const [disableNextBtn, setDisableBtn] = useState(true);  
  const headerRef = useRef(null);

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      window.open(RETURN_TO_OLB_LINK, '_self');
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    }      
      const id = selectedGoal.selectedGoal.financialGoalId;
      dispatch(getFinancialTopic(id));
  }, [loggedIn, sessionToken, initialized, history]);


  useEffect(() => {
    if (financialTopicAdded) {
      dispatch(getRecommendations(true));
    }
  }, [financialTopicAdded]);

  useEffect(() => {
    if (recommendationsSuccess) {
      dispatch(getUser());
      dispatch(resetRecommendationsSuccess());
      const path = {pathname: HOME_PAGE};
      dispatch(setSessionPath(path));
      history.push(path);
    }
  }, [recommendationsSuccess]);

  const back = () => {
    const homeBreadcrumb = {
      title: 'Financial Goals & Resource Guide',
      page: HOME_PAGE,
      active: true,
    };
    dispatch(updateBreadcrumbs([homeBreadcrumb]));
    
    const path = {pathname: HOME_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
    dispatch(updateSaveChangeModal(false));
  };
  
  const addFinancialTopicAnswer = (financialProfileAnswerId, topic) => {
    const itemFound = financialAnswerId && financialAnswerId.some(el => el.topic === topic);

    if (itemFound) {
      let index = financialAnswerId.findIndex(el => el.topic == topic);
      let arrayCopy = [...financialAnswerId];
      arrayCopy[index] = {...arrayCopy[index], financialProfileAnswerId: financialProfileAnswerId};
      setFinancialAnswerId(() => arrayCopy);
    } else {
      financialAnswerId.push({topic, financialProfileAnswerId});
      setFinancialAnswerId(() => financialAnswerId);
    }

    setDisableBtn(false);
  };

  const submit = () => {
    let additionalQuestionIds = financialAnswerId.map((el) => el.financialProfileAnswerId);
    if (additionalQuestionIds.includes(36) && additionalQuestionIds.length > 3) {
      additionalQuestionIds = additionalQuestionIds.filter((x) => ![37, 38, 39].includes(x));
    }
    tagAdditionalQuestions(user, selectedGoal.selectedGoal.financialGoalId, additionalQuestionIds);
    dispatch(createFinancialTopic(additionalQuestionIds));
    dispatch(updateSaveChangeModal(false));
    dispatch(updateEditingGoal(['healthcheck', selectedGoal?.selectedGoal?.goal]));
  };

  const checkButtonStatus = (stepCheck) => {
    // if Manage Spending + first question's answer = No -> skip next question    
    const manageSpendingCheck = financialAnswerId.filter(el => el.topic === 'Using a Budget').map((el) => el.financialProfileAnswerId);
    if (stepCheck === 1 && manageSpendingCheck && manageSpendingCheck.length > 0 && manageSpendingCheck[0] === 19) {
      submit();
    } else {    
      // if Improve Credit Standing + answer = 'I don't have any debt' -> skip next question       
      const improveCreditCheck = financialAnswerId.filter(el => el.topic === 'Household Debts').map((el) => el.financialProfileAnswerId);      
      if (stepCheck === 2 && improveCreditCheck && improveCreditCheck.length > 0 && improveCreditCheck[0] === 27) {
        submit();
      } else {
        const planForRetirementCheck = financialAnswerId.filter(el => el.topic === 'Employer-Sponsored Retirement Plan').map((el) => el.financialProfileAnswerId);
        if (stepCheck === 2 && planForRetirementCheck && planForRetirementCheck.length > 0 && planForRetirementCheck[0] === 36) {
          setStep(stepCheck + ((step === 3) ? -1 : 1));
          setDisableBtn(setDisableButtonValue(stepCheck + ((step === 3) ? -1 : 1)));
          dispatch(updateSaveChangeModal(true));
          dispatch(updateEditingGoal(['healthcheck', selectedGoal?.selectedGoal?.goal]));
          focusHeader();
        } else {
          if (stepCheck >= selectedGoal.selectedGoal.questions) {
            submit();
          } else {
            setStep(stepCheck);
            setDisableBtn(setDisableButtonValue(stepCheck));
            dispatch(updateSaveChangeModal(true));
            dispatch(updateEditingGoal(['healthcheck', selectedGoal?.selectedGoal?.goal]));
            focusHeader();
          }
        }
      }
    }
  };

  const focusHeader = () => {
    setTimeout(() => {
      headerRef.current.focus();
    }, 200);
  };
  
  const setDisableButtonValue = (stepCheck) => {
    const question = financialHealthTopic.financialProfileTopicAnswers[stepCheck + 3];
    const questionTopic =  question.financialTopic.topic;

    return financialAnswerId.findIndex(el => el.topic === questionTopic) === -1;
  };

  const displayQuestion = () => {
    const question = financialHealthTopic.financialProfileTopicAnswers[step + 3];
    const questionTopic =  question.financialTopic.topic;

    return <FinancialHealthQuiz addFinancialTopicAnswer={addFinancialTopicAnswer} financialHealthTopic={financialHealthTopic} topic={questionTopic} answers={financialAnswerId} headerRef={headerRef} />;
  };

  const displayTracker = () => {
    return  !(selectedGoal.selectedGoal.goal === 'Manage Spending' || selectedGoal.selectedGoal.goal === 'Improve Credit Standing' || selectedGoal.selectedGoal.goal === 'Plan for Retirement');
  };

  const getStepsLength = () => {
    const steps = financialHealthTopic?.financialProfileTopicAnswers?.filter((financialTopic) => financialTopic.financialTopic.financialProfileTopicId > 3).map((x) => x.financialTopic.topic);
    return (steps) ? steps.length : 0;
  };

  const getPageTitle = () => {
    const additionalQuestionsTitle = selectedGoal.selectedGoal.goal + `, step ${step + 1} of ${getStepsLength()} | BECU`;
    return additionalQuestionsTitle;
  };

  const BackButton = () => (
    <Button
      id={'FinancialProfileBack'}
      variant={'secondary'}
      style={{width: isSmallScreen ? '100%' : 'auto', marginTop: isSmallScreen ? 15 : 0}}
      onClick={() => {
        if (step === 0) {
          back();
        } else {
          checkButtonStatus(step - 1);
        }
      }}
    >
      {(step === 0) ? 'Cancel' : 'Back'}
    </Button>
  );

  const NextButton = () => (
    <Button
      id={'FinancialProfileNext'}
      variant={'primary'}
      style={{width: isSmallScreen ? '100%' : 'auto', marginLeft: isSmallScreen ? 0 : 15}}
      onClick={() => checkButtonStatus(step + 1)}
      disabled={disableNextBtn}
      aria-disabled={disableNextBtn}
    >
      {(step + 1 === selectedGoal.selectedGoal.questions) ? 'Finish and Save' : 'Next'}
    </Button>
  );

  return (
    <Flexbox flexDirection='column' style={universalStyles.container}>
      <PageMetaData pageTitle={getPageTitle()} />
      <Header />
      <div style={styles.subheaderWrapper}>
        <Container style={{marginTop: 8, marginBottom: 8}}>
          <Breadcrumbs />
        </Container>
      </div>
      <Container style={styles.columnPadding}>
        <Col md={7}>
          <h1>{selectedGoal.selectedGoal.goal}</h1>
          <p>{selectedGoal.selectedGoal.additionalQuestionsSubtitle}</p>
          <hr style={{backgroundColor: colors.storm, marginTop: '2rem', marginBottom: displayTracker() ? 0 : 15 }} />
        </Col>
        {displayTracker() && 
          <Col md={7} style={{position: 'relative'}}>
            {/* TODO: Move step tracker out of Profile component */}
            <>
              <div className='step-tracker-line' style={styles.trackerLine}>
                <div style={{...styles.filledTrackerLine, width: `${step * 50}%`}} />
              </div>
              {largeScreen ? (
                <ol aria-label="progress" style={styles.trackerContainer}>
                  {financialHealthTopic?.financialProfileTopicAnswers && financialHealthTopic.financialProfileTopicAnswers.filter((financialTopic) => financialTopic.financialTopic.financialProfileTopicId > 3).map((x) => x.financialTopic.topic).map((stepName, index) => {
                    return (
                      <li key={index} style={styles.trackerItem}>
                        <Flexbox justifyContent={'center'} alignItems={'center'} style={step === index ? styles.currentStep : styles.step}>
                          {step === index ? <CircleFill color={colors.harbor} size={20} /> : (
                            step > index ? <CheckCircle color={colors.harbor} size={24} /> : <CircleFill color={colors.storm} size={24} />
                          )}
                          {step <= index && <span style={{position: 'absolute', color: step === index ? colors.snow : colors.stone, fontSize: 14, fontWeight: 700}}> {index + 1} </span>}
                        </Flexbox>
                        <button
                          id={`TargetStep-${index}`}
                          className={(step <= index) ? 'inactive-step btn-hide-pointer link-button no-underline' : 'link-button no-underline'}
                          onClick={() => checkButtonStatus(index)}
                          disabled={step < index}
                          aria-disabled={step < index}
                          aria-label={`${stepName}, step ${index + 1} of ${getStepsLength()}`}
                        >
                          {stepName}
                        </button>
                      </li>
                    );
                  })}
                </ol>
              ) : (
                <div style={{...styles.trackerContainer, justifyContent: 'flex-start'}}>
                  <div style={{transition: 'width 0.5s linear', width: `${step * (isSmallScreen ? 34 : 36)}%`}} />
                  <div style={styles.trackerItem}>
                    <Flexbox justifyContent={'center'} alignItems={'center'} style={styles.currentStep}>
                      <CircleFill color={colors.harbor} size={20} />
                      <span style={{position: 'absolute', color: colors.snow, fontSize: 14, fontWeight: 700}}> {step + 1} </span>
                    </Flexbox>
                    Step {step + 1} of {selectedGoal.selectedGoal.questions}
                  </div>
                </div>
              )}
            </>
          </Col>  
        }
        <Col md={7}>
          {financialHealthTopic?.financialProfileTopicAnswers && displayQuestion()}
          <div style={{marginTop: 30}}>
            {isSmallScreen ? (
              <Flexbox flexDirection='column'>
                {NextButton()}
                {BackButton()}
              </Flexbox>
            ) : (
              <Flexbox justifyContent='flex-start' alignItems='center'>
                {BackButton()}
                {NextButton()}
              </Flexbox>
            )}
          </div>
        </Col>                
      </Container>      
    </Flexbox>          
  );
};

export default AdditionalQuestions;