export const styles = {
  answerWrapper: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  icon: {
    maxWidth: 24,
    marginLeft: 8,
    marginRight: 24,
  },
  topic: {
    fontSize: 16,
    marginBottom: 0,
  },
  answer: {
    fontSize: 14,
    marginBottom: 0,
    paddingRight: 32,
  },
};
