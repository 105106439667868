import React from 'react';

const ParagraphText = (body) => <p key={1}>{body}</p>;

export const ONBOARD_DATA = [
  {
    image: 'onboard_one.png',
    mobileImage: 'onboard_one_mobile.png',
    body:[
      ParagraphText(`We all work hard to stay financially fit, but it can be difficult without the right tools. At BECU, your financial health is our priority. We're here to help!`),
    ],
  },
  {
    image: 'onboard_two.png',
    mobileImage: 'onboard_two_mobile.png',
    body: [
      ParagraphText(`In just a few short steps, we will help you identify actions that you can take today to improve your financial health.`),
    ],
  },
  {
    image: 'onboard_three.png',
    mobileImage: 'onboard_three_mobile.png',
    body: [
      ParagraphText(`In fact, you've already taken the first step just by showing up! So let's keep going!`),
    ],
  },
];
   