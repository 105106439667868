import React, {useState, useEffect} from 'react';
import {PropTypes} from 'prop-types';
import {Alert} from 'react-bootstrap';
import {styles} from './styles';
import { CheckCircle, ExclamationCircle, ExclamationTriangle, InfoCircle } from 'react-bootstrap-icons';
import { useMediaQuery } from 'react-responsive';

const AlertBanner = ({show, name, message, type, dismissible, template, onClose, alertStyle}) => {
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});
  const [visible, setVisible] = useState(show);
  useEffect(() => {
    setVisible(show);
  }, [show]);

  const classes = styles();

  const exitAlert = () => {
    setVisible(false);
    if (onClose !== undefined) {
      onClose();
    }
  };

  const getIcon = () => {
    switch (type) {
      case 'danger':
        return <ExclamationTriangle size={16} />;
      case 'warning':
        return <ExclamationCircle size={16} />;
      case 'success':
        return <CheckCircle size={16} />;
      case 'info':
      default:
        return <InfoCircle size={16} />;
    }
  };

  if (visible) {
    return (
      <Alert id={name} onClose={exitAlert} dismissible={dismissible} className={classes[type]} style={{...alertStyle, padding: 0}}>
        <div style={{display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'flex-start'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 12}}>
            {getIcon()}
          </div>
          <div style={{padding: 16, paddingTop: isSmallScreen ? 4 : 16, maxWidth: isSmallScreen ? '100%' : '83%'}}>
            {message}
            {template ? template : ''}
          </div>
        </div>
      </Alert>
    );
  } else {
    return '';
  }
};

AlertBanner.propTypes = {
  show: PropTypes.func,
  onClose: PropTypes.func,
  name: PropTypes.string,
  message: PropTypes.string,
  template: PropTypes.object,
  dismissible: PropTypes.bool,
  type: PropTypes.string, // Options: danger, warning, success, info
  alertStyle: PropTypes.object,
};

AlertBanner.defaultProps = {
  message: 'Error',
  name: 'error',
  show: () => false,
  type: 'info',
  dismissible: true,
  alertStyle: {},
};

export default AlertBanner;
