import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Flexbox from 'flexbox-react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Header from 'components/app/Header';
import PageMetaData from 'components/app/PageMetaData';
import Breadcrumbs from 'components/app/Breadcrumbs';

import { styles } from './styles';
import { universalStyles } from 'styles/universalStyles';
import { loginWithToken, updateBreadcrumbs, updateExternalLinkModal, updateTutorial } from 'actions';
import { authSelector, breadcrumbsSelector, intializedSelector, sessionTokenSelector, tutorialSelector, userSelector } from 'selectors';
import { formatBreadcrumbs } from 'utils/breadcrumbs';
import { GOAL_PAGE, HOME_PAGE, RETURN_TO_OLB_LINK } from 'config/constants';

const desktopButtonOrder = ['cancel', 'confirm'];
const mobileButtonOrder = ['confirm', 'cancel'];

const Tutorial = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector(authSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const initialized = useSelector(intializedSelector);
  const tutorial = useSelector(tutorialSelector)?.tutorial;
  const recommendation = useSelector(tutorialSelector)?.recommendation;
  const user = useSelector(userSelector);
  const breadcrumbs = useSelector(breadcrumbsSelector);
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});
  const isBigScreen = useMediaQuery({query: '(min-width: 768px)'});
  const tutorialStepBreakpoint = useMediaQuery({query: '(min-width: 991px)'});

  const desktopButtons = [...(tutorial?.buttons ?? [])].sort((a, b) => desktopButtonOrder.indexOf(a.type) - desktopButtonOrder.indexOf(b.type));
  const mobileButtons = [...(tutorial?.buttons ?? [])].sort((a, b) => mobileButtonOrder.indexOf(a.type) - mobileButtonOrder.indexOf(b.type));

  useEffect(() => {
    if (user.financialGoal && tutorial) {
      const selectedGoal = user?.financialGoal?.filter(goal => goal.financialGoalId === tutorial.goalId)[0];

      const goalBreadcrumbs = [
        {
          title: 'Financial Goals & Resource Guide',
          page: HOME_PAGE,
          active: false,
        },
        {
          title: selectedGoal?.goal,
          page: GOAL_PAGE,
          active: false,
        },
        {
          title: tutorial.header.title,
          page: null,
          active: true,
        },
      ];
      dispatch(updateBreadcrumbs(goalBreadcrumbs));
    }
  }, [user]);

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      window.open(RETURN_TO_OLB_LINK, '_self');
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    } else {
      if (!tutorial) {
        history.push('/');
      }
    }
  }, [loggedIn, sessionToken, initialized, history]);

  const getStarted = () => {
    if (!recommendation.skipExternalModal) {
      dispatch(updateExternalLinkModal(true, recommendation.getStartedLink, recommendation.getStartedSametab));
    } else {
      window.open(recommendation.getStartedLink, recommendation.getStartedSametab ? '_self' : '_blank');
    }
  };

  const back = () => {
    dispatch(updateBreadcrumbs(formatBreadcrumbs([...breadcrumbs.slice(0, -1)])));
    dispatch(updateTutorial(null));
    history.goBack();
  };

  const DynamicButton = (button, index) => (
    <Button
      key={index}
      id={(button.type === 'confirm') ? 'ContinueToRecommendation' : 'ReturnToGoal'}
      variant={(button.type === 'confirm') ? 'primary' : 'secondary'}
      style={{...universalStyles.noSetWidth, marginLeft: (button.type === 'confirm' && !isSmallScreen) ? 15 : 0, marginTop: (button.type !== 'confirm' && isSmallScreen) ? 15 : 0}}
      onClick={() => {
        if (button.type === 'confirm') {
          getStarted();
        } else {
          back();
        }
      }}
    >
      {button.title}
    </Button>
  );

  return (
    <Flexbox flexDirection='column' style={universalStyles.container}>
      <PageMetaData pageTitle={`${tutorial?.header?.title} | BECU`} />
      <Header />
      <div id="main-content" style={styles.subheaderWrapper}>
        <Container style={{paddingTop: 8, paddingBottom: 8}}>
          <Breadcrumbs altAction={back} />
        </Container>
        <Container style={{...styles.subheaderContainer, flexDirection: isBigScreen ? 'row' : 'column'}}>
          <h1 style={isBigScreen ? styles.tutorialTitleBig : styles.tutorialTitleSmall}>{tutorial?.header?.title}</h1>
          <img role="none" src={tutorial?.header?.image} style={{...(isBigScreen ? styles.tutorialImageBig : styles.tutorialImageSmall), marginLeft: 'auto'}} aria-hidden='true' />
        </Container>
      </div>
      <Container>
        <Row>
          <Col md={7} style={styles.columnPadding}>
            <h2 style={{marginTop: (isSmallScreen) ? 40 : 0, marginBottom: 24}}>{tutorial?.description?.title}</h2>
            <p>{tutorial?.description?.body}</p>
          </Col>
        </Row>
        <hr />
        <Row style={styles.columnPadding}>
          <h2>{tutorial?.content?.title}</h2>
          <Col lg={6} style={{paddingTop: 30}}>
            <iframe
              src={tutorial?.content?.body?.video}
              frameBorder='0'
              allow='autoplay; encrypted-media'
              allowFullScreen
              title='YouTube tutorial video player'
              style={{width: '100%', height: (tutorialStepBreakpoint) ? 285 : 315}}
            />
          </Col>
          <Col lg={6} style={{paddingTop: 30}}>
            <p>{tutorial?.content?.body?.label}</p>
            <ul>
              {tutorial?.content?.body?.steps?.map((step, index) => (
                <li key={index} style={{marginTop: (index === 0) ? 0 : '1rem'}}>{step}</li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row style={styles.columnPadding}>
          <h2>{tutorial?.walkthrough?.title}</h2>
          {tutorial?.walkthrough?.steps?.map((step, index) => (
            <Col lg={6} key={index} style={styles.columnPadding}>
              <h4>{step.title}</h4>
              <p style={{minHeight: (tutorialStepBreakpoint) ? 48 : 24}}>{step.body}</p>
              <img src={step.image} alt={step.imageAlt} style={{maxWidth: '100%'}} />
            </Col>
          ))}
          <div style={styles.columnPadding}>
            {isSmallScreen ? (
              <Flexbox flexDirection='column'>
                {mobileButtons.map((button, index) => (
                  DynamicButton(button, index)
                ))}
              </Flexbox>
            ) : (
              <Flexbox justifyContent='flex-start' alignItems='center'>
                {desktopButtons.map((button, index) => (
                  DynamicButton(button, index)
                ))}
              </Flexbox>
            )}
          </div>
        </Row>
      </Container>
    </Flexbox>
  );
};

export default Tutorial;
