import React, { useState } from 'react';
import Flexbox from 'flexbox-react';
import {ListGroup} from 'react-bootstrap';
import {styles} from './styles';
import Quiz from 'components/financial-health/Quiz';
import {
  XCircleFill,
  PlusLg
} from 'react-bootstrap-icons';

import {colors} from 'styles/config';
import { universalStyles } from 'styles/universalStyles';

const topics = ['Expenses', 'Income', 'Bills'];

const QuizConfirmation = ({addFinancialGoal, addFinancialQuizAnswer, financialHealthGoal, selectedGoals, financialHealthQuizAnswers, financialHealthTopicHeaders, setAddGoalView, headerRef = null}) => {
  const allGoalSelected = (financialHealthGoal?.financialGoal?.length === selectedGoals?.length);
  const [liveText, setLiveText] = useState('');

  const announceRemoval = () => {
    setLiveText('');
    setTimeout(() => {
      setLiveText('Goal removed');
    }, 100);
  };

  return (
    <Flexbox justifyContent='center' flexDirection='column'>
      <h3 className='profile-question-header' tabIndex={-1} ref={headerRef} style={styles.reviewlHeading}>Confirm Your Information</h3>
      <h4 style={styles.goalTitle}>What goals motivate you to improve your finances:</h4>

      <div
        role="status"
        aria-live="polite"
        style={{
          position: 'absolute',
          width: '1px',
          height: '1px',
          margin: '-1px',
          overflow: 'hidden',
          clip: 'rect(0, 0, 0, 0)',
          whiteSpace: 'nowrap'
        }}
      >
        {liveText}
      </div>

      <ListGroup id="ManageGoalsList" variant="flush">
        {selectedGoals && selectedGoals.map((item, index) => (
          <ListGroup.Item
            key={item.goal}
            eventKey={index.toString()}
            id={`${item.goalId}`}
            aria-label={`View ${item.goal}`}
            style={styles.financialGoalContainer}
            tabIndex={0}
            className='goal-confirmation-tile'
          >
            <div>
              <img src={require(`../../../assets/images/financial/${item.imgName}`)} style={{maxWidth: 32, marginRight: 8}} aria-hidden='true' /> 
              <span style={{ fontWeight: 400 }}>{item.goal}</span>
            </div>
            <button
              id={'RemoveFinancialGoal'}
              aria-label={'Remove financial goal'}
              type={'button'}
              className={'link-button'}
              style={{width: 40, height: 40}}
              onClick={() => {
                addFinancialGoal(item.goalId, item.goal, item.imgName);
                announceRemoval();
              }}
            >
              <XCircleFill color={colors.harbor} style={styles.financialGoalCheckbox}/>
            </button>
          </ListGroup.Item>
        ))}
        <ListGroup.Item style={{padding:'20px 10px', borderBottom: 'none'}}>
          <button
            id={'Add Goals'}
            type={'button'}
            className={'text-link-button add-goals-confirm'}
            style={{...universalStyles.noSetWidth, display: 'flex', alignItems: 'center'}}
            onClick={() => setAddGoalView(true)}
            disabled={allGoalSelected}
            aria-disabled={allGoalSelected}
          >
            <PlusLg style={{marginRight: 8}} /> Add Goals
          </button>
          {allGoalSelected &&
            <p style={{color: colors.obsidian, fontWeight: 400, marginBottom: 0, marginTop: 10 }}>You have added all available goals.</p>
          }
        </ListGroup.Item>
      </ListGroup>

      {topics.map((topic, index) => (
        <Quiz
          key={index}
          index={index}
          headers={financialHealthTopicHeaders}
          answers={financialHealthQuizAnswers}
          addFinancialQuizAnswer={addFinancialQuizAnswer}
          topic={topic}
        />
      ))}
    </Flexbox>
  );
};

export default QuizConfirmation;