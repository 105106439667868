import {colors} from 'styles/config';

export const styles = {
  card: {
    borderColor: colors.storm,
    borderWidth: 1,
    borderRadius: 0,
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 1px rgba(0, 0, 0, 0.20)',
    padding: '30px 15px',
  },
};
