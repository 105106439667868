import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Flexbox from 'flexbox-react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import PageMetaData from 'components/app/PageMetaData';
import Header from 'components/app/Header';
import Breadcrumbs from 'components/app/Breadcrumbs';
import FeedbackTab from 'components/app/FeedbackTab';
import TipBox from 'components/app/TipBox';

import { styles } from './styles';
import { universalStyles } from 'styles/universalStyles';
import { GOAL_PAGE, HOME_PAGE, RETURN_TO_OLB_LINK } from 'config/constants';
import { authSelector, breadcrumbsSelector, completedFavoriteRecommendationsSelector, financialHealthGoalSelector, intializedSelector, recommendationsSelector, sessionTokenSelector, userSelector } from 'selectors';
import { getCompletedFavoriteRecommendations, getFinancialGoal, getRecommendations, loginWithToken, updateBreadcrumbs } from 'actions';
import { formatBreadcrumbs } from 'utils/breadcrumbs';
import { getRecommendation, getSelectedRecommendation } from 'utils/recommendations';

const Recommendation = () => {  
  const { goalId, recommendationId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector(authSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const initialized = useSelector(intializedSelector);
  const user = useSelector(userSelector);
  const financialHealthGoals = useSelector(financialHealthGoalSelector);
  const recommendations = useSelector(recommendationsSelector);
  const completedFavoriteRecommendations = useSelector(completedFavoriteRecommendationsSelector);
  const breadcrumbs = useSelector(breadcrumbsSelector);
  const isBigScreen = useMediaQuery({query: '(min-width: 768px)'});
  const isMediumScreen = useMediaQuery({query: '(min-width: 992px)'});
  const selectedGoal = financialHealthGoals?.financialGoal?.filter(goal => goal.financialGoalId === parseInt(goalId))[0];
  const selectedRecommendation =  getSelectedRecommendation(parseInt(goalId), parseInt(recommendationId), recommendations, completedFavoriteRecommendations);
  const recommInfo = getRecommendation(selectedRecommendation?.packageName);

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      window.open(RETURN_TO_OLB_LINK, '_self');
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    } else if (recommendations.length === 0 && user?.financialProfileTopicAnswers?.length) {
      dispatch(getRecommendations());
      dispatch(getCompletedFavoriteRecommendations());
    }
  }, [loggedIn, sessionToken, initialized, history]);

  useEffect(() => {
    if (!financialHealthGoals?.financialGoal?.length) {
      dispatch(getFinancialGoal());
    }
  }, [financialHealthGoals]);

  useEffect(() => {
    if (selectedRecommendation && financialHealthGoals?.financialGoal?.length) {
      const goalBreadcrumbs = [
        {
          title: 'Financial Goals & Resource Guide',
          page: HOME_PAGE,
          active: false,
        },
        {
          title: selectedGoal?.goal,
          page: GOAL_PAGE,
          active: false,
        },
        {
          title: selectedRecommendation.packageName,
          page: null,
          active: true,
        },
      ];
      dispatch(updateBreadcrumbs(goalBreadcrumbs));
    }
  }, [selectedRecommendation, financialHealthGoals]);

  const formatDescription = (desc) => {
    if (!desc) {
      return [];
    }

    return desc.split('\n');
  };
  
  const ListRecommendation = (item, index) => (
    <li
      key={index}
      className='recommendation-tile'
      tabIndex="0"
    >
      <h4 name={`RecommendationTitle-${index + 1}`} style={styles.recommendationListTitle}>{item.title}</h4>
      <div name={`RecommendationSubtitle-${index + 1}`}>
        {(formatDescription(item.subtitle)).map((desc, i) => (
          <p key={`subtitle-${index}-${i}`} style={{marginTop: (!i) ? 4 : 0}}>{desc}</p>
        ))}
      </div>
    </li>
  );
    
  const ListContentHeader = (item, index, isBigScreen) => (
    <Flexbox
      key={index}
      tabIndex="0"
      justifyContent='space-between'
      alignItems='center'
    >
      <div style={{padding: (isBigScreen) ? 0 : '0px 5%'}}>
        <h2 name={`RecommendationTitle-${index + 1}`} style={{marginBottom: '1.5rem'}} >{item.title}</h2>
        <div name={`RecommendationSubtitle-${index + 1}`} style={styles.recommendationHeaderSubtitle}>
          {item.subtitle.map((s, i) => (
            <p key={i} style={{marginBottom: (i < item.subtitle.length - 1) ? '1.5rem' : 0}}>{s}</p>
          ))}
        </div>
        {item.readMoreLink &&
          <a id="learnMoreLink" style={{marginLeft: 0}} href={item.readMoreLink} target="_blank" rel="noreferrer">
            Learn More
          </a>
        }
      </div>
    </Flexbox>
  );

  const ListContent = (data) => {
    return data?.recommendationContent?.map((item, index) => (
      <div key={index} style={styles.listWrapper}>
        <Row>
          <Col style={{paddingTop: 15, paddingBottom: 15}}>
            <Flexbox flexDirection={isBigScreen ? 'row' : 'column'} justifyContent='flex-start' alignItems='center'>
              {contentImage(index)}
              <div style={{marginLeft: (isBigScreen) ? 30 : 0, marginTop: (isBigScreen) ? 0 : 30, padding: (isBigScreen) ? 0 : '0 5%'}}>
                <h3 name={`RecommendationContentTitle-${index + 1}`}>{item.title}</h3>
                <p name={`RecommendationContentSubtitle-${index + 1}`}>{item.subtitle}</p>
                <a id="learnMoreLink" style={{marginLeft: 0}} href={item.readMoreLink} target="_blank" rel="noreferrer">
                  Learn More
                </a>
              </div>
            </Flexbox>
          </Col>
        </Row>
      </div>
    ));
  };

  const ListContentBullets = (item, index, isBigScreen) => (
    <Flexbox
      key={index}
      tabIndex="0"
      justifyContent='space-between'
      alignItems='center'
      style={{paddingTop: 16}} 
    >
      <div style={{paddingBottom: item?.readMoreLink ? 18 : 0}}>
        <h4 name={`RecommendationTitle-${index + 1}`}>{item.title}</h4>
        <p name={`RecommendationSubtitle-${index + 1}`}>{item.subtitle}</p>
        <div name={`RecommendationSubtitleHeader-${index + 1}`}>{item.subtitleListHeader}</div>
        <ul>
          {item?.subtitleList?.map((step, index2) => (
            <li key={index2} style={styles.recommendationBulletList}>{step.title}</li>
          ))}
        </ul>
        {item?.readMoreLink &&
          <a id="learnMoreLink" style={{marginLeft: 0}} href={item.readMoreLink} target="_blank" rel="noreferrer">
              Learn More
          </a>
        }
      </div>
    </Flexbox>
  );

  const headerImage = () => {
    let imgName = selectedRecommendation?.packageName.replace(/ /g, '_').toLowerCase();
    if (imgName) {
      const image = require(`../../assets/images/recommendations/${imgName + '.svg'}`);
      return <img src={image} style={{...(isMediumScreen ? styles.headerImageBig : styles.headerImageSmall), marginLeft: 'auto'}} role='none' aria-hidden='true' />;
    }
    return null;
  };

  const contentImage = (index) => {
    let imgName = selectedRecommendation?.packageName.replace(/ /g, '_').toLowerCase()+'_content_'+index;
    if (imgName) {
      const image = require(`../../assets/images/recommendations/${imgName + '.svg'}`);
      return <img src={image} style={{...(isBigScreen ? styles.goalImageBig : styles.goalImageSmall)}} role='none' aria-hidden='true' />;
    }
    return null;
  };

  const back = () => {
    dispatch(updateBreadcrumbs(formatBreadcrumbs([...breadcrumbs.slice(0, -1)])));
    history.goBack();
  };

  return (
    <Flexbox flexDirection='column' style={universalStyles.container}>
      <PageMetaData pageTitle={`${selectedRecommendation?.packageName ? selectedRecommendation.packageName : 'Recommendation'} | BECU`} />
      <Header />
      <div id='main-content'>
        <div style={styles.subheaderWrapper}>
          <Container style={{paddingTop: 8, paddingBottom: 8}}>
            <Breadcrumbs altAction={back} />
          </Container>
          <Container style={{...styles.subheaderContainer, flexDirection: isBigScreen ? 'row' : 'column', overflow: 'visible'}}>
            <h1 style={{width: (isMediumScreen) ? '100%' : '70%', paddingTop: (isMediumScreen) ? 110 : 32}}>{selectedRecommendation?.packageName}</h1>
            {isMediumScreen && headerImage()}
          </Container>
        </div>
        <Container style={{paddingBottom: 30}}>
          {!isMediumScreen && <Row>{headerImage()}</Row>}
          <Row>
            {recommInfo?.header &&
              <Col lg={7} style={styles.listWrapper}>
                <p name={`RecommendationHeader`} style={{padding: (isBigScreen) ? 0 : '0px 5%'}}>{recommInfo?.header}</p>
              </Col>
            }
            {recommInfo?.headerContent &&
              <Col lg={7} style={styles.listWrapper}>
                {recommInfo?.headerContent.map((recommendation, index) => (
                  ListContentHeader(recommendation, index, isBigScreen)
                ))}
              </Col>
            }
            {recommInfo?.recommendationList &&
              <Col lg={7} style={styles.listWrapper}>
                <ol style={{paddingTop: 20}}>
                  {recommInfo && recommInfo?.recommendationList.map((recommendation, index) => (
                    ListRecommendation(recommendation, index)
                  ))}
                </ol>
              </Col>
            }
            {recommInfo?.tip &&
              <Col lg={{span: 4, offset: 1}} style={{marginTop: (isMediumScreen) ? '7%' : 0}} >
                <TipBox
                  topMargin={(!isMediumScreen) ? 24 : 64}
                  title={recommInfo.tip.title} 
                  content={recommInfo.tip.content}
                />
              </Col>
            }
          </Row>
          <Row>
            <Col lg={9}>
              {recommInfo?.relatedContentSeparator &&
                <>
                  <hr></hr>
                  <h1 style={{marginTop: (isBigScreen) ? 30 : 0}}>Related Content</h1>
                </>
              }
              {recommInfo &&
                ListContent(recommInfo)
              }
            </Col>
          </Row>
          {recommInfo?.recommendationBullets &&
            <Row>
              <Col lg={9} style={styles.listWrapper}>
                {recommInfo?.recommendationBulletsTitle && <h2>{recommInfo?.recommendationBulletsTitle}</h2>}
                {recommInfo?.recommendationBullets.map((recommendation, index) => (
                  ListContentBullets(recommendation, index, isBigScreen)
                ))}
              </Col>
            </Row>
          }
          <Button
            id={'RecommendationsBack'}
            variant={'secondary'}
            style={{marginTop: 30, width: (isBigScreen) ? 83 : '100%'}}
            onClick={back}
          >
            Back
          </Button>
        </Container>
        <FeedbackTab />
      </div>
    </Flexbox>
  );
};

export default Recommendation;