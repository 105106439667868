import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Flexbox from 'flexbox-react';
import {Container, Nav, Navbar} from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import ConfirmationModal from 'components/modals/ConfirmationModal';

import {authSelector, saveChangeModalSelector, sessionTokenSelector, userSelector} from '../../../selectors';
import {HOME_PAGE, RETURN_TO_OLB_LINK, UNSAVED_MODAL_DATA, FAQ_PAGE} from '../../../config/constants';
import {setSessionPath, updateSaveChangeModal} from '../../../actions';

import {styles} from './styles';
import {tagLogout} from 'services/gtm';
import { colors } from 'styles/config';
import logo from '../../../assets/images/logo.svg';

const Header = ({ noHeaderLink = false, editView = false, toggleEditView = () => {} }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(userSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const loggedIn = useSelector(authSelector);
  const saveChangeModal = useSelector(saveChangeModalSelector);
  const isLargeScreen = useMediaQuery({query: '(min-width: 1200px)'});
  const [saveChangeModalVisible, setSaveChangeModalVisible] = useState(false);
  const [saveChangeModalFunction, setSaveChangeModalFunction] = useState();

  const handleLogout = () => {
    dispatch(updateSaveChangeModal(false));
    tagLogout(user);
    window.open(RETURN_TO_OLB_LINK, '_self');
  };

  const cancelEditView = () => {
    dispatch(updateSaveChangeModal(false));
    toggleEditView();
  };

  const redirectToHomepage = () => {
    dispatch(updateSaveChangeModal(false));
    
    var pathArray = window.location.pathname;    
    if(pathArray !== HOME_PAGE)
    {
      const path = {pathname: HOME_PAGE};
      setSessionPath(path);
      history.push(path);      
    }
    else
    {
      window.location.reload();
    }
  };

  const setModalVisibleStatus = (status) => {
    setSaveChangeModalVisible(status);
  };

  const setModalFunction = (callback) => {
    setSaveChangeModalFunction(() => callback);
  };

  const linkClickHandler = (action) => {
    if (!saveChangeModal) {
      action();
    } else {
      setModalFunction(action);
      setModalVisibleStatus(true);
    }
  };
  
  const navigateToFAQ = () => {
    const path = {pathname: FAQ_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
  };

  const NavbarHomeLink = () => (
    <Navbar.Brand>
      <a
        id="HomeButton"
        style={{...styles.linkWrapper, pointerEvents: noHeaderLink ? 'none' : 'auto'}}
        tabIndex={noHeaderLink ? -1 : 0}
        onClick={() => linkClickHandler(editView ? cancelEditView : redirectToHomepage)}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            linkClickHandler(editView ? cancelEditView : redirectToHomepage);
          }
        }}
      >
        <img src={logo} style={styles.faviconImg} width={95} alt={'BECU logo'} />
        {isLargeScreen && 'Financial Goals & Resource Guide'}
      </a>
    </Navbar.Brand>
  );

  return (
    <div style={{borderBottom: `1px solid ${colors.storm}`}}>
    <Container>
      <a className="skip-link" href="#main-content">Skip to main content</a>
        <Navbar id="NavBar" expand="lg" style={styles.navbar}>
          <Flexbox flexDirection='column' justifyContent='center' alignItems='flex-start'>
            {NavbarHomeLink()}
            {!isLargeScreen && <div style={styles.label}>Financial Goals & Resource Guide</div>}
          </Flexbox>
          {loggedIn && sessionToken && (
          <>
            <Navbar.Toggle id="NavBarToggle" aria-controls="basic-navbar-nav" style={{border: 'none'}} />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link id="NavBarFAQink" onClick={() => linkClickHandler(navigateToFAQ)} aria-label="Frequently asked questions and tips">
                  FAQS AND TIPS
                </Nav.Link>
                <Nav.Link id="NavBarLogOutLink" onClick={() => linkClickHandler(handleLogout)} style={{display: 'flex'}}>
                  <span aria-hidden='true' style={{marginRight: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><ArrowLeft /> </span>
                  RETURN TO ONLINE BANKING
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </>
          )}
          <ConfirmationModal
            modalData={UNSAVED_MODAL_DATA}
            modalFunction={saveChangeModalFunction}
            showModal={saveChangeModalVisible}
            setModalVisibleStatus={setModalVisibleStatus}
          />
        </Navbar>
        {history?.location?.pathname === HOME_PAGE &&
          <h3 style={styles.bannerTitle} id="WelcomeName">
            Welcome, {user.firstName}
          </h3>
        }
      </Container>
    </div>
  );
};

export default Header;
