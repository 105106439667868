import {colors} from 'styles/config';

export const styles = {
  categoryTitle: {
    marginBottom: '1rem',
  },
  financialHealthIntroduction: {
    marginBottom: '1.5rem',
    color: colors.stone,
  },
  financialHealthOnboardCardTitle: {
    fontSize: '1.2rem',
    fontWeight:700
  },
  financialHealthOnboardCardText: {
    marginTop:20,
    color: colors.stone
  },
  profileAnswersWrapper: {
    borderRadius: 4,
    backgroundColor: colors.cloud,
    width: '100%',
    padding: 25,
  },
  card: {
    border: 'none', 
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: 4,
  },
};
