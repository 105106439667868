import React from 'react';
import {useMediaQuery} from 'react-responsive';
import {Col, Dropdown, ListGroup} from 'react-bootstrap';

import {FAQ_DATA} from 'config/faq';
import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';

const CategorySelector = ({active, updateCategory}) => {
  const isBigScreen = useMediaQuery({query: '(min-width: 768px)'});

  return (
    <Col md={4} style={isBigScreen ? universalStyles.columnPadding : styles.smallPadding}>
      {isBigScreen ? (
        <ListGroup id="FAQSubjectList">
          {FAQ_DATA.map((category, index) => (
            <ListGroup.Item
              key={index}
              eventKey={index.toString()}
              as={'button'}
              action
              onClick={() => updateCategory(index)}
              active={active && active[0] === index.toString()}
              id={`btn-${category.title.replace(/ /g, '-')}`}
              aria-label={`View ${category.title} topic`}
            >
              {category.title}
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <Dropdown id="FAQSubjectDropdown">
          <Dropdown.Toggle id="FAQExpandTopics" variant={'secondary'} style={universalStyles.fullWidth}>
            Select a Topic
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {FAQ_DATA.map((category, index) => (
              <Dropdown.Item
                key={index}
                eventKey={index.toString()}
                as={'button'}
                onClick={() => updateCategory(index)}
                active={active && active[0] === index.toString()}
                style={{whiteSpace: 'normal'}}
                id={`btn-${category.title.replace(/ /g, '-')}`}
                aria-label={`View ${category.title} topic`}
              >
                {category.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Col>
  );
};

export default CategorySelector;
