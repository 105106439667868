import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Flexbox from 'flexbox-react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import Header from 'components/app/Header';
import FeedbackTab from 'components/app/FeedbackTab';
import PageMetaData from 'components/app/PageMetaData';
import OnboardStart from 'components/financial-health/Onboard';
import OnboardGoalsStart from 'components/financial-health/OnboardGoals';
import { GoalTile } from 'components/home/goal-tile';
import { ProfileAnswers } from 'components/home/profile-answer';
import { Recommendation } from 'components/home/recommendation';
import AlertBanner from 'components/app/AlertBanner';
import Breadcrumbs from 'components/app/Breadcrumbs';
import ConfirmationModal from 'components/modals/ConfirmationModal';

import { PencilSquare, Plus } from 'react-bootstrap-icons';

import { styles } from './styles';
import { universalStyles } from 'styles/universalStyles';
import { EDIT_PROFILE_PAGE, FINANCIAL_HEALTH_HOME_PAGE_TITLE, GOAL_PAGE, HOME_PAGE, EDIT_GOAL_PAGE, MY_GOALS_PAGE, PROFILE_PAGE, RETURN_TO_OLB_LINK } from 'config/constants';
import {
  authSelector,
  sessionTokenSelector,
  intializedSelector,
  financialHealthGoalSelector,
  financialHealthGoalAddedSelector,
  financialHealthTopicAddedSelector,
  financialHealthGoalDeletedSelector,
  recommendationsSelector,
  userSelector,
  editGoalSelector,
  completedFavoriteRecommendationsSelector,
  recommendationCompletedSuccessSelector,
  recommendationFavoritesSuccessSelector,
} from '../../selectors';
import { getCompletedFavoriteRecommendations, getFinancialGoal, getFinancialTopic, getRecommendations, getUser, loginWithToken, resetFinancialGoalAdded, resetFinancialGoalDeleted, resetFinancialTopicAdded, resetRecommendationCompletion, resetRecommendationFavorites, setSessionPath, updateBreadcrumbs, updateEditingGoal } from 'actions';
import { HEALTH_CHECK_FLOW, EDIT_GOAL_FLOW, EDIT_PROFILE_FLOW, GOALS_ADDED, CREATE_PROFILE_SUCCESS, GOAL_REMOVED } from 'config/errorMessaging';
import { tagGetStarted, tagGoalSelected, tagStartAddNewGoals, tagStartEditGoal, tagStartEditProfile } from 'services/gtm';

const FinancialHealthHome = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector(authSelector);
  const sessionToken = useSelector((sessionTokenSelector));
  const initialized = useSelector(intializedSelector);
  const user = useSelector(userSelector);
  const financialHealthGoals = useSelector(financialHealthGoalSelector);
  const financialGoalAdded = useSelector(financialHealthGoalAddedSelector);
  const financialTopicAdded = useSelector(financialHealthTopicAddedSelector);
  const financialGoalDeleted = useSelector(financialHealthGoalDeletedSelector);
  const recommendations = useSelector(recommendationsSelector);
  const completedFavoriteRecommendations = useSelector(completedFavoriteRecommendationsSelector);
  const editingGoal = useSelector(editGoalSelector);
  const recommendationCompletedSuccess = useSelector(recommendationCompletedSuccessSelector);
  const recommendationFavoritesSuccess = useSelector(recommendationFavoritesSuccessSelector);
  const [alertBannervisible, setAlertBannervisible] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [goalRemoved, setgoalRemoved] = useState(null);
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});
  const isBigScreen = useMediaQuery({query: '(min-width: 992px)'});
  const [goalMenu, setGoalMenu] = useState(null);
  const [bannerMessage, setBannerMessage] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [showCompleted, setShowCompleted] = useState(false);
  const [goalsFetched, setGoalsFetched] = useState(false);
  const [recommendationsFetched, setRecommendationsFetched] = useState(false);
  const [updateRecModalData, setUpdateRecModalData] = useState({title: '', body: '', yesText: '', noText: ''});
  const [updateRecModalAction, setUpdateRecModalAction] = useState(() => {});
  const [updateRecModalVisible, setUpdateRecModalVisible] = useState(false);

  useEffect(() => {
    const homeBreadcrumb = {
      title: 'Financial Goals & Resource Guide',
      page: HOME_PAGE,
      active: true,
    };
    dispatch(updateBreadcrumbs([homeBreadcrumb]));
  }, []);

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      window.open(RETURN_TO_OLB_LINK, '_self');
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    }
  }, []);

  useEffect(() => {
    if (financialGoalAdded || financialTopicAdded || financialGoalDeleted) {
      dispatch(getUser());
      setBannerMessage(retrieveMessage());
      setAlertBannervisible(true);
      setTimeout(() => {
        setAlertBannervisible(false);
        dispatch(updateEditingGoal(null));
        setBannerMessage('');
       }, 10000);

      dispatch(resetFinancialGoalAdded());
      dispatch(resetFinancialTopicAdded());
      dispatch(resetFinancialGoalDeleted());
    }
  }, [financialGoalAdded, financialTopicAdded, financialGoalDeleted, editingGoal]);

  useEffect(() => {
    if (!financialHealthGoals?.financialGoal?.length && Object.keys(user).length && !goalsFetched) {
      setGoalsFetched(true);
      dispatch(getFinancialGoal());
    }
  }, [financialHealthGoals, user]);

  useEffect(() => {
    if (recommendations.length === 0 && Object.keys(user).length && !recommendationsFetched) {
      setRecommendationsFetched(true);
      if (user.financialGoal || user.financialProfileTopicAnswers) {
        dispatch(getRecommendations());
        dispatch(getCompletedFavoriteRecommendations());
      }
    }
  }, [recommendations, user]);

  useEffect(() => {
    if (completedFavoriteRecommendations.completedRecommendations || completedFavoriteRecommendations.favoriteRecommendations) {
      const seenPackageNames = new Set();

      const completedArray = completedFavoriteRecommendations.completedRecommendations.map((item) => {
        const match = completedFavoriteRecommendations.favoriteRecommendations.find((favorite) => item.goalId === favorite.goalId && item.recommendationsId === favorite.recommendationsId);
        return {
          ...item,
          isFavorite: match ? match.isFavorite : false,
          favoriteDate: match ? match.favoriteDate : null,
        };
      });
      const favoriteArray = completedFavoriteRecommendations.favoriteRecommendations.reduce((acc, recommendation) => {
        if (!seenPackageNames.has(recommendation.packageName)) {
          if (recommendation.packageName.includes('Alerts')) {
            seenPackageNames.add('Alerts');
            seenPackageNames.add('Set Up Alerts');
          } else {
            seenPackageNames.add(recommendation.packageName);
          }
          acc.push({...recommendation, multipleGoalIds: [{recommendationsId: recommendation.recommendationsId, goalId: recommendation.goalId}]});
        } else {
          const usedDuplicateRec = acc.find(x => {
            if (recommendation.packageName.includes('Alerts')) {
              return x.packageName.includes('Alerts');
            } else {
              return x.packageName === recommendation.packageName;
            }
          });

          if (usedDuplicateRec) {
            usedDuplicateRec.multipleGoalIds.push({recommendationsId: recommendation.recommendationsId, goalId: recommendation.goalId});
          }
        }
        return acc;
      }, []);

      setCompleted(completedArray);
      setFavorites(favoriteArray);
    }
  }, [completedFavoriteRecommendations]);

  useEffect(() => {
    if (recommendationCompletedSuccess.success) {
      dispatch(resetRecommendationCompletion());
    }
    if (recommendationFavoritesSuccess.success) {
      dispatch(resetRecommendationFavorites());
    }
  }, [recommendationCompletedSuccess, recommendationFavoritesSuccess]);

  const getStarted = () => {
    tagGetStarted(user);
    const path = {pathname: PROFILE_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
  };

  const navToGoal = (selected, tagPath) => {
    tagGoalSelected(user, selected.financialGoalId, tagPath, (selected.questions > 0 && selected.answers === 0));
    const path = {pathname: GOAL_PAGE + `/${selected.financialGoalId}`};
    dispatch(setSessionPath(path));
    history.push(path);
  };

  const navToEditGoal = (selected) => {
    tagStartEditGoal(user, selected.financialGoalId);
    const path = {pathname: EDIT_GOAL_PAGE + `/${selected.financialGoalId}`};
    dispatch(setSessionPath(path));
    history.push(path);
  };

  const goalToBeRemoved = (selected) => {
    setgoalRemoved(selected);
  };

  const navToAddGoals = () => {
    tagStartAddNewGoals(user);
    if (!financialHealthGoals?.financialGoal?.length) {
      dispatch(getFinancialGoal());
    }
    const path = {pathname: MY_GOALS_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
  };

  const navToEdit = () => {
    tagStartEditProfile(user);
    if (!financialHealthGoals?.financialGoal?.length) {
      dispatch(getFinancialGoal());
    }
    dispatch(getFinancialTopic());
    const path = {pathname: EDIT_PROFILE_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
  };

  const moreActionsNeeded = (selGoal) => {
    return selGoal.questions > 0 && selGoal.answers === 0;
  };

  const toggleGoalMenu = (index) => {
    setGoalMenu(index);
  };

  const addedAllGoalsCheck = () => {
    let userGoalLength = user?.financialGoal?.length;
    let financialGoalsLength = financialHealthGoals?.financialGoal?.length;
    return (userGoalLength && financialGoalsLength && (userGoalLength === financialGoalsLength));
  };

  const retrieveMessage = () =>  {
    if (financialGoalAdded) {
      return GOALS_ADDED;
    } else if (financialGoalDeleted) {
      return GOAL_REMOVED.replace('goalName', goalRemoved?.goal);
    } else if (editingGoal && editingGoal.length > 0) { 
      return ((editingGoal[0] === 'editGoalFlow') ? EDIT_GOAL_FLOW.replace('goalName', editingGoal[1]) : (editingGoal && editingGoal[0] === 'healthcheck' ? HEALTH_CHECK_FLOW.replace('goalName', editingGoal[1]) : EDIT_PROFILE_FLOW ));
    }  else if (financialTopicAdded) {
      setCreateSuccess(true);
      return CREATE_PROFILE_SUCCESS;
    }
  };

  const updateCompletedFavorite = (modalData, modalAction) => {
    setUpdateRecModalData(modalData);
    setUpdateRecModalAction(() => modalAction);
    setUpdateRecModalVisible(true);
  };

  return (
    <Flexbox flexDirection={'column'} style={universalStyles.container}>
      <PageMetaData pageTitle={FINANCIAL_HEALTH_HOME_PAGE_TITLE} />
      <Header />
      <Container role={'main'} aria-label={''} className={'financial-health'} id="main-content" style={{paddingTop: 50}}>
        <Breadcrumbs />
        {((!user?.financialGoal || user?.financialGoal?.length === 0) && (!user?.financialProfileTopicAnswers || user?.financialProfileTopicAnswers?.length === 0) && loggedIn) ? (
          <Row>
            <Col style={{paddingTop: 20, paddingBottom: 40}}>
              <Col lg={8}>
                <div id={'Financial-Health-Title'} style={styles.categoryTitle}>
                  <h1 style={styles.financialHealthTitle}>{'Financial Goals & Resource Guide'}</h1>
                </div>
                <p style={styles.financialHealthIntroduction}>In five minutes or less, you select your financial goals and answer a few questions, then we’ll recommend actions you can take today to achieve those goals.</p>
              </Col>
              <OnboardStart getStarted={getStarted} />
            </Col>
          </Row>
        ) : (
          <Row style={{paddingTop: 20, paddingBottom: 60}}>
            <Col lg={7}>
              <AlertBanner
                type={'success'}
                name={'saveSuccess'}
                show={() => alertBannervisible}
                dismissible={true}
                onClose={() => setAlertBannervisible(false)}
                message={bannerMessage}
              />
            </Col>
            <Col lg={7}>
              <h1 style={{marginTop: (createSuccess) ? 20 : 0}}>Financial Goals & Resource Guide</h1>
              {((!user?.financialGoal || user?.financialGoal?.length === 0) && (!user?.financialProfileTopicAnswers || user?.financialProfileTopicAnswers?.length > 0) && loggedIn) ? (
                <Row>
                  <Col style={{paddingTop: 40}}>
                    <OnboardGoalsStart getStarted={getStarted} />
                  </Col>
                </Row>
              ) : (
                <div>
                  <Flexbox flexDirection={(isSmallScreen) ? 'column' : 'row'} alignItems={(isSmallScreen) ? 'flex-start' : 'center'} justifyContent='space-between' style={{marginTop: isBigScreen ? 45 : 30, marginBottom: (addedAllGoalsCheck()) ? 8 : 16}}>
                    <div style={{ marginBottom: (isSmallScreen) ? '1rem' : 0 }}>
                      {isSmallScreen ? (
                        <h2>Your Goals</h2>
                      ) : (
                        <h4>Your Goals</h4>
                      )}
                    </div>
                    <Button
                      id="AddNewGoal"
                      variant="secondary"
                      style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: (isSmallScreen) ? '100%' : 'auto'}}
                      onClick={navToAddGoals}
                      disabled={addedAllGoalsCheck()}
                      aria-disabled={addedAllGoalsCheck()}
                    >
                      <Plus size={24} style={{marginRight: 10}} />
                      Add New Goal
                    </Button>
                  </Flexbox>
                  {addedAllGoalsCheck() &&
                    <p style={{fontSize: 14, marginBottom: '2rem'}}>You have added all available goals.</p>
                  }
                  {user?.financialGoal?.map((selectedGoal, index) => (
                    <GoalTile key={selectedGoal.goal} financialGoal={selectedGoal} index={index} navToGoal={navToGoal} isAdditionalActionsNeeded={moreActionsNeeded(selectedGoal)} removeGoalFunction={goalToBeRemoved} navToEditGoal={navToEditGoal} currentGoalMenu={goalMenu} setCurrentGoalMenu={toggleGoalMenu} />
                  ))}
                </div>
              )}
              <hr style={{margin: '45px 0'}} />
              <h4 style={{marginBottom: 0}}>Your Favorite Recommendations</h4>
              {(favorites.length) ? (
                favorites.map((favorite, index) => (
                  <Recommendation key={`${favorite?.packageName}-${index}`} item={favorite} showsComplete={false} recommendations={recommendations} updateCompletedFavorite={updateCompletedFavorite} />
                ))
              ) : (
                <Card style={{...styles.card, marginTop: 15}}>
                  <Card.Body style={{padding: 25}}>
                    <p style={{marginBottom: 0, fontSize: 14}}>You have not saved any recommended actions to your favorites.</p>
                  </Card.Body>
                </Card>
              )}
              <hr style={{margin: '45px 0'}} />
              <h4 style={{marginBottom: 0, marginTop: 45}}>Your Completed Recommendations</h4>
              {(completed.length > 0) ? (
                <div style={{marginTop: 15}}>
                  <Form>
                    <Form.Switch
                      id='custom-switch'
                      className='custom-switch'
                      label={`${(showCompleted) ? 'Hide' : 'Show'} Completed`}
                      value={showCompleted}
                      onChange={() => {
                        const show = !showCompleted;
                        setShowCompleted(show);
                      }}
                    />
                  </Form>
                  {showCompleted &&
                    <div style={{marginTop: 5}}>
                      {completed.map((complete, index) => (
                        <Recommendation key={`${complete?.packageName}-${index}`} item={complete} showsComplete={true} recommendations={recommendations} updateCompletedFavorite={updateCompletedFavorite} />
                      ))}
                    </div>
                  }
                </div>
              ) : (
                <Card style={{...styles.card, marginTop: 15}}>
                  <Card.Body style={{padding: 25}}>
                    <p style={{marginBottom: 0, fontSize: 14}}>You have not completed any recommended actions.</p>
                  </Card.Body>
                </Card>
              )}
              {!isBigScreen && <hr style={{margin: '45px 0'}} />}
            </Col>
            <Col lg={{span: 4, offset: 1}}>
              <Flexbox flexDirection={(isSmallScreen) ? 'column' : 'row'} alignItems={(isSmallScreen) ? 'flex-start' : 'center'} justifyContent='space-between' style={{marginTop: isBigScreen ? (createSuccess ? 108 : 88) : 0, marginBottom: 15}}>
                <div style={{ marginBottom: (isSmallScreen) ? '1rem' : 0 }}>
                  {isSmallScreen ? (
                    <h2>Your Financial Profile</h2>
                  ) : (
                    <h4>Your Financial Profile</h4>
                  )}
                </div>
                <Button
                  id="EditFinancialProfile"
                  variant="secondary"
                  style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: (isSmallScreen) ? '100%' : 'auto', height: 'auto'}}
                  onClick={navToEdit}
                >
                  <PencilSquare size={24} style={{marginRight: 10}} />
                  Edit Profile
                </Button>
              </Flexbox>
              <div style={styles.profileAnswersWrapper}>
                {user?.financialProfileTopicAnswers?.filter((financialTopic) => financialTopic.financialTopic.topic === 'Expenses' || financialTopic.financialTopic.topic === 'Income' || financialTopic.financialTopic.topic === 'Bills').map((item, index) => (
                  ProfileAnswers(item, index, (index === 2))
                ))}
              </div>
            </Col>
          </Row>
        )}
        <FeedbackTab />
        <ConfirmationModal
          modalData={updateRecModalData}
          modalFunction={updateRecModalAction}
          showModal={updateRecModalVisible}
          setModalVisibleStatus={setUpdateRecModalVisible}
        />
      </Container>
    </Flexbox>
  );
};

export default FinancialHealthHome;
