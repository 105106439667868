import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {TERMS_AND_CONDITIONS} from 'config/legal';
import {termsModalSelector} from 'selectors';
import {updateTermsModal} from 'actions';

const TermsModal = () => {
  const dispatch = useDispatch();
  const termsModalVisible = useSelector(termsModalSelector);

  return (
    <Modal id="TermsAndConditionsModal" show={termsModalVisible} scrollable size={'lg'} onHide={() => dispatch(updateTermsModal(false))}>
      <Modal.Header closeButton className='border-0'>
        <Modal.Title style={universalStyles.modalHeader}>Envelopes Terms and Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={styles.termsBody}>
          <a href={require('../../../assets/documents/BECU_Envelopes_Terms_and_Conditions.pdf')} target="_blank" rel="noreferrer">
            <FontAwesomeIcon id={'PdfIcon'} icon={faFilePdf} style={styles.pdfIcon} />
            Download Terms & Conditions (PDF)
          </a>
        </div>
        {TERMS_AND_CONDITIONS.map((terms, index) => (
          <div key={index} style={terms.type === 'heading' ? styles.termsHeader : styles.termsBody}>
            {terms.text}
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default TermsModal;
