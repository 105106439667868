export const FAQ_PAGE = '/faq';
export const HOME_PAGE = '/';
export const LOGIN_PAGE = '/login';
export const NOT_FOUND_PAGE = '/not-found';
export const SSO_PAGE = '/sso';
export const SSO_SESSION_LOGOUT_PAGE = '/logout';
export const PROFILE_PAGE = '/financial-profile';
export const EDIT_PROFILE_PAGE = '/profile';
export const GOAL_PAGE = '/financial-goal';
export const EDIT_GOAL_PAGE = '/edit/financial-goal';
export const MY_GOALS_PAGE = '/add-goal';
export const TUTORIAL_PAGE = '/tutorial';

export const FAQ_PAGE_TITLE = 'Frequently Asked Questions & Tips | BECU';
export const HOME_PAGE_TITLE = 'Home | BECU';
export const LOGIN_PAGE_TITLE = 'Login | BECU';
export const FINANCIAL_HEALTH_HOME_PAGE_TITLE = 'Financial Goals & Resource Guide | BECU';
export const Credit_CARD_GOAL_PAGE_TITLE = 'Pay Down Credit Card Debt | BECU';
export const MANAGE_SPENDING_GOAL_PAGE_TITLE = 'Manage Spending | BECU';
export const EDIT_PROFILE_PAGE_TITLE = 'Your Financial Profile | BECU';
export const MY_GOALS_PAGE_TITLE = 'Your Goals | BECU';

export const BECU_BASE_URL = process.env.REACT_APP_BECU_BASE_LINK;
export const PRIVACY_URL = process.env.REACT_APP_PRIVACY_LINK;
export const SERVICE_URL = `${process.env.REACT_APP_SERVICE_API_URL}/api/V1`;
export const HEALTH_SERVICE_URL = `${process.env.REACT_APP_HEALTH_SERVICE_API_URL}/api/V1`;
export const LOGIN_SERVICE_URL = `${process.env.REACT_APP_LOGIN_SERVICE_URL}/api/V1`;
export const TOKEN_TIME = Number(process.env.REACT_APP_TOKEN_TIME);
export const MAX_SESSION_MODAL_TIME = Number(process.env.REACT_APP_MAX_SESSION_MODAL_TIME);
export const ENABLE_MAX_SESSION_MODAL = process.env.REACT_APP_ENABLE_MAX_SESSION_MODAL;
export const RETURN_TO_OLB_LINK = `${process.env.REACT_APP_RETURN_TO_OLB_LINK}`;
export const LOGIN_API = '/authentication/signon';
export const LOGIN_TOKEN_API = '/authentication/signontoken';
export const USER_API = '/user';
export const FINANCIAL_PROFILE_API = '/financialProfile';
export const AGREEMENT_API = '/agreement';
export const SETTINGS_API = '/setting';
export const ENVELOPES_API = '/digitalenvelope';
export const ACCOUNTS_API = '/account';
export const TARGETS_API = '/envelopetarget';
export const SCHEDULE_API = '/envelopeschedule';
export const FINANCIAL_HEALTH_API = '/financialhealth';
export const FINANCIAL_GOAL = '/financialgoal';
export const FINANCIAL_TOPIC = '/financialtopicanswer';
export const FINANCIAL_RECOMMENDATIONS = '/financialRecommendations';
export const HISTORY_API = '/history';
export const CTA_GOALS_API = '/callToAction/goals';
export const COMPLETED_FAVORITE_RECOMMENDATIONS = '/financialCompletedFavoriteRecommendations';
export const COMPLETION_API = '/financialRecommendationsStatus';
export const FAVORITES_API = '/financialFavoriteRecommendations';

export const TOKEN_STORAGE_KEY = 'token';
export const SESSION_STATE_KEY = 'state';
export const ACTIVE_ACCOUNT_NUMBER_KEY = "active_account_number";
export const ACTIVE_TAB_KEY = 'active_tab_key';
export const ALLOCATION_TAB_KEY = 'allocation-settings';
export const ENVELOPE_TAB_KEY = 'envelopes';
export const SESSION_TIMEOUT = 'timeout';
export const SSO_AUTH_PAGE = 'sso_auth';

export const FINANCIAL_HEALTH_SUBSCRIBER_CODE = 4;

export const API_ERROR_MESSAGE = 'Something went wrong and your information may not be accurate. Please try again later.';

export const UNSAVED_MODAL_DATA = {
  title: 'Unsaved Changes',
  body: 'Are you sure you want to go back?\n\nYou have unsaved changes.',
  yesText: 'Go Back',
  noText: 'Cancel'
};

export const SURVEY_MONKEY_MODALS = {
  FEEDBACK: {
    type: 'Feedback',
    title: 'BECU Financial Goals & Resource Guide Feedback',
    body: 'Please use the form below to submit feedback about the app or a specific feature.',
    surveyLink: 'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd_2FOue0c7ymEbkXd5SGN2H55Spv7_2BMdHDK3uEUFyUqdR1.js',
  },
};

export const AUTOMATE_PAYMENTS = [
  {
    goalId: 4,
    recommendationId: 5,
  },
  {
    goalId: 7,
    recommendationId: 42,
  },
];

export const ALERTS = [
  {
    goalId: 8,
    recommendationId: 12,
  },
  {
    goalId: 4,
    recommendationId: 35,
  },
  {
    goalId: 7,
    recommendationId: 44,
  },
];

export const SPECIALIST = [
  {
    goalId: 8,
    recommendationId: 14,
  },
  {
    goalId: 1,
    recommendationId: 14,
  },
  {
    goalId: 4,
    recommendationId: 38,
  },
  {
    goalId: 2,
    recommendationId: 39,
  },
];

export const MONEY_MANAGER = [
  {
    goalId: 8,
    recommendationId: 20,
  },
  {
    goalId: 1,
    recommendationId: 15,
  },
  {
    goalId: 1,
    recommendationId: 40,
  },
  {
    goalId: 4,
    recommendationId: 37,
  },
  {
    goalId: 2,
    recommendationId: 40,
  },
];

export const GREENPATH = [
  {
    goalId: 8,
    recommendationId: 36,
  },
  {
    goalId: 1,
    recommendationId: 4,
  },
  {
    goalId: 4,
    recommendationId: 9,
  },
  {
    goalId: 7,
    recommendationId: 43,
  },
  {
    goalId: 2,
    recommendationId: 41,
  },
];

