import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from 'react-bootstrap';

import {setSurveyMonkeyFormModal} from 'actions';
import {SURVEY_MONKEY_MODALS} from 'config/constants';
import {userSelector} from 'selectors';
import {tagFeedbackLink} from 'services/gtm';
import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';

const FeedbackTab = (props) => {
  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  const user = useSelector(userSelector);

  return (
    <Button
      id={'FeedbackTab'}
      ref={buttonRef}
      variant={'feedback'}
      className={'btn-feedback animatedFadeInUp'}
      style={{...universalStyles.noSetWidth, ...styles.feedbackTab}}
      onClick={() => {
        tagFeedbackLink(user);
        dispatch(setSurveyMonkeyFormModal(true, SURVEY_MONKEY_MODALS.FEEDBACK));
        buttonRef.current.blur();
      }}
    >
      Feedback
    </Button>
  );
};

export default FeedbackTab;
