import {GET_USER_SUCCESS, SET_FIRST_TIME_USER} from '../config/actionTypes';
import {HEALTH_SERVICE_API, getAuthHeader} from '../services/apiConfig';
import {handleGenericError} from '../services/errorConfig';
import {API_ERROR_MESSAGE, FINANCIAL_PROFILE_API, USER_API} from '../config/constants';
import {setApiError} from './ui';

export const getUser = () => (dispatch) => {
  HEALTH_SERVICE_API.get(USER_API + FINANCIAL_PROFILE_API, {headers: getAuthHeader()})
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
      if (err.response.status !== 401) {
        dispatch(setApiError(true, API_ERROR_MESSAGE));
      }
    });

  const success = (data) => {
    return {
      type: GET_USER_SUCCESS,
      payload: data,
    };
  };
};

export const setFirstTimeUser = (firstTimeUser) => (dispatch) => {
  const setFirstTime = (firstTimeUser) => {
    return {
      type: SET_FIRST_TIME_USER,
      payload: firstTimeUser,
    };
  };

  dispatch(setFirstTime(firstTimeUser));
};
