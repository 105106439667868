import {
  SAVE_CHANGE_MODAL,
  UPDATE_ACCOUNTS_MODAL,
  UPDATE_ERROR_MODAL,
  UPDATE_MAX_SESSION_MODAL,
  SET_API_ERROR,
  SURVEY_MONKEY_MODAL,
  EXTERNAL_LINK_MODAL,
  UPDATE_BREADCRUMBS,
  UPDATE_TERMS_MODAL,
} from '../config/actionTypes';

const INITIAL_STATE = {
  accountsModalVisible: false,
  apiError: {visible: false, message: ''},
  errorModal: {visible: false, message: '', code: 0},
  maxSessionModalVisible: false,
  saveChangeModal: false,
  surveyMonkeyFormModal: {visible: false, modal: null},
  externalLinkModal: {visible: false, link: ''},
  breadcrumbs: [],
  termsModalVisible: false,
};

export const ui = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    case EXTERNAL_LINK_MODAL:
      return {
        ...state,
        externalLinkModal: action.payload,
      };
    case SAVE_CHANGE_MODAL:
      return {
        ...state,
        saveChangeModal: action.payload,
      };
    case UPDATE_ACCOUNTS_MODAL:
      return {
        ...state,
        accountsModalVisible: action.payload,
      };
    case UPDATE_ERROR_MODAL:
      return {
        ...state,
        errorModal: action.payload,
      };
    case UPDATE_MAX_SESSION_MODAL:
      return {
        ...state,
        maxSessionModalVisible: action.payload,
      };
    case SET_API_ERROR:
      return {
        ...state,
        apiError: action.payload,
      };
    case SURVEY_MONKEY_MODAL:
      return {
        ...state,
        surveyMonkeyFormModal: action.payload,
      };
    case UPDATE_TERMS_MODAL:
      return {
        ...state,
        termsModalVisible: action.payload,
      };
    default:
      return state;
  }
};
