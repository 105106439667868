import React from 'react';
import Flexbox from 'flexbox-react';
import { styles } from './styles';
import FinancialGoals from 'components/financial-health/Goals';
import { Col } from 'react-bootstrap';

const FinancialHealthGoals = ({addFinancialGoal, financialHealthGoal, selectedGoals, addGoalView, headerRef = null}) => {

  return (
    <Flexbox justifyContent='center' flexDirection='column'>
      {!addGoalView &&
        <>
          <h2 className='profile-question-header' tabIndex={-1} ref={headerRef} style={styles.financialGoalHeading}>Goals</h2>
          <p>First, tell us what goals motivate you to improve your finances. Select all that apply. You can change your goals at any time.</p>
        </>
      }
      <div style={{marginTop: !addGoalView ? 16 : 0}}>
        <Col md={12}>
          {financialHealthGoal?.financialGoal?.map((financialGoal, index) => {
            const selected = selectedGoals.filter((goal) => goal.goalId === financialGoal.financialGoalId.toString()).length === 1;

            return (
              <FinancialGoals addFinancialGoal={addFinancialGoal} key={financialGoal.financialGoalId} financialGoal={financialGoal} selected={selected} index={index} />     
            );
          })}
        </Col>
      </div>
    </Flexbox>
  );
};

export default FinancialHealthGoals;