import {colors} from 'styles/config';

export const styles = {
  trackerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 0',
  },
  trackerItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 74,
  },
  currentStep: {
    position: 'relative',
    padding: 1,
    backgroundColor: colors.snow,
    border: `1px solid ${colors.harbor}`,
    borderRadius: '100%',
    marginTop: 6,
    marginBottom: 8,
  },
  step: {
    position: 'relative',
    backgroundColor: colors.snow,
    marginTop: 6,
    marginBottom: 8,
  },
  inactiveStep: {
    position: 'relative',
    marginTop: 6,
    marginBottom: 8,
  },
  trackerLine: {
    position: 'absolute',
    top: 40,
    height: 4,
    backgroundColor: colors.storm,
  },
  filledTrackerLine: {
    height: 4,
    backgroundColor: colors.harbor,
    transition: 'width 0.5s linear',
  },
};
