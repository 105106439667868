import Flexbox from 'flexbox-react';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import {universalStyles} from 'styles/universalStyles';
import {modalType} from 'types';
import { updateSaveChangeModal } from 'actions';

const ConfirmationModal = ({modalData, modalFunction, setModalVisibleStatus, showModal}) => {
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});

  const yesAction = () => {
    modalFunction();
    setModalVisibleStatus(false);
    dispatch(updateSaveChangeModal(false));
  };

  const NoButton = () => (
    <Button
      variant={'secondary'}
      style={{width: isSmallScreen ? '100%' : 'auto', margin: 0, marginTop: isSmallScreen ? 15 : 0}}
      onClick={() => setModalVisibleStatus(false)}
    >
      {modalData.noText}
    </Button>
  );

  const YesButton = () => (
    <Button
      className={'envelope-confirmation-button'}
      variant="primary"
      style={{width: isSmallScreen ? '100%' : 'auto', margin: 0, marginLeft: isSmallScreen ? 0 : 15}}
      onClick={yesAction}
    >
      {modalData.yesText}
    </Button>
  );

  return (
    <>
      <Modal id={`ConfirmationChangeModal-${modalData.title}`} show={showModal} onHide={() => setModalVisibleStatus(false)}>
        <Modal.Header closeButton className='border-0'>
          <Modal.Title style={universalStyles.modalHeader}>{modalData.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{whiteSpace: 'pre-wrap'}} className='modal-body-footer-present'>{modalData.body}</Modal.Body>
        <Modal.Footer className='border-0' style={{padding: 24, paddingTop: 8}}>
          {isSmallScreen ? (
            <Flexbox flexDirection='column' alignItems='center' style={{width: '100%'}}>
              {YesButton()}
              {NoButton()}
            </Flexbox>
          ) : (
            <Flexbox alignItems='center'>
              {NoButton()}
              {YesButton()}
            </Flexbox>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

ConfirmationModal.propTypes = {
  modalData: modalType,
  modalFunction: PropTypes.func,
  setModalVisibleStatus: PropTypes.func,
  showModal: PropTypes.bool,
};

export default ConfirmationModal;
